<template>
  <div class="parentDiv">
    <b-form-row class="mb-3">
      <b-col cols="3">
        <b-form-group label="Record ID (Number after the #)">
          <b-form-input type="number" v-model="form.associate.model_id" placeholder="300"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col cols="3">
        <b-form-group label="Record Type">
          <b-form-select v-model="form.associate.association_type" :options="associationOptions"></b-form-select>
        </b-form-group>
      </b-col>
      <b-col cols="3">
        <b-form-group>
          <label><br></label>
          <b-btn variant="success" @click="associateRecord" block style="margin-top:-2px;">
            <span v-if="submiting">
              <b-spinner type="grow" variant="success" class="align-middle" small></b-spinner>
            </span>
            <span v-else>
              Associate record
            </span>
          </b-btn>
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row class="mb-3">
      <b-col cols="2">
        <b-form-group label="Date/Time Taken">
          <b-form-input v-model="form.picture.date_time_taken" placeholder="Date/Time taken"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col cols="3">
        <b-form-group label="Picture">
          <b-form-file
              v-model="form.picture.picture"
              :state="Boolean(form.picture.picture)"
              placeholder="Choose a image or drop it here..."
              drop-placeholder="Drop file here..."
              :id="'picture'"
              @change="handlePictureUpload($event)"
          ></b-form-file>
        </b-form-group>
      </b-col>
      <b-col cols="5">
        <b-form-group label="Image description">
          <b-form-input v-model="form.picture.description" placeholder="Describe what this picture is about"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col cols="2">
        <b-form-group>
          <label><br></label>
          <b-btn variant="success" block @click="submitPicture" style="margin-top:-2px;">Submit Picture</b-btn>
        </b-form-group>
      </b-col>
    </b-form-row>
    <ErrorDisplay :errors="errors"></ErrorDisplay>

    <h3>Pictures:</h3>
    <b-row>
      <b-col cols="4" v-for="(item,i) in pictures" :key="item.id">
        <b-card header-tag="header" footer-tag="footer">
          <template #header>
            <h5 class="mb-0">Picture #{{ (i+1)}}</h5>
          </template>
          <b-img :src="item.picture_url" fluid/>
          <template #footer>
            <div class="float-right">
              <b-button variant="primary" size="sm" @click="()=>{ $refs['PictureModal'].openModal(item.picture_url); }">View</b-button>
              <b-button variant="outline-danger" size="sm" @click="deletePicture(item.id,i)">Delete</b-button>
            </div>
          </template>
        </b-card>
      </b-col>
    </b-row>
    <div v-if="caseFile">
      <h3>After Action Reports:</h3>
      <b-row>
        <b-col cols="4" v-for="item in after_actions" :key="item.id"></b-col>
      </b-row>
      <h3>Arrest Reports:</h3>
      <b-row>
        <b-col cols="4" v-for="(item,i) in arrest_reports" :key="item.id">
          <b-card header-tag="header" footer-tag="footer">
            <template #header>
              <h5 class="mb-0">Arrest Report #{{item.id}}</h5>
            </template>
            <p class="font-weight-bold">{{item.civilian.name}}</p>
            <p class="font-weight-bold">{{item.civilian.dob}}</p>
            <p>{{item.narrative.substring(0,120)}}</p>
            <template #footer>
              <div class="float-right">
                <b-button variant="primary" size="sm" @click="()=>{ $refs['arrestReportShow'].openArrestById(item.id); }">View</b-button>
                <b-button variant="outline-danger" size="sm" @click="desassociateRecord('arrest_report',item.id,i)">De-associate</b-button>
              </div>
            </template>
          </b-card>
        </b-col>
      </b-row>
      <h3>Warrants:</h3>
      <b-row>
        <b-col cols="4" v-for="(item,i) in warrants" :key="item.id">
          <b-card header-tag="header" footer-tag="footer">
            <template #header>
              <h5 class="mb-0">Warrant #{{item.id}} - {{capitalize(item.warrant_type)}}</h5>
            </template>
              <p class="font-weight-bold">{{item.civilian.name}}</p>
              <p class="font-weight-bold">{{item.civilian.dob}}</p>
              <p>{{item.narrative.substring(0,120)}}</p>
              <h5>
                <b-badge variant="success" v-if="item.status==='approved'">Approved</b-badge>
                <b-badge variant="danger" v-else>Submited for approval</b-badge>
              </h5>
            <template #footer>
              <div class="float-right">
                <b-button variant="primary" size="sm" @click="()=>{ $refs['warrantShow'].openWarrant(item.id); }">View</b-button>
                <b-button variant="outline-danger" size="sm" @click="desassociateRecord('warrant',item.id,i)">De-associate</b-button>
              </div>
            </template>
          </b-card>
        </b-col>
      </b-row>
    </div>
    <div v-if="afterAction">

    </div>
    <WarrantShow ref="warrantShow"></WarrantShow>
    <ArrestReportShow ref="arrestReportShow"></ArrestReportShow>
    <PicutreModalShow ref="PictureModal"></PicutreModalShow>
  </div>
</template>

<script>
import DetectiveRepository from "@/api/DetectiveRepository";
import ErrorDisplay from "@/components/Partials/ErrorDisplay";
import WarrantShow from "@/components/Partials/LEO/Lookup/WarrantShow";
import ArrestReportShow from "@/components/Partials/LEO/Lookup/ArrestReportShow";
import PicutreModalShow from "@/components/Partials/Detectives/FormCaseModal/RelatedDocumentation/PicutreModalShow";

export default {
  name: "RelatedDocumentation",
  components: {PicutreModalShow, ArrestReportShow, WarrantShow, ErrorDisplay},
  props: ['caseFile','afterAction'],
  data() {
    return {
      form: {
        picture: {
          picture: null,
          date_time_taken: null,
          description: null,
        },
        associate: {
          model_id: null,
          association_type: null,
        }
      },
      associationOptions:null,
      pictures: [],
      after_actions: [],
      case_files: [],
      warrants: [],
      arrest_reports: [],
      errors: null,
      submiting: false,
    }
  },
  computed: {
    unit() {
      return this.$store.state.auth.leoUnit;
    }
  },
  mounted() {
    if(this.caseFile){
      this.form.picture.case_files=[this.caseFile.id];
      this.form.picture.filing_officer_id=this.unit.id;

      this.pictures=this.caseFile.case_file_pictures;

      this.arrest_reports=this.caseFile.case_file_arrest_reports;
      this.warrants=this.caseFile.case_file_warrants;
      this.after_actions=this.caseFile.case_file_after_action_reports;

      this.associationOptions=[
        {value:'after_action', text:'After Action Report'},
        {value:'warrant', text:'Warrant'},
        {value:'arrest_report', text:'Arrest Report'},
      ];

    } else if(this.afterAction){
      this.pictures=this.afterAction.after_action_report_pictures;
      this.case_files=this.afterAction.case_files;

      this.associationOptions=[
        {value:'case_file', text:'Case File'},
      ];
    }

    this.form.picture.date_time_taken=this.moment().format('Y-MM-DD') + " " + this.moment().format('HH:mm:ss');
  },
  methods: {
    capitalize(str){
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    clearForm(){
      this.form.picture.picture=null;
      this.form.picture.description=null;
    },
    clearAssociate(){
      this.form.associate.association_type=null;
      this.form.associate.model_id=null;
    },
    handlePictureUpload(evt){
      this.form.picture.picture=evt.target.files[0];
    },
    submitPicture(){
      if(this.submiting) return false;
      this.submiting=true;
      this.errors=null;
      DetectiveRepository.createPicture(this.form.picture).then((response) => {
        this.submiting=false;
        this.clearForm();
        this.pictures.push(response.data.data);
      }).catch((error) => {
        this.errors=error.response.data.errors;
        this.submiting=false;
      });
    },
    deletePicture(id,i){
      DetectiveRepository.deletePicture(id).then( (response) => {
        this.pictures.splice(i,1);
      }).catch((error) => {});
    },
    associateRecord(){
      if(this.caseFile){
        this.submiting=true;
        DetectiveRepository.caseFileAssociateRecord(this.caseFile.id,this.form.associate).then( (response) => {
          this.submiting=false;
          switch(this.form.associate.association_type){
            case 'after_action':
              this.after_actions.push(response.data);
              break;
            case 'warrant':
              this.warrants.push(response.data);
              break;
            case 'arrest_report':
              this.arrest_reports.push(response.data);
              break;
          }
          this.clearAssociate();
        }).catch((error) => {
          this.submiting=false;
        });
      } else if(this.afterAction){
        this.submiting=true;
        DetectiveRepository.afterActionAssociateRecord(this.afterAction.id,this.form.associate).then( (response) => {
          this.submiting=false;
          switch(this.form.associate.association_type){
            case 'case_file':
              this.case_files.push(response.data);
              break;
          }
          this.clearAssociate();
        }).catch((error) => {
          this.submiting=false;
        });
      }
    },
    desassociateRecord(type,id,i){
      if(this.caseFile){
        let d={
          model_id:id,
          association_type: type
        };

        DetectiveRepository.caseFileDesAssociateRecord(this.caseFile.id,d).then((response) => {
          switch(type){
            case 'after_action':
              this.after_actions.splice(i,1);
              break;
            case 'warrant':
              this.warrants.splice(i,1);
              break;
            case 'arrest_report':
              this.arrest_reports.splice(i,1);
              break;
          }
        }).catch((error) => {});
      } else if(this.afterAction) {

        DetectiveRepository.afterActionDesAssociateRecord(this.afterAction.id, d).then((response) => {
          switch (type) {
            case 'case_file':
              this.case_files.splice(i, 1);
              break;
          }

        }).catch((error) => {});
      }
    },
  }
}
</script>

<style scoped>

</style>