<template>
  <div>
    <b-modal @show="()=>{this.errors=null}" no-close-on-backdrop id="medical-record-form-modal" size="lg" title="Create Medical Report" @ok="submitReport">

      <b-form-group
          label="Date/Time (*)"
          label-for="date_time"
      >
        <b-form-row>
          <b-col cols="8">
            <b-form-input
                id="date_time"
                v-model="form.date"
                autocomplete="off"
                type="date"
                required
            ></b-form-input>
          </b-col>
          <b-col cols="4">
            <b-form-input
                id="date_time"
                v-model="form.time"
                autocomplete="off"
                type="time"
                required
            ></b-form-input>
          </b-col>
        </b-form-row>

      </b-form-group>

      <b-form-group
          label="Location"
          label-for="location"
      >
        <b-form-input
            id="location"
            v-model="form.location"
            autocomplete="off"
            type="text"
            placeholder="Location"

        ></b-form-input>
      </b-form-group>

      <b-form-group
          label="Cause of Injury (*)"
          label-for="cause_of_injury"
      >
        <b-form-input
            id="cause_of_injury"
            v-model="form.cause_of_injury"
            autocomplete="off"
            type="text"

        ></b-form-input>
      </b-form-group>

      <b-form-group label="Transport type (*)" label-for="transport_type">
        <v-select :reduce="option => option.value" name="transport_type" v-if="transportTypes!==null" :options="transportTypes" v-model="form.transport_type"></v-select>
      </b-form-group>

      <b-form-group
          label="Hospital"
          label-for="hospital_name"
      >
        <b-form-input
            id="hospital_name"
            v-model="form.hospital_name"
            autocomplete="off"
            type="text"

        ></b-form-input>
      </b-form-group>

      <b-form-group
          label="Injury Description"
          label-for="injury_description"
      >
        <b-form-textarea
            id="injury_description"
            v-model="form.injury_description"
            type="text"
            required
            rows="2"
        ></b-form-textarea>
      </b-form-group>

      <b-form-group
          label="Narrative (*)"
          label-for="narrative"
      >
        <b-form-textarea
            id="narrative"
            v-model="form.narrative"
            type="text"
            required
            rows="4"
        ></b-form-textarea>
      </b-form-group>

      <ErrorDisplay :errors="errors"></ErrorDisplay>

    </b-modal>
  </div>
</template>

<script>
import FdRepository from "@/api/FdRepository";
import ErrorDisplay from "@/components/Partials/ErrorDisplay";

export default {
  name: "MedicalRecord",
  components: {ErrorDisplay},
  props: ['civilian'],
  data: function () {
    return {
      form: {
        date: null,
        time: null,
        location: null,
        cause_of_injury: null,
        injury_description: null,
        transport_type: null,
        hospital_name: null,
        narrative: null,
      },
      errors: null,
      transportTypes:[
        {value:'routine',label:'Transport Routine'},
        {value:'refused',label:'Refused Transport'},
        {value:'priority',label:'Transport Priority'},
      ],
      submited: false

    }
  },
  methods: {
    submitReport(evt){
      evt.preventDefault();

      if(this.submited) return false;
      this.submited=true;

      let data=this.form;
      data.arresting_officer_id=this.unit.id;
      data.civilian_id=this.civilian.id;
      data.date_time=this.form.date+" "+this.form.time;
      FdRepository.createMedicalRecord(data).then(response => {
        this.$root.$emit('bv::hide::modal', 'medical-record-form-modal');
        this.submited=false;
        this.form={
          date: null,
          time: null,
          location: null,
          cause_of_injury: null,
          injury_description: null,
          transport_type: null,
          hospital_name: null,
          narrative: null,
        };
      }).catch(error => {
        this.errors=error.response.data.errors;
        this.submited=false;
      });
    }
  },
  computed: {
    unit() {
      return this.$store.state.auth.leoUnit;
    }
  },
  mounted() {
    this.form.date=this.moment().format('Y-MM-D');
    this.form.time=this.moment().format('HH:mm:ss');
  }
}
</script>

<style scoped>

</style>