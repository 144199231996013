<template>
  <div id="map" ref="map"></div>
</template>

<script>
import L from "leaflet";

export default {
  name: "MapSelector",
  props: ['marker'],
  data(){
    return {
      map: {
        marker: null,
        latlang: [0, 0],
      }
    }
  },
  methods: {
    getMapBounds(layer) {
      const h = layer.options.tileSize * 3
      const w = layer.options.tileSize * 2;

      const southWest = Map.unproject([0, h], 0);
      const northEast = Map.unproject([w, 0], 0);

      return new L.LatLngBounds(southWest, northEast);
    },
  },
  mounted() {
    window.CurrentLayer = undefined;
    window.CurrentLayer= L.tileLayer('images/tiles/minimap_{y}_{x}.png',{ minZoom: -2, maxZoom: 4, tileSize: 1024, maxNativeZoom: 0, minNativeZoom: 0, tileDirectory: 'images/tiles' });
    window.Map = L.map('map', {
      crs: L.CRS.Simple,
      layers: [CurrentLayer]
    }).setView([0,0], 0);
    const mapBounds = this.getMapBounds(CurrentLayer);
    Map.setMaxBounds(mapBounds);
    Map.fitBounds(mapBounds);
    setTimeout(() => {
      Map.invalidateSize();
    }, 1)
    if (this.marker) {
      console.log("added marker");
      let latlang={lat:this.marker.lat, lng: this.marker.lng};
      console.log(latlang);
      this.map.marker = L.marker(latlang, {
        icon: L.icon({iconUrl: 'images/icons/normal.png', iconAnchor: [10,31]}),
        draggable: true
      }).addTo(Map);

      console.log(this.map.marker);

      this.map.marker.setLatLng(latlang);
    }
    Map.on('click', (e) => {
      const popLocation = e.latlng;
      console.log("clickeee");
      console.log(popLocation);
      this.$emit('updateLatLang', popLocation)
      if (this.map.marker != null) {
        this.map.marker.setLatLng(popLocation);
      } else {
        this.map.marker = L.marker(popLocation, {
          icon: L.icon({iconUrl: 'images/icons/normal.png', iconAnchor: [10, 31]}),
          draggable: true,
        }).addTo(Map);

        console.log(this.map.marker);
        this.map.marker.on('move', (e) =>{
          this.map.latlang = e.target.getLatLng();
          this.$emit('updateLatLang', this.map.latlang)
        })
      }
    });

  },
}
</script>

<style scoped>
#map {
  height: 75vh;
  width: 100%;
  background-color: #143d6b;
}
</style>
