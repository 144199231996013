<template>
  <div>
    <div>
      <b-button variant="outline-success" @click="newAfterAction">+ After Action</b-button><br><br>
      <b-form-row>
        <b-col cols="3">
          <b-form-input v-model="search.title" placeholder="Title"></b-form-input>
        </b-col>
        <b-col cols="3">
          <b-form-input v-model="search.address" placeholder="Address"></b-form-input>
        </b-col>
        <b-col cols="3">
          <b-form-checkbox
              id="pending-checkbox-1"
              v-model="search.pending"
              name="pending-checkbox-1"
              :value="true"
              :unchecked-value="null"
          >
            Pending records
          </b-form-checkbox>
        </b-col>
        <b-col cols="2">
          <b-form-checkbox
              id="all-checkbox-1"
              v-model="search.all"
              name="all-checkbox-1"
              :value="true"
              :unchecked-value="null"
          >
            Everyone's records
          </b-form-checkbox>
        </b-col>
        <b-col cols="1">
          <b-button block variant="primary" @click="searchAfterAction"><span v-if="!searching">🔎</span><b-spinner small v-if="searching"></b-spinner></b-button>
        </b-col>
      </b-form-row>
    </div><br>
    <b-table striped hover dark :items="items" :fields="fields" @row-clicked="openCaseFile">
    </b-table>
    <AfterActionModal ref="afterActionModal"></AfterActionModal>
  </div>
</template>

<script>
import DetectiveRepository from "@/api/DetectiveRepository";
import AfterActionModal from "@/components/Partials/Detectives/AfterActionModal";

export default {
  name: "AfterActionReportTable",
  components: {AfterActionModal},
  data() {
    return {
      afterActionId: null,
      fields: [
        {
          key: 'id',
          label: 'Report ID',
          sortable: true,
        },
        {
          key: 'title',
          label: 'Title',
          sortable: false,
          formatter: 'checkLimit'
        },
        {
          key: 'address',
          sortable: false,
          label: 'Address',
          formatter: 'checkLimit'
        },
        {
          key: 'approved',
          sortable: true,
          label: 'Status',
          formatter: 'pendingFormat'
        },
        {
          key: 'filing_officer',
          sortable: false,
          label: 'Author',
          formatter: 'officerNameFormat'
        },
        {
          key: 'created_at',
          sortable: true,
          label: 'Date submitted',
        }
      ],
      items: [],
      searching: false,
      search: {
        title: null,
        mine: null,
        pending: null,
        address: null,
      },
      loading: false,
    }
  },
  methods: {
    checkLimit(string){
      if(string === null) return " ";
      if (string.length > 100){
        return string.substring(1,100) + '...'
      } else {
        return string;
      }
    },
    pendingFormat(data){
      if(data){
        return "Approved";
      } else {
        return "Pending";
      }
    },
    officerNameFormat(data){
      return "["+data.agency.short_name+"] " + data.rank +  " " + data.name;
    },
    openCaseFile(record,index){
      this.afterActionId=record.id;

      this.$nextTick( () => {
        if(this.$refs.hasOwnProperty('afterActionModal')) this.$refs['afterActionModal'].show(record.id);
      });
    },
    searchAfterAction(){
      if(this.searching) return;
      this.searching=true;
      DetectiveRepository.getAfterActions(this.search).then(response => {
        this.items=response.data.data;
        this.searching=false;
      });
    },
    newAfterAction() {
      this.afterActionId=null;
      if(this.$refs.hasOwnProperty('afterActionModal')) this.$refs['afterActionModal'].newCase();
    }

  },
  created() {
    this.loading=true;
    DetectiveRepository.getAfterActions(true).then(response => {
      this.items=response.data.data;
      this.loading=false;
    });

  }
}
</script>

<style scoped>

</style>