import Repository from "./Repository";

class DojRepository {

    createVeredictReport(data){
        return Repository.post('veredict-records',data);
    }

}

export default new DojRepository();