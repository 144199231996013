<template>
  <div>
    <ValidateUnit v-if="unit===null"></ValidateUnit>
    <div class="row mt-4">
      <div class="col-4">
      </div>
      <div class="col">
        <div class="row">
          <div class="col text-center">
            <b-button variant="primary"  v-b-modal.lookup-modal>Lookup</b-button>
            <b-button class="btn" variant="warning" v-b-modal.notepad>Notepad</b-button>
            <button type="button" class="btn btn-info" v-b-modal.information-panel>Information</button>
            <b-button variant="primary" v-b-modal.global-search-modal>SARIS</b-button>
            <b-button variant="primary" v-b-modal.quick-search-arrest>Case Lookup</b-button>
            <InformationPanel></InformationPanel>
            <Notepad :block="false" type="doj"></Notepad>
            <Lookup></Lookup>
            <GlobalSearchModal></GlobalSearchModal>
            <QuickSearchArrest></QuickSearchArrest>

          </div>
        </div>
      </div>

        <div class="col-4">

        </div>

    </div>


    <div class="row align-items-start mt-4">
      <div class="col-6">
        <h5>Warrants</h5>
        <table class="table-hover table table-striped table-dark table-sm table-bg-darker">
          <thead class="thead-light">
            <tr>
              <th scope="col">Type</th>
              <th scope="col">Civilian</th>
              <th scope="col">Officer</th>
              <th scope="col">Department</th>
              <th scope="col">Status</th>
              <th scope="col">&nbsp;</th>
            </tr>
          </thead>
          <tbody>
          <tr v-if="loading">
            <td colspan="6" class="text-center">
              <b-spinner class="align-middle" variant="primary" label="Loading..."></b-spinner>
            </td>
          </tr>
            <tr v-for="(warrant,index) in warrants" @click="openWarrantReportModal(warrant)">
              <td>{{  warrant.warrant_type.charAt(0).toUpperCase() + warrant.warrant_type.slice(1) }}</td>
              <td v-if="warrant.civilian !== null">{{ warrant.civilian.name }}</td>
              <td v-if="warrant.civilian === null">Civilian Deleted</td>
              <td>{{ warrant.officer.rank + " " + warrant.officer.name }}</td>
              <td>{{ warrant.officer.agency.short_name }}</td>
              <td>{{ warrant.status.charAt(0).toUpperCase() + warrant.status.slice(1) }}</td>
              <td class="text-right">
                <b-button variant="success" v-if="doesUserHavePermission('doj_access')" @click="approveWarrant(warrant,index)">Approve</b-button>
                <b-button variant="danger" v-if="doesUserHavePermission('warrant_delete')" @click="deleteWarrant(warrant,index)">Delete</b-button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <WarrantShow :warrant="warrantSelected" v-if="warrantSelected!==null"></WarrantShow>
    </div>
  </div>
</template>

<script>
import LeoRepository from "@/api/LeoRepository";
import Lookup from "@/components/Partials/LEO/Lookup/Lookup";
import Notepad from "@/components/Partials/Notepad";
import ValidateUnit from "@/components/Partials/ValidateUnit";
import InformationPanel from "@/components/Partials/Dispatch/InformationPanel";
import GlobalSearchModal from "@/components/Partials/DOJ/GlobalSearchModal";
import WarrantShow from "@/components/Partials/LEO/Lookup/WarrantShow";
import QuickSearchArrest from "@/components/Partials/DOJ/QuickSearchArrest";
 
export default {
  name: "DOJ",
  components: {
    WarrantShow,
    GlobalSearchModal,
    InformationPanel,
    ValidateUnit,
    Notepad,
    Lookup,
    QuickSearchArrest,
  },
  data(){
    return {
      statusButtons: null,
      warrants: null,
      warrantSelected: null,
      loading:true,
    }
  },
  computed: {
    unit() {
      return this.$store.state.auth.leoUnit;
    }
  },
  created() {
    LeoRepository.listWarrants('pending').then(response => {
      this.warrants=response.data.data;
      this.loading=false;
    });
  },
  methods: {
    deleteWarrant(warrant,index){
      LeoRepository.deleteWarrant(warrant.id).then(response => {
        this.warrants.splice(index, 1);
      });
    },

    approveWarrant(warrant,index){
      let data=warrant;
      data.status="approved";
      LeoRepository.editWarrants(warrant.id,data).then(r => {
        this.warrants[index].status="approved";
      });
    },

    openWarrantReportModal(warrant){
      LeoRepository.getWarrant(warrant.id).then( response => {
        this.warrantSelected=response.data.data;
        setTimeout(() => this.$root.$emit('bv::show::modal', 'modal-warrant-show'), 100);
      });
    }
  }

};
</script>

<style scoped>
.bs-linebreak {
  height:1px;
}
</style>