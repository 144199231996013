<template>
  <div>
    <b-modal @show="()=>{this.errors=null}" no-close-on-backdrop id="warrant-form-modal" size="lg" title="Create Warrant" @ok="submitWarrant">


      <b-form-group
          label="Civilian"
          label-for="civilian"
      >
        <b-form-input
            id="civilian"
            v-model="civilian.name"
            type="text"
            autocomplete="off"
            placeholder="Civilian Name"
            required
            disabled
        ></b-form-input>
      </b-form-group>

      <b-form-group
          label="Date/Time"
          label-for="date_time"
      >
        <b-form-row>
          <b-col cols="8">
            <b-form-input
                id="date_time"
                v-model="form.date"
                autocomplete="off"
                type="date"
                required
            ></b-form-input>
          </b-col>
          <b-col cols="4">
            <b-form-input
                id="date_time"
                v-model="form.time"
                autocomplete="off"
                type="time"
                required
            ></b-form-input>
          </b-col>
        </b-form-row>

      </b-form-group>

      <hr>
      <AddCharges @change="chargesChanged"></AddCharges>
      <hr>

      <b-form-group
          label="Warrant Type"
          label-for="type"
      >
        <b-form-select v-model="form.warrant_type" :options="options"></b-form-select>
      </b-form-group>

      <b-form-group
          label="Narrative"
          label-for="narrative"
      >
        <b-form-textarea
            id="narrative"
            v-model="form.narrative"
            type="text"
            required
            rows="4"
        ></b-form-textarea>
      </b-form-group>

      <ErrorDisplay :errors="errors"></ErrorDisplay>

    </b-modal>
  </div>
</template>

<script>
import AddCharges from "@/components/Partials/LEO/Reports/AddCharges";
import LeoRepository from "@/api/LeoRepository";
import ErrorDisplay from "@/components/Partials/ErrorDisplay";
export default {
  name: "Warrant",
  components: {ErrorDisplay, AddCharges},
  props: ['civilian'],
  data: function () {
    return {
      form: {
        narrative: null,
        charges: null,
        warrant_type: null,
        date: null,
        time: null,
      },
      errors: null,
      options:{
        'arrest':'Arrest',
        'search':'Search',
        'bench':'Bench'
      },
      submited:false
    }
  },
  computed: {
    unit() {
      return this.$store.state.auth.leoUnit;
    }
  },
  methods:{
    submitWarrant(e){
      e.preventDefault();
      if(this.submited) return false;
      this.submited=true;

      let data=this.form;
      data.date_time=this.form.date+" "+this.form.time;
      data.officer_id=this.unit.id;
      data.civilian_id=this.civilian.id;
      LeoRepository.createWarrant(data).then(response => {
        this.submited=false;
        this.$root.$emit('bv::hide::modal', 'warrant-form-modal');
      }).catch(error => {
        this.submited=false;
        this.errors=error.response.data.errors;
      });

    },
    chargesChanged(event){
      this.form.charges=event;
    }
  },
  mounted() {
    this.form.date=this.moment().format('Y-MM-D');
    this.form.time=this.moment().format('HH:mm:ss');
  }
}
</script>

<style scoped>

</style>