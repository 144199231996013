<template>
  <b-modal id="flag-form-modal" title="Flag">
    <b-form-group label="Flag">
      <select v-model="flag_selected" class="form-control" v-if="flags !== null">
        <option :value="f.flag_name" v-for="f in flags[model]" v-if="doesUserHavePermission(f.permission)">{{ f.flag_formatted_name }}</option>
      </select>
    </b-form-group>

    <b-form-group label="Date" v-if="flag_selected !== null && flags[model][flag_selected].validation_type === 'date'">
      <b-form-row>
        <b-col cols="8">
          <b-form-input
              id="date_time"
              v-model="flag_date"
              type="date"
              autocomplete="off"
              required
          ></b-form-input>
        </b-col>
        <b-col cols="4">
          <b-form-input
              id="date_time"
              autocomplete="off"
              v-model="flag_time"
              type="time"
              required
          ></b-form-input>
        </b-col>
      </b-form-row>

    </b-form-group>

    <b-alert show :variant="msg_variant" v-if="msg!==null">{{ msg }}</b-alert>

    <template #modal-footer>
      <div class="w-100">
        <b-button
            variant="primary"
            size="sm"
            class="float-right"
            @click="submitFlag"
            v-if="flag_selected!==null"
        >
          <template v-if="submited"><b-spinner small></b-spinner></template>
          <template v-if="modelf.hasOwnProperty(flag_selected) && modelf[flag_selected].value">Remove Flag</template>
          <template v-else>Add Flag</template>
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import FlagsRepository from "@/api/FlagsRepository";

export default {
  name: "FlagForm",
  props: ['model','model_id','model_flags'],
  data() {
    return {
      flags:null,
      flag_selected: null,
      flag_date: null,
      flag_time: null,
      submited:false,
      msg:null,
      msg_variant: null,
      modelf:this.$props.model_flags
    }
  },
  mounted() {
    FlagsRepository.getAllFlags().then(r => {
      this.flags=r.data.data;
    });
  },
  methods: {
    submitFlag(){
      if(this.submited) return;
      this.submited=true;
      this.msg=null;
      this.msg_variant=null;

      let data = {
        model: this.model,
        flag_name: this.flag_selected,
        model_id: this.model_id,
      };

      if(this.flag_date !== null){
        data.date=data.date_time=this.flag_date+" "+this.flag_time;
      }

      FlagsRepository.submitflagModel(data).then(r => {
        console.log(r.data);
        this.submited=false;
        this.msg="Flag submitted with success";
        this.msg_variant="success";
        this.modelf=r.data;
      }).catch(error => {
        this.submited=false;
        this.msg="Something went wrong...";
        this.msg_variant="danger";
      });
    }
  }
}
</script>

<style scoped>

</style>