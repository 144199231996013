<template>
  <div class="parentDiv">
    <b-card no-body class="statementCard">
      <b-card-header>
        <h6 class="card-title d-inline">New Statement</h6>
        <b-btn variant="outline-primary" class="d-inline float-right" size="sm" v-b-toggle="'statement-form'">Toggle Form</b-btn>
        <b-btn variant="danger" class="d-inline float-right" size="sm" @click="clearForm()">Clear</b-btn>
        <b-btn variant="success" class="d-inline float-right" size="sm" @click="submitStatement">
          <span v-if="submiting">
            <b-spinner type="grow" variant="success" class="align-middle" small></b-spinner>
          </span>
          <span v-else>
            Submit
          </span>
        </b-btn>

      </b-card-header>
      <b-collapse :id="'statement-form'" visible accordion="statement-form" role="tabpanel" v-model="showForm">
        <b-card-body>
          <b-form-row>
            <b-col>
              <b-form-group label="Citizen Name" :label-for="'citizen_name'">
                <b-form-input
                    :id="'citizen_name'"
                    v-model="form.statement.citizen_name"
                    type="text"
                    placeholder="Name of citizen giving the statement"
                    autocomplete="off"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Citizen Contact" :label-for="'citizen_contact'">
                <b-form-input
                    :id="'citizen_contact'"
                    v-model="form.statement.citizen_contact"
                    type="text"
                    placeholder="A way to contact the citizen"
                    autocomplete="off"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col>
              <b-form-group label="Citizen Address" :label-for="'citizen_address'">
                <b-form-input
                    :id="'citizen_address'"
                    v-model="form.statement.citizen_address"
                    type="text"
                    placeholder="The address of the citizen"
                    autocomplete="off"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Relation" :label-for="'relation'">
                <b-form-input
                    :id="'relation'"
                    v-model="form.statement.relation"
                    type="text"
                    placeholder="Relationship of the citizen to the case"
                    autocomplete="off"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col>
              <b-form-group label="Statement" :label-for="'statement'">
                <b-form-textarea
                    :id="'statement'"
                    v-model="form.statement.statement"
                    type="text"
                    rows="3"
                    placeholder="The statement the citizen gave">
                </b-form-textarea>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col>
              <b-form-group label="Officer's Notes" :label-for="'officer_notes'">
                <b-form-textarea
                    :id="'officer_notes'"
                    v-model="form.statement.officer_notes"
                    type="text"
                    rows="1"
                    placeholder="Various notes the officer or detective has on this specific statement. Internal Use Only">
                </b-form-textarea>
              </b-form-group>
            </b-col>
          </b-form-row>
          <ErrorDisplay :errors="errors"></ErrorDisplay>
        </b-card-body>
      </b-collapse>

    </b-card>
    <h3 v-if="statements.length > 0">Statements associated:</h3>
    <b-card no-body class="statementCard" v-for="(statement,i) in statements" :key="i">
      <b-card-header>
        <h6 class="card-title d-inline">Statement #{{(i+1)}} - {{statement.citizen_name}}</h6>
        <b-btn variant="outline-primary" class="d-inline float-right" size="sm" v-b-toggle="'statement-view'+i">Toggle</b-btn>
        <b-btn variant="danger" class="d-inline float-right" size="sm" @click="deleteStatement(i,statement.id)">Delete</b-btn>
      </b-card-header>
      <b-collapse :id="'statement-view'+i" accordion="statement-view" role="tabpanel">
        <b-card-body>
          <ShowStatement :statement="statement"></ShowStatement>
        </b-card-body>
      </b-collapse>

    </b-card>
    <b-btn variant="outline-success" class="d-inline float-right">Next ></b-btn>
  </div>
</template>

<script>
import ShowStatement from "./Statement/ShowStatement";
import ErrorDisplay from "../../ErrorDisplay";
import DetectiveRepository from "@/api/DetectiveRepository";
export default {
  name: "StatementsForm",
  components: {ErrorDisplay, ShowStatement},
  props: ['caseFileId','caseFile','afterAction'],
  data(){
    return {
      form: {
        statement: {
          citizen_name: null,
          citizen_contact: null,
          citizen_address: null,
          relation: null,
          statement: null,
          officer_notes: null,
        }
      },
      errors: null,
      statements: [],
      submiting: false,
      showForm: true,
    }
  },
  mounted() {
    if(this.caseFile){
      this.form.statement.case_files=[this.caseFile.id];
      this.statements=this.caseFile.case_file_statements;
      this.showForm=false;
    } else if(this.afterAction){
      this.form.statement.after_action_reports=[this.afterAction.id];
      this.statements=this.afterAction.after_action_report_statements;
      this.showForm=false;
    }
  },
  methods: {
    clearForm(){
      for (let key in this.form.statement) {
        this.form.statement[key] = null;
      }
      this.form.statement.case_files=[this.caseFile.id];
    },
    submitStatement(){
      if(this.submiting) return false;
      this.submiting=true;
      this.errors=null;
      DetectiveRepository.createStatement(this.form.statement).then((response) => {
        this.submiting=false;
        this.clearForm();
        this.statements.push(response.data.data);
        this.showForm=false;
      }).catch((error) => {
        this.errors=error.response.data.errors;
        this.submiting=false;
      });
    },
    deleteStatement(i,id){
      DetectiveRepository.deleteStatement(id).then( (response) => {
        this.statements.splice(i,1);
      });
    }
  }
}
</script>

<style scoped>
.parentDiv .statementCard {
  margin-bottom: 10px;
}

.parentDiv .statementCard:last-child {
  margin-bottom: 0;
}
</style>
