export default class Voice {
  ambe = false;
  cbVoiceMetadata = undefined;
  audioContext = new (window.AudioContext || window.webkitAudioContext)({
    sampleRate:48000,latencyHint:"interactive"
  });
  filter1 = this.audioContext.createBiquadFilter();
  filter2 = this.audioContext.createBiquadFilter();
  compressor = this.audioContext.createDynamicsCompressor();
  gainNode = this.audioContext.createGain();
  queuedPackets= []
  alive = false;
  currentChannel = undefined;
  recorder = undefined;
  socket = undefined;
  pttDown = false;
  playing = false;
  lastPacket = false;
  currentVolume = 1;
  voiceSubID = undefined;
  onData(data) {
    if(!this.pttDown)
    {
      this.lastPacket = false;
    }
    try {
      if (this.pttDown || !this.lastPacket) {
        if(!this.pttDown && !this.lastPacket)
        {
          console.info("Sending last voice packet")
          this.lastPacket = true;
        }
        if(this.pttDown)
        {
          this.lastPacket = false;
        }
        if (this.currentChannel != undefined) {
          console.debug("[VOICE] Sending voice data packet...")
          let voicePacket = {};
          voicePacket.metadata = {};
          if(this.voiceSubID!=undefined) {
            voicePacket.metadata.subscriberID = this.voiceSubID;
          }
          voicePacket.metadata.enhancedVoice = true;
          voicePacket.metadata.packetLength = 240;
          voicePacket.packet = data;
          this.socket.emit("v2_packet", this.currentChannel, voicePacket);
        } else {
            console.error("here")
        }
      } else {
        console.error("there")
      }
    }
    catch(e)
    {
      console.error("Error sending message")
      console.error(e)
    }
  }
  playSinglePacket(packet)
  {
    console.debug("[VOICETICK]Play single packet")
    let source = this.audioContext.createBufferSource();
    source.buffer = packet.buffer;
    source.connect(this.gainNode)
    this.gainNode.connect(this.audioContext.destination);
    source.start();
    console.debug("[VOICETICK]Wait "+packet.duration*1000+" ms to play next packet")
    source.onended = ()=>{
      if(this.queuedPackets.length>0)
        this.playSinglePacket(this.queuedPackets.shift())
      else
        this.playing = false;
    };
  }

  setSubscriber(subscriberID)
  {
    this.voiceSubID = subscriberID;
  }
  constructor(socket)
  {
    setInterval(()=>{
      if(this.playing === false) {
        if (this.queuedPackets.length > 0) {
          console.debug("[VOICETICK]Start playing voice packets")
          this.playing = true;
          let packet = this.queuedPackets.shift();
          setTimeout(() => {
            console.debug("[VOICETICK]Play first packet now")
            this.playSinglePacket(packet)
          }, 500);
        }
      }
    },5)
    this.socket = socket;
    console.info("[VOICE] Init voice...")
    navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
      this.recorder = new MediaRecorder(stream,{
        mimeType: "audio/webm;codecs:vorbis"
      });
      this.recorder.ondataavailable = e => {
        this.onData(e.data);
      }
    });
    this.socket.on("v2_packet",d=>{
      this.handleV2Packet(d).then();
    })

    this.gainNode.gain.value = this.currentVolume/1.5;
  }
  setVoiceChannel(newChannel)
  {
    if(this.currentChannel!==undefined)
    {
      this.socket.emit("vc_leave",this.currentChannel);
      this.currentChannel=undefined;
    }
    if(newChannel != undefined)
    {
      this.pttDown = false;
      this.currentChannel = "vc["+newChannel+"]";
      this.socket.emit("vc_join",this.currentChannel)
    }
  }
  queuePacketForPlay(voicePacket){
    console.debug("[VOICE] queue voice packet, spot "+(this.queuedPackets.length+1)+" in queue")
    this.queuedPackets.push(voicePacket)
  }
  async handleV2Packet(CAIVoicePacket)
  {
    try {
      let packet;

      try{
        if(this.cbVoiceMetadata!=undefined && this.cbVoiceMetadata!=null && CAIVoicePacket.metadata != undefined) {
          this.cbVoiceMetadata(CAIVoicePacket.metadata);
        }
      }
      catch{
        //
      }
      if(this.ambe && CAIVoicePacket.ambePacket!=undefined && CAIVoicePacket.ambePacket.byteLength)
      {
        packet = CAIVoicePacket.ambePacket;
      }
      else if(CAIVoicePacket.packet!=undefined)
      {
        packet = CAIVoicePacket.packet;
      }
      else
      {
        packet = CAIVoicePacket;
      }
      let result = await this.audioContext.decodeAudioData(packet);
      this.queuePacketForPlay({
        buffer:result,
        duration:result.duration
      });

    }
    catch(e)
    {
      console.log("ERROR")
      console.log(e);
    }
  }
  leaveVoiceChannel()
  {
    this.setVoiceChannel(null)
  }
  setPTT(pttValue) {
    this.pttDown = pttValue;
    if(pttValue)
    {
      this.recorder.start();
      var recordPTT = setInterval(()=>{
        if(this.pttDown)
        {
          this.recorder.stop();
          this.recorder.start();
        }
        else
          clearInterval(recordPTT);
      },240)
    }
    else
    {
      try {
        this.recorder.stop();
      }
      catch(e)
      {
        console.log("failed to stop media")
      }
    }
  }
};
