<template>
  <div>
    <table class="table table-striped table-dark table-bg-darker table-hover">
      <thead class="thead-light">
      <tr>
        <th scope="col">Caller</th>
        <th scope="col">Location</th>
        <th scope="col">Description</th>
        <th scope="col">Time</th>
        <th scope="col">Status</th>
      </tr>
      </thead>
      <tbody>
      <tr v-if="loading">
        <td colspan="5" class="text-center">
          <b-spinner class="align-middle" variant="primary" label="Loading..."></b-spinner>
        </td>
      <tr v-for="(call,index) in calls" @click="toggleCallDropdown($event,index)" class="call-tr">
        <td>
          <template v-if="call.bystander_caller === 1">
            Bystander/Anon
          </template>
          <template v-if="call.bystander_caller !== 1">
            {{ call.caller_name }}
          </template>
        </td>
        <td>{{ call.caller_location }}</td>
        <td>{{ call.description.slice(0,100) }}...</td>
        <td>{{ moment(call.created_at).format('HH:mm') }}</td>
        <td>{{ call.status }}
          <ContextMenu ref="callContext" :data-index="index">
            <b-dropdown-item href="#" v-on:click="showCall(call)">View Call Details</b-dropdown-item>
            <b-dropdown-item href="#" v-on:click="openEditorCall(call)" v-if="doesUserHavePermission('dispatch_call_create') && service === 'dispatch'">Create Active Call</b-dropdown-item>
            <b-dropdown-item href="#" v-on:click="closeCall(call)" v-if="doesUserHavePermission('dispatch_call_delete') && service === 'dispatch'">Delete Call</b-dropdown-item>
          </ContextMenu>
        </td>
      </tr>
      </tbody>
    </table>
    <ShowCall :call-select="callSelect" v-if="callSelect !== null"></ShowCall>
    <ManageCall ref="callManager"></ManageCall>
  </div>

</template>

<script>
import CitizenRepository from "@/api/CitizenRepository";
import ShowCall from "@/components/Partials/LEO/Calls/ShowCall";
import ContextMenu from "@/components/Partials/Dispatch/ContextMenu";
import ManageCall from "@/components/Partials/Dispatch/ManageCall";

export default {
  name: "CallList",
  components: {ManageCall, ContextMenu, ShowCall},
  props:['service'],
  data(){
    return {
      calls:null,
      callSelect: null,
      loading: true
    }
  },
  created(){
    this.updateCitizenCalls();
  },
  methods: {
    updateCitizenCalls(){
      CitizenRepository.getCitizenCalls(this.service).then(response => {
        this.calls = response.data.data;
        this.loading = false;
      });
    },
    showCall(call){
      this.callSelect=call;
      setTimeout(() => this.$root.$emit('bv::show::modal', 'call-modal'), 100);
    },

    closeCall(call){
      let c=call;
      c.status='closed';
      CitizenRepository.updateCitizenCall(c.id, c).then(response => {});
    },

    toggleCallDropdown(event,index){

      const dropdown=this.$refs['callContext'].find(
          el => el.$attrs['data-index'] === index
      );


      this.$refs['callContext'].forEach(e=>{
        if(e.$attrs['data-index'] !== index) e.close();
      });

      dropdown.toggle(event);
    },

    openEditorCall(call){
      this.$refs['callManager'].openCallEditor(call);
    }
  },
  sockets: {
    incomingCitizenCall: function(){
      this.updateCitizenCalls();
    },
    updatedCitizenCall: function(){
      this.updateCitizenCalls();
    }
  }
}
</script>

<style scoped>

.table-bg-darker {
  background-color: #212529;
}

.my-custom-scrollbar {
  max-width: 100%;
  max-height: 300px;
  overflow: auto;
}

.table-wrapper-scroll-y {
  display: block;
}

</style>

<style>
.dropdown-toggle-no-caret::after {
  display: none;
}

.call-tr {
  cursor: pointer;
}

</style>