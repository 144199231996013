import axios from "axios";

const baseDomain=process.env.VUE_APP_API_URL;
const baseURL = baseDomain + "/api/" + process.env.VUE_APP_API_VERSION;

let storeUserInfo= localStorage.getItem('userinfo');

let headers = {'Accept':'application/json'};

if(storeUserInfo!==null){
    let token=JSON.parse(storeUserInfo).access_token;
    if( token ){
        headers.Authorization="Bearer " + token;
    }
}


export default axios.create({
    baseURL,
    headers: headers
});