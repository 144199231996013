<template>
  <div>
    <b-button class="btn btn-block" variant="primary" v-b-modal.vehicle-form-modal @click="openVehicleFormNew">Register New Vehicle</b-button>

    <b-modal @show="()=>{this.errors=null}" no-close-on-backdrop id="vehicle-form-modal" size="md" title="Vehicle Registration" ref="newVehicleModal">
      <b-form-row>
        <b-col>
          <b-form-group
              label="Plate"
              label-for="plate"
          >
            <b-form-input
                id="plate"
                autocomplete="off"
                v-model="form.plate"
                type="text"
                required
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col>
          <b-form-group
              label="Make"
              label-for="make"
          >
            <b-form-input
                id="make"
                v-model="form.make"
                autocomplete="off"
                type="text"
                required
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
              label="Model"
              label-for="model"
          >
            <b-form-input
                id="model"
                v-model="form.model"
                autocomplete="off"
                type="text"
                required
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col>
          <b-form-group
              label="Color"
              label-for="color"
          >
            <b-form-input
                id="color"
                v-model="form.color"
                autocomplete="off"
                type="text"
                required
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
              label="Year"
              label-for="year"
          >
            <b-form-input
                id="year"
                v-model="form.year"
                autocomplete="off"
                type="text"
                required
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-form-row>

      <ErrorDisplay :errors="errors"></ErrorDisplay>

      <template #modal-footer>
        <div class="float-left text-left" style="width: 35%" v-if="vehicle!==null">
          <b-button variant="danger" size="sm" @click="changeButton()" v-if="!stolen">Mark As Stolen</b-button>
          <b-button variant="success" size="sm" @click="changeButton()" v-if="stolen">Mark As Found</b-button>
        </div>
        <div class="text-right" style="width: 61%">

          <template v-if="vehicle===null">
            <b-button
                variant="success"
                size="sm"
                @click="createVehicle"
            >
              Create
            </b-button>
          </template>
          <template v-if="vehicle!==null">
            <b-button
                variant="success"
                size="sm"
                @click="updateVehicle"
            >
              Update
            </b-button>
            <b-button
                variant="danger"
                size="sm"
                @click="deleteVehicle"
            >
              Delete
            </b-button>
          </template>

          <b-button
              variant="secondary"
              size="sm"
              @click="$refs['newVehicleModal'].hide"
          >
            Close
          </b-button>
        </div>
      </template>


    </b-modal>
  </div>
</template>

<script>
import CitizenRepository from "@/api/CitizenRepository";
import ErrorDisplay from "@/components/Partials/ErrorDisplay";
import FlagsRepository from "@/api/FlagsRepository";

export default {
  name: "NewVehicle",
  components: {ErrorDisplay},
  props:['civilian'],
  data: function () {
    return {
      form: {
        owner_id: null,
        plate: null,
        color: null,
        make: null,
        model: null,
        year: null,
      },
      vehicle:null,
      errors: null,
      submiting:false,
      stolen: false
    }
  },
  created() {
    this.form.owner_id=this.civilian.id;
  },
  methods: {

    buttonColor(val){
      if (val) {
        return 'success'
      }
      else {
        return 'danger'
      }
    },

    changeButton() {
      FlagsRepository.vehicleStolen(this.vehicle.id).then(r => {
        this.$emit('updateCivilianData');
        this.stolen = r.data;
      })
    },


    createVehicle: function(){
      this.submiting=true;
      CitizenRepository.createCitizenVehicle(this.form).then(response => {
        this.submiting=false;
        this.$refs['newVehicleModal'].hide();
        this.$emit('updateCivilianData');
      }).catch(error => {
        this.errors=error.response.data.errors;
      });
    },

    updateVehicle: function(){
      this.submiting=true;
      CitizenRepository.updateCitizenVehicle(this.form, this.vehicle.id).then(response => {
        this.submiting=false;
        this.$refs['newVehicleModal'].hide();
        this.$emit('updateCivilianData');
      }).catch(error => {
        this.errors=error.response.data.errors;
      });
    },

    deleteVehicle: function(){
      CitizenRepository.deleteCitizenVehicle(this.vehicle.id).then(response => {
        this.$refs['newVehicleModal'].hide();
        this.$emit('updateCivilianData');
      }).catch(error => {
        this.errors=error.response.data.errors;
      });
    },

    openVehicleFormUpdate: function(vehicle){
      this.vehicle=vehicle;
      this.form.plate=vehicle.plate;
      this.form.color=vehicle.color;
      this.form.year=vehicle.year;
      this.form.make=vehicle.make;
      this.form.model=vehicle.model;
      if (vehicle.flags.stolen) {
        this.stolen=vehicle.flags.stolen.value;
      } else {
        this.stolen = false
      }


      this.$refs['newVehicleModal'].show();
    },

    openVehicleFormNew: function(){
      this.vehicle=null;
      this.form.plate=null;
      this.form.color=null;
      this.form.year=null;
      this.form.make=null;
      this.form.model=null;
    },


  }
}
</script>

<style scoped>
.block {
  display: block
}
</style>