<template>
  <b-modal no-close-on-backdrop id="modal-medical-record-show" size="lg" :title="`Medical Record - #`+medical_record.id"
           v-if="medical_record !== null">

    <b-form-row class="mb-3">
      <b-col>
        <div class="fw-bold font-weight-bolder dd-title">Date/Time</div>
        {{ medical_record.date_time }}
      </b-col>
      <b-col>
        <div class="fw-bold font-weight-bolder dd-title">Civilian Name</div>
        {{ medical_record.civilian.name }}
      </b-col>
    </b-form-row>
    <b-form-row class="mb-3">
      <b-col>
        <div class="fw-bold font-weight-bolder dd-title">Location</div>
        {{ medical_record.location }}
      </b-col>

      <b-col>
        <div class="fw-bold font-weight-bolder dd-title">Cause of Injury</div>
        {{ medical_record.cause_of_injury }}
      </b-col>
    </b-form-row>
    <b-form-row class="mb-3">

      <b-col>
        <div class="fw-bold font-weight-bolder dd-title">Transport Type</div>
        {{ medical_record.transport_type }}
      </b-col>

      <b-col>
        <div class="fw-bold font-weight-bolder dd-title">Hospital</div>
        {{ medical_record.hospital_name }}
      </b-col>
    </b-form-row>
    <b-form-row class="mb-3">

      <b-col>
        <div class="fw-bold font-weight-bolder dd-title">Injury Description</div>
        {{ medical_record.injury_description }}
      </b-col>
    </b-form-row>
    <b-form-row class="mb-3">
      <b-col>
        <div class="fw-bold font-weight-bolder dd-title">Narrative</div>
        {{ medical_record.narrative }}
      </b-col>
    </b-form-row>

    <template #modal-footer="">
      <b-btn v-on:click="$bvModal.hide('modal-medical-record-show')" variant="danger">Close</b-btn>
    </template>

  </b-modal>
</template>

<script>

export default {
  name: "MedicalRecordShow",
  props: ['medical_record'],
  methods: {
    deleteMedicalRecord() {

    },
    test(dir) {
      console.log(dir)
    }
  }
}
</script>

<style scoped>

</style>