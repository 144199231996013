import Repository from "@/api/Repository";

class FlagsRepository {
    vehicleStolen(id) {
        return Repository.post('flags/vehicle/' + id + '/stolen')
    };

    vehicleImpounded(id) {
        return Repository.post('flags/vehicle/' + id + '/impounded')
    };

    getAllFlags(){
        return Repository.get('flags');
    };

    submitflagModel(data){
        return Repository.post('flags/items',data);
    }

    globalSearch(data){
        return Repository.post('search', data);
    };


}

export default new FlagsRepository();