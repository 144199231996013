<template>
  <div>
    <b-button variant="light" size="sm" class="sidebar-button" v-b-toggle.sidebar>
      ⚙️
    </b-button>
    <b-sidebar id="sidebar" title="Map Options" right shadow="">
      <div class="px-3 py-2">
        <b-form-checkbox
            id="checkbox-1"
            v-model="form.showSmartSigns"
            name="checkbox-1"
        >
          Show SmartSigns
        </b-form-checkbox>
        <b-form-checkbox
            id="checkbox-2"
            v-model="form.showSmartMotorways"
            name="checkbox-1"
        >
          Show SmartMotorways
        </b-form-checkbox>
      </div>
    </b-sidebar>
    <SmartSignsEdit ref="SmartSignsForm"/>
    <SmartMotorwaysEdit ref="SmartMotorwaysForm"/>
    <div id="map"></div>
  </div>
</template>

<script>

import "leaflet/dist/leaflet.css"
import L from "leaflet";
import SmartSignsEdit from "../IngameIntegration/SmartSignsEdit";
//import {LMap, LImageOverlay, LMarker} from "@vue-leaflet/vue-leaflet";
import { CRS } from "leaflet/dist/leaflet-src.esm";
import IngameRepository from "../../../../api/IngameRepository";
import SmartMotorwaysEdit from "../IngameIntegration/SmartMotorwaysEdit";

export default {
  name: "Livemap",
  components: {SmartMotorwaysEdit, SmartSignsEdit},
  data(){
    return {
      markers: {
        'citizenCalls':{},
        'units':{},
        'dispatchCalls':{},
        'smartsigns':{},
        'smartmotorways':{},
      },
      players: {},
      markerTypes:{
        999: {
          iconUrl: "images/icons/" + "/debug.png",
          iconSize: [23, 32],
          popupAnchor: [0, 0],
          iconAnchor: [11.5, 0] // Bottom middle
        },
        6: {
          iconUrl: "images/icons/" + "/normal.png",
          iconSize: [22, 32],
          popupAnchor: [0, 0],
          iconAnchor: [11, 0]
        },
        '10-6': {
          iconUrl: "images/icons/" + "/10-6.png",
          iconSize: [28, 28],
          popupAnchor: [0, 0],
          iconAnchor: [0, 0]
        },
        '10-8': {
          iconUrl: "images/icons/" + "/10-8.png",
          iconSize: [28, 28],
          popupAnchor: [0, 0],
          iconAnchor: [0, 0]
        },
        '10-23': {
          iconUrl: "images/icons/" + "/10-23.png",
          iconSize: [28, 28],
          popupAnchor: [0, 0],
          iconAnchor: [0, 0]
        },
        '10-97': {
          iconUrl: "images/icons/" + "/10-97.png",
          iconSize: [28, 28],
          popupAnchor: [0, 0],
          iconAnchor: [0, 0]
        },
        '10-11': {
          iconUrl: "images/icons/" + "/10-11.png",
          iconSize: [28, 28],
          popupAnchor: [0, 0],
          iconAnchor: [0, 0]
        },
        '10-15': {
          iconUrl: "images/icons/" + "/10-15.png",
          iconSize: [28, 28],
          popupAnchor: [0, 0],
          iconAnchor: [0, 0]
        },
        '10-99': {
          iconUrl: "images/icons/" + "/10-99.png",
          iconSize: [28, 28],
          popupAnchor: [0, 0],
          iconAnchor: [0, 0]
        },
        'smartsign': {
          iconUrl: "images/icons/" + "/smartsign.png",
          iconSize: [16, 16],
          popupAnchor: [5, 5],
          iconAnchor: [-3, -3]
        },
        'smartmotorways-orange': {
          iconUrl: "images/icons/" + "/smartmotorways-orange.png",
          iconSize: [16, 16],
          popupAnchor: [5, 5],
          iconAnchor: [-3, -3]
        },
        'smartmotorways-blue': {
          iconUrl: "images/icons/" + "/smartmotorways-blue.png",
          iconSize: [16, 16],
          popupAnchor: [5, 5],
          iconAnchor: [-3, -3]
        }
      },
      signs:{},
      motorways:{},
      form:{
        showSmartSigns:false,
        showSmartMotorways:false,
      }
    }
  },
  mounted() {

    window.vm=this;
    let access = this.doesUserHavePermission('dispatch_access');

    if(!access){
      this.$router.push('/profile');
    }


    window.CurrentLayer = undefined;

    window.CurrentLayer= L.tileLayer('images/tiles/minimap_{y}_{x}.png',{ minZoom: -2, maxZoom: 4, tileSize: 1024, maxNativeZoom: 0, minNativeZoom: 0, tileDirectory: 'images/tiles' });

    window.Map = L.map('map', {
      crs: L.CRS.Simple,
      layers: [CurrentLayer]
    }).setView([0,0], 0);

    Map.on("load",function() { setTimeout(() => {
      Map.invalidateSize();
    }, 1); });

    var mapBounds = this.getMapBounds(CurrentLayer);

    Map.setMaxBounds(mapBounds);
    Map.fitBounds(mapBounds);
    setTimeout(() => {
      Map.invalidateSize();
    }, 1)

    this.form.showSmartSigns=(localStorage.getItem("map.smartsigns") === "true") ? true : false;
    this.form.showSmartMotorways=(localStorage.getItem("map.smartmotorways") === "true") ? true : false;

    this.$nextTick(() => {
      this.createSmartSignsMarkers();
      this.createMotorwaysMarkers();
    });
  },
  async beforeMount() {
    // HERE is where to load Leaflet components!
    //const { circleMarker } = await import("leaflet/dist/leaflet-src.esm");
  },
  methods: {
    getMapBounds(layer){
      var h = layer.options.tileSize * 3,
          w = layer.options.tileSize * 2;

      var southWest = Map.unproject([0, h], 0);
      var northEast = Map.unproject([w, 0], 0);

      return new L.LatLngBounds(southWest, northEast);
    },
    createMarker(animated, draggable, objectRef, title, markerUid, markerFolder, tooltip, hidePopup=false) {

      var name = objectRef.reference;
      if (name === "@DEBUG@@Locator") {
        name = "@Locator";
      }
      objectRef.position = this.stringCoordToFloat(objectRef.position);
      //console._log(objectRef.position);
      var coord = this.convertToMap(objectRef.position.x, objectRef.position.y);
      //console._log(coord);
      var markerType = objectRef.type;

      //console._log(JSON.stringify(locationType));

      var html = "";

      if (objectRef.description !== ""){
        html += '<div class="row info-body-row">' + objectRef.description + "</div>";
      }

      var infoContent = '<div class="info-window"><div class="info-header-box"><div class="info-header"><b>' + name + '</b></div></div><div class="clear"></div><div class=info-body>' + html + "</div></div>";

      var image = L.icon(markerType);

      var where = window.Map;
      if(objectRef.data && objectRef.data.isPlayer && window.config.groupPlayers){
        // Add to the cluster layer
        where = window.PlayerMarkers;
      }
      if(where === undefined){
        console.warn("For some reason window.Map or window.PlayerMarkers is undefined");
        console.warn("Cannot add the blip: " + objectRef);
        where = createClusterLayer();
      }

      var marker = L.marker(coord, {
        title: title,
        id: this.markers.length,
        icon: image,
        object: objectRef,
        player: objectRef.data.player ? objectRef.data.player : undefined,
        draggable: draggable ? true : false
      }).addTo(where).bindPopup(infoContent).bindTooltip(tooltip, {
        permanent: !hidePopup,
        interactive: true,
        direction: "left",
      });

      this.markers[markerFolder][markerUid]=marker;
      return this.markers[markerFolder].length;
    },
    Coordinates(x, y, z) {
      this.x = x;
      this.y = y;
      this.z = z;
    },
    MarkerObject(reference, position, type, description, data) {
      this.reference = reference;
      this.position = position;
      this.type = type;
      this.description = description;
      this.data = data;
    },
    convertToMap(x, y) {
      var h = CurrentLayer.options.tileSize * 3,
          w = CurrentLayer.options.tileSize * 2;

      var latLng1 = Map.unproject([0, 0], 0);
      var latLng2 = Map.unproject([w / 2, (h - CurrentLayer.options.tileSize)], 0);

      var game_1_x = -4000.00 - 200;
      var game_1_y = 8000.00 + 420;
      var game_2_x = 400.00 - 30;
      var game_2_y = -300.0 - 340.00;

      var rLng = latLng1.lng + (x - game_1_x) * (latLng1.lng - latLng2.lng) / (game_1_x - game_2_x);
      var rLat = latLng1.lat + (y - game_1_y) * (latLng1.lat - latLng2.lat) / (game_1_y - game_2_y);
      return {
        lat: rLat,
        lng: rLng
      };
    },
    stringCoordToFloat(coord) {
      return {
        x: parseFloat(coord.x),
        y: parseFloat(coord.y),
        z: parseFloat(coord.z),
      }
    },
    clearMarker(id,markerFolder) {
      if (this.markers[markerFolder][id] !== null) {
        this.hideMarker(this.markers[markerFolder][id]);
        this.markers[markerFolder][id].remove();
        delete this.markers[markerFolder][id];
      }
    },
    clearAllMarkers(markerFolder) {
      for (let k in this.markers[markerFolder]) {
        this.clearMarker(k,markerFolder);
      }
    },
    hideMarker(marker){
      window.Map.removeLayer(marker);
    },
    showMarker(marker){
      window.Map.addLayer(marker);
    },
    createSmartSignMarker(s){
      let text=s.defaultText;
      if(s.hasOwnProperty("text")) text=s.text;

      let desc='<span style="font-weight: bolder;">Current Text</span><br>Line 1: '+text[0]+'<br>Line 2: '+text[1]+'<br>Line 3: '+text[2];
      desc+='<br><a href="#" onclick="window.vm.openSmartSignEdit('+s.id+')">Edit sign!</a>'

      let objc=new this.MarkerObject("Sign " + s.id, new this.Coordinates(s.x,s.y,s.z), this.markerTypes["smartsign"], desc,"","" );
      this.createMarker(false,false,objc,"", s.id,"smartsigns","Sign " + s.id,true);

      let m = this.markers["smartsigns"][s.id];
      if(!this.form.showSmartSigns){
        window.Map.removeLayer(m);
      } else {
        window.Map.addLayer(m);
      }
    },
    createSmartSignsMarkers(){

      IngameRepository.getSmartSigns().then((response) => {
        let signs = response.data;

        for(let k in signs){
          if(signs.hasOwnProperty(k)){
            let s=signs[k];
            this.signs[s.id]=s;
            this.createSmartSignMarker(s);
          }
        }
      });
    },
    createMotorwaysMarker(s,k){
      let desc='Direction: '+s.direction+'<br>Lanes: '+s.lanes;
      desc+='<br><a href="#" onclick="window.vm.openMotorwayEdit('+s.id+')">Edit motorway!</a>'

      let colors={
        "North":"orange",
        "South":"blue",
        "East":"orange",
        "West":"blue"
      };

      let objc=new this.MarkerObject("Motorway " + s.id, new this.Coordinates(s.position.x,s.position.y,s.position.z), this.markerTypes["smartmotorways-"+colors[s.direction]], desc,"","" );
      this.createMarker(false,false,objc,"", s.id,"smartmotorways","Motorway " + s.id,true);

      let m = this.markers["smartmotorways"][s.id];
      if(!this.form.showSmartMotorways){
        window.Map.removeLayer(m);
      } else {
        window.Map.addLayer(m);
      }
    },
    createMotorwaysMarkers(){

      IngameRepository.getSmartMotorways().then((response) => {
        let signs = response.data;

        for(let k in signs){
          if(signs.hasOwnProperty(k)){
            let s=signs[k];
            this.motorways[s.id]=s;
            this.createMotorwaysMarker(s);
          }
        }
      });
    },
    hideShowFolder(folder,show){
      for(let k in this.markers[folder]){
        if(this.markers[folder].hasOwnProperty(k)){
          let m = this.markers[folder][k];
          if(show) this.showMarker(m); else this.hideMarker(m);
        }
      }
    },
    openSmartSignEdit(k){
      let sign=this.signs[k];
      this.$refs['SmartSignsForm'].open([sign.id],sign.text);

    },
    openMotorwayEdit(k){
      let motorway=this.motorways[k];
      this.$refs['SmartMotorwaysForm'].open(motorway);

    },
  },
  sockets:{
    updateUnitsPositions: function(data){
      for(let k in data){

        if(!data.hasOwnProperty(k)) continue;
        let unit=data[k];

        if(unit.current_status.code === '10-7' && this.players.hasOwnProperty(unit.id)){
          this.clearMarker("unit"+unit.identifier,'units');
          continue;
        }

        if(this.players.hasOwnProperty(unit.id)){
          let marker=this.markers['units'][unit.id];
          marker.setLatLng(this.convertToMap(unit.x,unit.y,unit.z));
          marker.setIcon(L.icon(this.markerTypes[unit.current_status.code]))
          continue;
        }

        let desc="<span style='font-weight: bolder; color:"+unit.current_status.color+"'>"+unit.current_status.name+"</span><br>"+unit.street+"<br>"+unit.agency.short_name+"<br>"+unit.rank+" "+unit.name;

        let tooltip=unit.identifier + " (" + unit.agency.short_name + ")";
        let objc=new this.MarkerObject(unit.identifier, new this.Coordinates(unit.x,unit.y,unit.z), this.markerTypes[unit.current_status.code], desc,"","" );
        let len=this.createMarker(true,true,objc,"", unit.id,"units",tooltip);

        let marker=this.markers.units[len-1];

        this.players[unit.id]={
          markerObj: marker,
          markerNum:len-1,
        };
      }
    },
    incomingCitizenCall: function(data){
      let services=data.calltype.services.split(',');
      if(services.includes('dispatch')){
        let desc=data.description.slice(0,100);
        let objc=new this.MarkerObject(data.calltype.name, new this.Coordinates(data.x,data.y,data.z), this.markerTypes[999], desc,"","" );
        this.createMarker(true,false,objc,"", data.id,"citizenCalls");
      }
    },
    updatedCitizenCall: function(data){
      if(this.markers['citizenCalls'].hasOwnProperty(data.id) && data.status === 'closed'){
        this.clearMarker(data.id,'citizenCalls');
      }
    },
    incomingDispatchCall: function(data){
        let desc=data.description.slice(0,100);
        let objc=new this.MarkerObject(data.title, new this.Coordinates(data.x,data.y,data.z), this.markerTypes[6], desc,"","" );
        this.createMarker(true,false,objc,"", data.id,"dispatchCalls");
    },
    updateDispatchCall: function(data){
      if(this.markers['dispatchCalls'].hasOwnProperty(data.id) && data.call_status === 'closed'){
        this.clearMarker(data.id,'dispatchCalls');
      }
    },
    smartSignUpdated: function(data){
      if(this.markers['smartsigns'].hasOwnProperty(data.id)){
        this.clearMarker(data.id,'smartsigns');
        this.signs[data.id].text=data.text;
        this.createSmartSignMarker(this.signs[data.id]);
      }
    }
  },
  watch: {
    // whenever question changes, this function will run
    'form.showSmartSigns'(newVal) {
      this.hideShowFolder("smartsigns",newVal);
      localStorage.setItem("map.smartsigns",newVal);
    },
    'form.showSmartMotorways'(newVal) {
      this.hideShowFolder("smartmotorways",newVal);
      localStorage.setItem("map.smartmotorways",newVal);
    }
  }

}
</script>

<style scoped>
#map {
  height: 100vh;
  width: 100%;
  background-color: #143d6b;
}

.sidebar-button {
  position: absolute;
  top: 90px;
  left: 13px;
  z-index: 999;
}
</style>
