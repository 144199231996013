<template>
  <div>
    <b-modal id="smartmotorways-edit" no-close-on-backdrop header-class="motorway-header" content-class="bg-motorway" @ok="save" size="xl" ref="smartmotorways-edit-modal">
      <b-row>
        <b-col cols="7" class="pr-3">
          <b-row>
              <div :data-n="n-1" @dragend="dragend($event)" @dragover="dragover($event)" class="motorway-input d-flex flex-wrap align-items-center first-ml" v-for="n in currentMotorway.lanes" :key="n">
                <b-img class="motorway-image selected-image" v-if="getObjKey(images, currentMotorway.speeds[n-1])" :data-img="getObjKey(images, currentMotorway.speeds[n-1])" :src="'/images/motorways/'+getObjKey(images, currentMotorway.speeds[n-1])"
                @dragstart="dragstart($event)"></b-img>
              </div>
          </b-row>
        </b-col>
        <b-col class="" @dragend="dragend($event)" @dragover="dragover($event)">
          <div class="icon-bg">
            <div class="m-auto">
              <b-img v-for="(v,k) in images" :src="'/images/motorways/'+k" :data-img="k" :key="k" class="motorway-image"
                     @dragstart="dragstart($event)"></b-img>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import IngameRepository from "../../../../api/IngameRepository";

export default {
  name: "SmartMotorwaysEdit.vue",
  data(){
    return {
      currentMotorway: {},
      images: {
        'arrow_left.png': 1,
        'arrow_right.png': 2,
        'red_x.png': 3,
        'speed_20.png': 20,
        'speed_30.png': 30,
        'speed_40.png': 40,
        'speed_50.png': 50,
        'speed_60.png': 60,
        'national_speed.png': 70,
      },
      dragOverElem: null,
      clearing: false,
    }
  },
  methods: {
    open(motorway) {
      this.$refs["smartmotorways-edit-modal"].show()
      this.currentMotorway = motorway
    },
    getObjKey(obj, value) {
      return Object.keys(obj).find(key => obj[key] === value);
    },
    dragover(ev){
      this.dragOverElem = ev.target
      ev.preventDefault()
    },
    dragstart(ev){
      if (ev.target.classList.contains('selected-image')) {
        this.clearing = ev.target.parentElement
      }
    },
    dragend(ev, el){
      ev.preventDefault()
      if(this.dragOverElem){
        if (this.dragOverElem.tagName === 'IMG') {
          this.dragOverElem = this.dragOverElem.parentElement
        }
        let index = this.dragOverElem.dataset.n
        if (index) {
          this.currentMotorway.speeds[index] = this.images[ev.target.dataset.img]
          this.$forceUpdate()
        } else {
          if (this.clearing) {
            console.log('clearing')
            this.currentMotorway.speeds[this.clearing.dataset.n] = 0
            this.$forceUpdate()
            this.clearing = null;
          }
        }
      }
    },
    save(){

      let d = {
        ids: [this.currentMotorway.id],
        speeds: this.currentMotorway.speeds,
      }
      console.log(d)
      IngameRepository.updateSmartMotorways(d).then(response => {
        this.$refs["smartmotorways-edit-modal"].hide()
      })
    }
  },
  mounted() {
    
  }
}
</script>

<style scoped>
.motorway-input {
  background-color: black;
  border-radius: 1em;
  width: 7.5em;
  height: 35vh;
  align-self: stretch;
  margin-right: 1em;
}

.motorway-image {
  width: 5em;
  margin: 1em;
}

.selected-image {
  margin: auto;
}

.first-ml:first-child{
  margin-left: 6em;
}


.icon-bg {
  background-color: black;
  border-radius: 1em;
  margin-right: 6.7em;
}

</style>

<style>
.motorway-header {
  border-bottom: 0 !important;
  padding-bottom: 2em;
}

.bg-motorway {
  background-image: url('/images/motorways/Highways_England.png');
}
</style>
