import Repository from "./Repository";

class IngameRepository {

    getSmartSigns(){
        return Repository.get('fivem/smart-signs');
    }

    updateSmartSigns(data){
        return Repository.post('fivem/smart-signs/update',data);
    }

    getSmartMotorways(){
        return Repository.get('fivem/smart-motorways');
    }

    updateSmartMotorways(data){
        return Repository.post('fivem/smart-motorways/update',data);
    }
}

export default new IngameRepository();