<template>
  <div>
    <h5 class="text-white">Civilians <b-btn variant="warning" @click="showHidden" v-if="hidden===0" size="sm">Show Hidden</b-btn> <b-btn variant="success" @click="showHidden" v-if="hidden===1" size="sm">Show UnHidden</b-btn></h5>

    <table class="table table-striped table-dark table-hover table-bg-darker">
      <thead class="thead-light">
      <tr>
        <th scope="col">Name</th>
        <th scope="col">DOB</th>
        <th scope="col">Gender</th>
        <th scope="col">Ethnicity</th>
        <th scope="col">Occupation</th>
        <th scope="col">&nbsp</th>
      </tr>
      </thead>
      <tbody>
      <tr v-if="loading">
        <td colspan="6" class="text-center">
          <b-spinner class="align-middle" variant="primary" label="Loading..."></b-spinner>
        </td>
      </tr>
      <tr v-for="civ in civilians">
        <td>{{ civ.name }}</td>
        <td>{{ civ.dob }}</td>
        <td>{{ civ.gender.name }}</td>
        <td>{{ civ.ethnicity.name }}</td>
        <td>{{ civ.occupation }}</td>
        <td>
          <router-link :to="{ name:'citizen', params: { citizen:civ.id } }"  class="btn btn-secondary">
            Select
          </router-link>

          <b-btn variant="primary" @click="$emit('edit',civ)">Edit</b-btn>
          <b-btn variant="danger" v-if="civ.hidden===0" @click="hideCivilian(civ)">Hide</b-btn>
          <b-btn variant="success" v-if="civ.hidden===1" @click="unhideCivilian(civ)">UnHide</b-btn>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

</template>

<script>
import CitizenRepository from "@/api/CitizenRepository";

export default {
  name: "CivilianList",
  data(){
    return {
      civilians:null,
      hidden:0,
      loading: true
    }
  },
  created(){
    this.updateData();
  },

  methods: {
    hideCivilian(civ){
      let data=civ;
      data.hidden=1;
      CitizenRepository.updateCitizen(data,civ.id).then(response => {
        this.updateData();
      });
    },
    unhideCivilian(civ){
      let data=civ;
      data.hidden=0;
      CitizenRepository.updateCitizen(data,civ.id).then(response => {
        this.updateData();
      });
    },

    updateData(){
      console.log("Hidden: " + this.hidden);
      CitizenRepository.getCitizens(this.hidden).then(response => {
        this.civilians=response.data.data;
        this.loading=false;
      });
    },

    showHidden(){
      if(this.hidden===0){
        this.hidden=1;
      } else if(this.hidden===1){
        this.hidden=0;
      }

      this.updateData();
    }
  }
}
</script>

<style scoped>

.table-bg-darker {
  background-color: #212529;
}

</style>