<template>
  <div>
    <b-form-row>
      <b-col cols="3">
        <div class="fw-bold font-weight-bolder dd-title">Identification Number</div>
        {{ evidence.identification_number }}
      </b-col>

      <b-col cols="3">
        <div class="fw-bold font-weight-bolder dd-title">Type</div>
        {{ evidence.type }}
      </b-col>

      <b-col cols="6">
        <div class="fw-bold font-weight-bolder dd-title">Collecting Officer</div>
        {{ evidence.collect_officer.rank }}, {{ evidence.collect_officer.name }}, {{ evidence.collect_officer.agency.short_name }} [{{ evidence.collect_officer.identifier }}]
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col cols="6">
        <div class="fw-bold font-weight-bolder dd-title">Collect Location</div>
        {{ evidence.collect_location }}
      </b-col>

      <b-col cols="6">
        <div class="fw-bold font-weight-bolder dd-title">Storage Location</div>
        {{ evidence.storage_location }}
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col cols="8">
        <div class="fw-bold font-weight-bolder dd-title">Description</div>
        {{ evidence.description }}
      </b-col>

      <b-col cols="4">
        <b-img :src="evidence.proof.url" fluid-grow></b-img>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col cols="12">
        <br>
        <hr>
        <ChainOfCustody @updateCoc="updateCoc" :value="this.evidence.chain_of_custody"></ChainOfCustody>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col cols="2" offset="10">
        <b-btn variant="success" class="d-inline float-right" size="sm" @click="updateEvidence">Update
          <span v-if="isLoading">
            <b-spinner type="grow" variant="success" class="align-middle" small></b-spinner>
          </span>
        </b-btn>
      </b-col>
    </b-form-row>
    <b-form-row  v-if="errors">
      <b-col cols="12">
        <ErrorDisplay :errors="errors"></ErrorDisplay>
      </b-col>
    </b-form-row>
  </div>
</template>

<script>
import ChainOfCustody from "@/components/Partials/Detectives/FormCaseModal/Evidence/ChainOfCustody";
import DetectiveRepository from "@/api/DetectiveRepository";
import ErrorDisplay from "@/components/Partials/ErrorDisplay";
export default {
  name: "ShowEvidence",
  components: {ErrorDisplay, ChainOfCustody},
  props: ['evidence','index'],
  data(){
    return {
      isLoading: false,
      errors: null,
    }
  },
  methods: {
    updateCoc(coc){
      this.evidence.chain_of_custody = coc;
      this.$emit('updateEvidence', this.evidence);
    },
    updateEvidence(){
      this.errors=null;
      this.isLoading=true;
      DetectiveRepository.saveEvidence(this.evidence.id,{chain_of_custody:this.evidence.chain_of_custody}).then(response => {
        this.evidence.chain_of_custody=response.data.chain_of_custody;
        this.isLoading=false;
      }).catch(error => {
        this.errors=error.response.data.errors;
        this.isLoading=false;
      });
    }
  }
}
</script>

<style scoped>

</style>