<template>
  <div>
    <b-modal @show="()=>{this.errors=null}" no-close-on-backdrop id="arrest-report-form-modal" size="lg" title="Create Arrest Report" @ok="submitArrest">
      <template #modal-ok>
        Sign &amp; Submit Report
    </template>

      <b-form-group
          label="Arrested Civilian"
          label-for="civilian"
      >
        <b-form-input
            id="civilian"
            v-model="civilian.name"
            type="text"
            placeholder="Civilian Name"
            autocomplete="off"
            disabled
        ></b-form-input>
      </b-form-group>

      <AddCharges @change="chargesChanged"></AddCharges>

      <b-form-row v-bind:key="k-1">
         <b-col cols="12">
           <small><b-badge variant="danger">Important</b-badge> <em>Final sentence recommendations will be decided by the Prosecutor.</em></small><br />
           <small><b-badge variant="info">Note</b-badge> <em>Not all state sentence recommendations are prefilled in CAD. They will be updated by DOJ Staff.</em></small>
         </b-col>
      </b-form-row>

      <hr>

      <b-form-group
          label="Date/Time of Arrest"
          label-for="date_time"
      >
        <b-form-row>
          <b-col cols="8">
            <b-form-input
                id="date_time"
                v-model="form.date"
                type="date"
                autocomplete="off"
                required
            ></b-form-input>
          </b-col>
          <b-col cols="4">
            <b-form-input
                id="date_time"
                autocomplete="off"
                v-model="form.time"
                type="time"
                required
            ></b-form-input>
          </b-col>
        </b-form-row>

      </b-form-group>

      <b-form-group
          label="Property"
          label-for="contents"
      >
        <b-form-input
            id="contents"
            autocomplete="off"
            v-model="form.contents"
            type="text"
            placeholder="Phone, Wallet, Keys..."

        ></b-form-input>
      </b-form-group>

      <b-form-group
          label="Narrative"
          label-for="narrative"
      >
        <b-form-textarea
            id="narrative"
            v-model="form.narrative"
            type="text"
            required
            rows="6"
            placeholder="Provide all the detail and evidence required to prosecute this person."
        ></b-form-textarea>
        <br />
        <strong>Declaration</strong><br />
        <em>By submitting this Arrest Report, I, <u>{{this.unit.name}}</u>, <u>{{this.unit.rank}}</u> of <u>{{this.unit.agency.full_name}}</u>,
           do solemnly and sincerely declare that this narrative is the truth, the whole truth, and nothing but the truth.
           I understand that false information can result in me being prosecuted.</em>
           <br /><br />
           <em>Furthermore, I understand that incorrectly filed reports, including those with limited or no narrative, will
             likely not be prosecuted by the State. I declare that I have provided as much information as I have.</em>
      </b-form-group>

      <ErrorDisplay :errors="errors"></ErrorDisplay>

    </b-modal>
  </div>
</template>

<script>
import AddCharges from "@/components/Partials/LEO/Reports/AddCharges";
import LeoRepository from "@/api/LeoRepository";
import ErrorDisplay from "@/components/Partials/ErrorDisplay";
export default {
  name: "ArrestReport",
  components: {ErrorDisplay, AddCharges},
  props: ['civilian'],
  data: function () {
    return {
      form: {
        charges: null,
        date: null,
        time: null,
        contents: null,
        narrative: null,
      },
      errors: null,
      submited:false
    }
  },
  methods: {
    chargesChanged(event){
      this.form.charges=event;
    },
    submitArrest(evt){
      evt.preventDefault();
      if(this.submited) return false;
      this.submited=true;

      let data=this.form;
      data.arresting_officer_id=this.unit.id;
      data.civilian_id=this.civilian.id;
      data.date_time=this.form.date+" "+this.form.time;
      LeoRepository.createArrestReport(data).then(response => {
        this.submited=false;
        this.$root.$emit('bv::hide::modal', 'arrest-report-form-modal');
      }).catch(error => {
        this.submited=false;
        this.errors=error.response.data.errors;
      });
    }
  },
  computed: {
    unit() {
      return this.$store.state.auth.leoUnit;
    }
  },
  mounted() {
    this.form.date=this.moment().format('Y-MM-D');
    this.form.time=this.moment().format('HH:mm:ss');
    console.log(this.unit);
  }
}
</script>

<style scoped>

</style>