<template>
  <div>
    <b-form-row>
      <b-col cols="3">
        <div>Citizen Name</div>
        {{ statement.citizen_name }}
      </b-col>
      <b-col cols="3">
        <div>Citizen Contact</div>
        {{ statement.citizen_contact }}
      </b-col>
      <b-col cols="3">
        <div>Citizen Address</div>
        {{ statement.citizen_address }}
      </b-col>
      <b-col cols="3">
        <div>Relation</div>
        {{ statement.relation }}
      </b-col>

    </b-form-row>
    <b-form-row>
      <b-col>
        <div>Statement</div>
        {{ statement.statement }}
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col>
        <div>Officer's Notes</div>
        {{ statement.officer_notes }}
      </b-col>
    </b-form-row>
  </div>
</template>

<script>
export default {
  name: "ShowStatement",
  props: ['statement'],
  methods: {
    log(statement) {
      console.log(statement);
    }
  }
}
</script>

<style scoped>
.form-row div[class*="col"] div:first-child {
  font-weight: bolder;
}
</style>
