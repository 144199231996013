<template>
  <b-modal id="select-position-map-modal" ref="selectPositionMapModal" size="xl" title="Map Selector" @ok="submit">
    <MapSelector @updateLatLang="update" :marker="marker"></MapSelector>
  </b-modal>
</template>

<script>
import MapSelector from "./MapSelector";
export default {
  name: "SelectPositionMapModal",
  components: {MapSelector},
  props: ['marker'],
  data(){
    return {
      map: {
        latlang: null,
      },
      data: {
        marker: null,
      }
    }
  },
  methods: {
    submit(){
      this.$emit('submit', this.map.latlang);
      this.$refs['selectPositionMapModal'].hide();
    },
    update(latlang){
      this.map.latlang = latlang;
    }
  }
}
</script>

<style scoped>

</style>
