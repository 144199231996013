<template>
<div>
  <ValidateUnit v-if="unit===null"></ValidateUnit>
  <div class="container">
    <div class="row mt-4">
      <div class="col-3">
      </div>
      <div class="col-6">
        <div class="row">
          <div class="col text-center">
            <StatusButtons :unit="unit" :status-buttons="statusButtons"></StatusButtons>
          </div>
        </div>
        <div class="row">
          <div class="col text-center">
            <b-button variant="primary"  v-b-modal.lookup-modal>Lookup</b-button>
            <b-button class="btn" variant="warning" v-b-modal.notepad>Notepad</b-button>
            <Notepad :block="false" type="fire"></Notepad>
            <Lookup :fire="true" ></Lookup>
          </div>
        </div>
      </div>

      <div class="col-4">

      </div>

    </div>
  </div>

  <div class="row align-items-start mt-4">
    <div class="col-5">
      <h5 class="text-white">911 Calls</h5>
      <CallList service="fd"></CallList>
    </div>
    <div class="col-3">
      <h5 class="text-white">Status:</h5>
      <Status :unit="unit"></Status>
    </div>
    <div class="col-4">
    </div>

  </div>
</div>
</template>

<script>
import Notepad from "@/components/Partials/Notepad";
import StatusButtons from "@/components/Partials/LEO/StatusButtons";
import Lookup from "@/components/Partials/LEO/Lookup/Lookup";
import DataRepository from "@/api/DataRepository";
import CallList from "@/components/Partials/LEO/Calls/CallList";
import Status from "@/components/Partials/LEO/Status";
import ValidateUnit from "@/components/Partials/ValidateUnit";
export default {
name: "FD",
  components: {ValidateUnit, Status, CallList, Lookup, StatusButtons, Notepad},
  data(){
    return {
      statusButtons: null,
    }
  },
  computed: {
    unit() {
      return this.$store.state.auth.leoUnit;
    }
  },
  created() {

    DataRepository.getUnitStatus()
        .then((response) => {
          this.statusButtons=response.data.data;
          this.loading=false;
        });

  }
}

</script>

<style scoped>
.padding-right{
  padding-right:5px;

}

.padding-left{
  padding-left:5px;
}
</style>