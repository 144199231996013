<template>
  <div class="unit-warn">
    <p class="text-center h1 mt-5 text-danger icon-top">
      <b-icon-x-circle></b-icon-x-circle>
    </p>
    <p class="text-center h4 text-white text-uppercase">
      You need to select a identifier on top before using this interface.
    </p>
  </div>
</template>

<script>
export default {
  name: "ValidateUnit",
  props: ['unit']
}
</script>

<style scoped>
.unit-warn {
  position: absolute;
  width: 80%;
  height: 80%;
  background-color: rgba(0,0,0,0.9);
  z-index:999;
  border-radius: 25px;
  left: 10%;
}
</style>