<template>

  <b-modal no-close-on-backdrop :id="this.modalId" size="lg" title="BOLO Information"  v-if="bolo !== null">
    <ul class="list-group" v-if="">
      <li class="list-group-item d-flex justify-content-between align-items-start">
        <div class="ms-2 me-auto">
          <div class="fw-bold font-weight-bolder">Officer</div>
          {{ bolo.officer.rank }} {{ bolo.officer.name }} <br>{{ bolo.officer.identifier }}
        </div>
      </li>

      <li class="list-group-item d-flex justify-content-between align-items-start" v-if="bolo.civilian !== null">
        <div class="ms-2 me-auto">
          <div class="fw-bold font-weight-bolder">Citizen Name</div>
          {{ bolo.civilian.name }}<br>DOB: {{ bolo.civilian.dob }}
        </div>
      </li>

      <li class="list-group-item d-flex justify-content-between align-items-start">
        <div class="ms-2 me-auto description">
          <div class="fw-bold font-weight-bolder">Description</div>
          <template v-if="!editMode">{{ bolo.description }}</template>
          <template v-if="editMode">
            <b-form-textarea
                id="textarea"
                v-model="edit.description"
                placeholder="Subject/vehicle description"
                cols="500"
                rows="3"
            ></b-form-textarea>
          </template>
        </div>
      </li>

      <li class="list-group-item d-flex justify-content-between align-items-start">
        <div class="ms-2 me-auto wanted_for">
          <div class="fw-bold font-weight-bolder">Wanted for</div>
          <template v-if="!editMode">{{ bolo.wanted_for }}</template>
          <template v-if="editMode">
            <b-form-textarea
                id="textarea"
                v-model="edit.wanted_for"
                placeholder="What is this subject wanted for"
                cols="500"
                rows="3"
            ></b-form-textarea>
          </template>
        </div>
      </li>

      <li class="list-group-item d-flex justify-content-between align-items-start">
        <div style="width: 100%;">
          <div class="fw-bold font-weight-bolder">Plate</div>
          <template v-if="!editMode">{{ bolo.plate }}</template>
          <template v-if="editMode">
            <b-form-input type="text" v-model="edit.plate" autocomplete="off" ></b-form-input>
          </template>
        </div>
      </li>

      <li class="list-group-item d-flex justify-content-between align-items-start">
        <div style="width: 100%;">
          <div class="fw-bold font-weight-bolder">Last seen</div>
          <template v-if="!editMode">{{ bolo.last_seen }}</template>
          <template v-if="editMode">
            <b-form-input type="text" v-model="edit.last_seen" class="form-control" autocomplete="off"></b-form-input>
          </template>
        </div>
      </li>
    </ul>

    <template #modal-footer="{ ok, cancel, hide }">
      <b-button  variant="primary" @click="startEdit()" v-if="(doesUserHavePermission('bolo_edit') || bolo.officer_id === unit.id) && !editMode">
          Edit
      </b-button>
      <b-button  variant="primary" @click="saveBolo()" v-if="(doesUserHavePermission('bolo_edit') || bolo.officer_id === unit.id) && editMode">
          Save
      </b-button>
      <b-button variant="danger" @click="deleteBolo()" v-if="doesUserHavePermission('bolo_delete')">
      Delete
      </b-button>
    </template>

  </b-modal>

</template>

<script>
import LeoRepository from "@/api/LeoRepository";

export default {
  name: "BoloDetails",
  props: {
    boloSelect: Object,
    modalId: {
      type: String,
      default: 'bolo-modal'
    }
  },
  data(){
    return {
      editMode: false,
      bolo: null,
      edit: {
        description: null,
        wanted_for: null,
        plate: null,
        last_seen: null,
      }
    }
  },
  computed: {
    unit() {
      return this.$store.state.auth.leoUnit;
    }
  },
  mounted() {
    this.bolo=this.boloSelect;
  },
  methods: {
    startEdit() {
      this.editMode=true;
      this.edit=this.bolo;
    },
    saveBolo(){
      LeoRepository.updateBolo(this.bolo.id, this.edit).then(response => {
        this.bolo=response.data.data;
        this.editMode=false;
      });
    },
    deleteBolo(){
      LeoRepository.deleteBolo(this.bolo.id).then(response => {
        this.bolo=null;
        this.$root.$emit('bv::hide::modal', 'bolo-modal')
      }).catch(error => {

      });
    }
  },
  watch: {
    boloSelect: function(newBolo){
      this.bolo=this.boloSelect;
    },
    bolo: function(oldBolo,newBolo){
      this.editMode=false;
    }
  }
}
</script>

<style scoped>
.description, .wanted_for {
  white-space: break-spaces;
}
</style>