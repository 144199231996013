<template>
  <b-modal id="after-action-modal" size="xl" no-close-on-backdrop :title="modalTitle" ref="afterAction" hide-footer>

    <b-tabs content-class="mt-3" justified v-model="tabIndex">
      <b-tab title="Details">
        <AfterActionDetails v-bind:CKEditor="CKEditor" v-bind:editorConfig="editorConfig" @createdAfterAction="afterActionCreated" :after-action="afterAction" @deleteAfterAction="afterActionDeleted"></AfterActionDetails>
      </b-tab>
      <b-tab title="Evidence" :disabled="afterAction===null">
        <EvidenceForm v-if="afterAction!==null" v-bind:after-action-id="afterAction.id" v-bind:after-action="afterAction"></EvidenceForm>
      </b-tab>
      <b-tab title="Statements" :disabled="afterAction===null">
        <StatementsForm v-if="afterAction!==null" :after-action="afterAction"></StatementsForm>
      </b-tab>
      <b-tab title="Related Documentation" :disabled="afterAction===null">
        <RelatedDocumentation v-if="afterAction!==null" :after-action="afterAction"></RelatedDocumentation>
      </b-tab>
    </b-tabs>

  </b-modal>
</template>

<script>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CaseDetails from "@/components/Partials/Detectives/FormCaseModal/CaseDetails";
import EvidenceForm from "@/components/Partials/Detectives/FormCaseModal/EvidenceForm";
import StatementsForm from "./FormCaseModal/StatementsForm";
import DocumentForm from "./FormCaseModal/DocumentForm";
import DetectiveRepository from "@/api/DetectiveRepository";
import RelatedDocumentation from "@/components/Partials/Detectives/FormCaseModal/RelatedDocumentation";
import AfterActionDetails from "@/components/Partials/Detectives/AfterActionModal/AfterActionDetails";

export default {
  name: "NewCaseModal",
  components: {AfterActionDetails, RelatedDocumentation, DocumentForm, StatementsForm, EvidenceForm, CaseDetails},
  props: ['afterActionId'],
  data() {
    return {
      afterAction:null,
      tabIndex:0,
      CKEditor: ClassicEditor,
      editorConfig: {
        toolbar: {
          items: [
            'heading',
            '|',
            'alignment',
            'bold',
            'italic',
            'link',
            'bulletedList',
            'numberedList',
            'indent',
            'blockQuote',
            'undo',
            'redo'
          ]
        }
      }
    }
  },
  computed: {
    modalTitle() {
      if(this.afterAction){
        return "After Action Report #"+this.afterAction.id+" - " + this.afterAction.title;
      } else {
        return "Create new after action report";
      }
    }
  },
  methods: {
    newCase() {
      console.log("new caseee");
      this.afterAction=null;
      this.$refs.afterAction.show()
    },
    afterActionCreated(cf) {
      this.afterAction=cf.data;
      setTimeout(() => {
        this.tabIndex=this.tabIndex+1; }, 100);
    },
    afterActionDeleted(){
      this.$refs.afterAction.hide();
    },
    show(id){
      DetectiveRepository.getAfterActionById(id).then(response => {
        this.afterAction=response.data.data;
        this.$refs.afterAction.show();
      });
    }
  },
  mounted() {
    if(this.afterActionId){
      DetectiveRepository.getAfterActionById(this.afterActionId).then(response => {
        this.afterAction=response.data.data;
        this.$refs.afterAction.show();
      });
    }
  },
}
</script>

<style>
  .nav-link {
    color:#FFF!important;
  }

  .nav-link.disabled {
    color:#6c757d!important;
  }

  .nav-link.active {
    color:#000!important;
  }
</style>
