<template>
  <div>
    <b-modal @show="()=>{this.errors=null}" no-close-on-backdrop id="veredict-report-form-modal" size="lg" title="Create Conviction Record" @ok="createVeredictRecord">


      <b-form-row>
        <b-col cols="6">
          <b-form-group
              label="Plaintiff"
              label-for="plaintiff"
          >
            <b-form-input
                id="plaintiff"
                v-model="form.plaintiff_name"
                type="text"
                placeholder="Plaintiff Name"
                autocomplete="off"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
              label="Defendant"
              label-for="civilian"
          >
            <b-form-input
                id="civilian"
                v-model="civilian.name"
                type="text"
                placeholder="Civilian Name"
                autocomplete="off"
                disabled
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-form-row>

      <b-form-row>

        <b-col cols="8">
          <b-form-group
              label="Court"
              label-for="court"
          >
            <b-form-input
                id="court"
                v-model="form.court_name"
                type="text"
                placeholder="Court Name (i.e.: Superior Court)"
                autocomplete="off"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group
              label="Case ID"
              label-for="case_id"
          >

          <b-input-group>
            <b-form-input
                id="case_id"
                v-model="form.arrest_report_id"
                type="text"
                placeholder="Arrest Report ID"
                autocomplete="off"
            ></b-form-input>
            <b-input-group-append>
              <b-button @click="lookupCase" variant="dark">Fill Charges</b-button>
            </b-input-group-append>
          </b-input-group>
            

            
          </b-form-group>
        </b-col>
      </b-form-row>


      <b-form-group
          label="Verdict"
          label-for="veredict"
      >
        <b-form-textarea
            id="veredict"
            v-model="form.veredict"
            type="text"
            required
            rows="4"
        ></b-form-textarea>
      </b-form-group>

      <b-form-group
          label="Sentence"
          label-for="sentence"
      >
        <b-form-textarea
            id="sentence"
            v-model="form.sentence"
            type="text"
            required
            rows="4"
        ></b-form-textarea>
      </b-form-group>

      <b-form-group
          label="Contempts"
          label-for="contempts"
      >
        <b-form-textarea
            id="contempts"
            v-model="form.contempts"
            type="text"
            required
            rows="4"
        ></b-form-textarea>
      </b-form-group>

      <ErrorDisplay :errors="errors"></ErrorDisplay>
    </b-modal>
  </div>

</template>

<script>
import DojRepository from "@/api/DojRepository";
import LeoRepository from "@/api/LeoRepository";
import ErrorDisplay from "@/components/Partials/ErrorDisplay";

export default {
  name: "VeredictRecord",
  components: {ErrorDisplay},
  props: ['civilian'],
  data: function () {
    return {
      form: {
        court_name:null,
        plaintiff_name:"State of San Andreas",
        defendant_name:this.$props.civilian.name,
        defendant_id:this.$props.civilian.id,
        veredict: null,
        sentence: null,
        contempts: null,
        judge_id:null,
        arrest_report_id:null,
      },
      errors: null,
      submited:false
    }
  },
  computed: {
    unit() {
      return this.$store.state.auth.leoUnit;
    }
  },
  methods: {
    createVeredictRecord(evt){
      evt.preventDefault();
      if(this.submited) return false;
      this.submited=true;

      let data=this.form;
      data.judge_id=this.unit.id;

      DojRepository.createVeredictReport(data).then(r => {
        this.submited=false;
        this.$root.$emit('bv::hide::modal', 'veredict-report-form-modal');
      }).catch(error => {
        this.submited=false;
        this.errors=error.response.data.errors;
      });
    },
    lookupCase(evt){
       evt.preventDefault();
       this.errors=null;
       if(this.form.arrest_report_id === null) return false;
       var verdictField = '';
       LeoRepository.getArrestReport(this.form.arrest_report_id).then(r => {
         r.data.data.charges.forEach(e => {
           verdictField += "VERDICT - " + e.code + " " + e.title + ", " + e.pivot.type.charAt(0).toUpperCase() + e.pivot.type.slice(1) + "\n";
         });
         this.form.veredict = verdictField;
       }).catch(error => {
        this.submited=false;
        this.errors=error.response.data.errors;
      });
    }
  }
}
</script>

<style scoped>

</style>