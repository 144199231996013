<template>
  <b-modal id="global-search-modal" size="xl" hide-footer title-html="<b>S</b>an <b>A</b>ndreas <b>R</b>egional <b>I</b>nformation <b>S</b>ystem">

    <b-form-row>
      <div class="col-6">
        <b-form-input type="text" v-model="form.search" placeholder="Search..." ></b-form-input>
      </div>
      <div class="col-3">
        <v-select v-model="form.model" :options="modelsOptions" :reduce="i => i.code">
        </v-select>
      </div>
      <div class="col-3">
        <v-select v-model="form.field" :options="models[form.model].fields" :reduce="i => i.code"></v-select>
      </div>

    </b-form-row>


    <b-form-row>
      <div class="col-6">
        <h6 class="mt-2">Flags</h6>

        <select v-model="form.flags" class="form-control text-capitalize" multiple  v-if="flags.hasOwnProperty(form.model)">
          <option :value="f.flag_name" v-for="f in flags[form.model]">{{ f.flag_formatted_name }}</option>
        </select>

        <p class="text-warning" v-if="!flags.hasOwnProperty(form.model)">No flags to select from.</p>

      </div>
      <div class="col-6">
        <h6 class="mt-2">Extras</h6>

        <select v-model="form.extra" class="form-control text-capitalize" multiple  v-if="models[form.model].hasOwnProperty('extras')">
          <option :value="f" v-for="f in models[form.model].extras">{{ f }}</option>
        </select>

        <p class="text-warning" v-if="!models[form.model].hasOwnProperty('extras')">No extras to select from.</p>
      </div>
    </b-form-row>

    <b-form-row>
      <div class="col-4 offset-4 text-center mt-4">
        <b-button variant="primary" block @click="search" v-if="!searching" >Search</b-button>
        <b-button variant="primary" block v-if="searching" ><b-spinner small></b-spinner> Searching...</b-button>
      </div>
    </b-form-row>

    <b-alert variant="danger" dismissible v-model="error" class="mt-2">
      Something went wrong... Please try again later or contact tech support!
    </b-alert>

    <hr>
    <h5>Search results</h5>
    <hr>

    <LookupTable :data="this.searchRet" :header-items="tables[form.model]" v-if="searchRet!==null"></LookupTable>

  </b-modal>
</template>

<script>
import FlagsRepository from "@/api/FlagsRepository";
import LookupTable from "@/components/Partials/LEO/Lookup/LookupTable";

export default {
  name: "GlobalSearchModal",
  components: {LookupTable},
  data(){
    return {
      form: {
        field: null,
        search: null,
        model: 'Civilian',
        flags:[],
        extra:[]
      },
      models:{
        'Civilian':{
          title:'Citizens',
          fields:[
            {code:'name',label:'Name'},
            {code:'dob',label:'DOB'},
            {code:'hair_color',label:'Hair Color'},
            {code:'eye_color',label:'Eye Color'},
            {code:'address',label:'Address'},
            {code:'postal',label:'Postal'},
            {code:'phone_number',label:'Phone Number'},
            {code:'height',label:'Height'},
            {code:'weight',label:'Weight'},
            {code:'occupation',label:'Occupation'}
          ],
          extras:[
              'arrests','priors','firearms','licenses','vehicle','warrants'
          ]
        },
        'CivilianVehicle':{
          title:'Vehicles',
          fields:[
            {code:'plate',label:'License Plate'},
            {code:'color',label:'Color'},
            {code:'make',label:'Make'},
            {code:'model',label:'Model'},
            {code:'year',label:'Year'}
          ]
        },
        'CivilianWeapon':{
          title:'Weapons',
          fields:[
            {code:'weapon_model',label:'Model'},
            {code:'weapon_type',label:'Type'},
            {code:'weapon_serial_number',label:'Serial Number'}
          ]
        }
      },
      flags:{},
      searchRet:null,
      searching:false,
      error:false,
      tables:{
        'Civilian':[
          {
            title:'Name',
            key:'name'
          },{
            title:'Age',
            key:'age'
          },{
            title:'Ethnicity',
            key:'ethnicity.name'
          },{
            title:"Gender",
            key:'gender.name'
          },{
            title:"Address",
            key:'address'
          },{
            title: '',
            key: 'flags'
          }
        ],
        'CivilianVehicle':[
          {
            title:'Owner',
            key:'owner.name'
          },{
            title:'Owner Age',
            key:'owner.age'
          },
          {
            title:'Plate',
            key:'plate'
          },
          {
            title:'Make',
            key:'make'
          },
          {
            title:'Model',
            key:'model'
          },
          {
            title:'Color',
            key:'color'
          },
          {
            title:'Year',
            key:'year'
          },
          {
            title: 'Flags',
            key: 'flags'
          }
        ],
        'CivilianWeapon':[
          {
            title:'Owner',
            key:'owner.name'
          },{
            title:'Owner Age',
            key:'owner.age'
          },
          {
            title:'Model',
            key:'weapon_model'
          },
          {
            title:'Type',
            key:'weapon_type'
          }
        ]
      }
    }
  },
  computed: {
    modelsOptions(){
      let d=this.models;
      let f=[];
      for(let k in d){
        f.push({label:d[k].title,code:k});
      }

      return f;
    }
  },
  mounted() {
    FlagsRepository.getAllFlags().then(r => {
      this.flags=r.data.data;
    });
  },
  methods: {
    search(){
      this.searching=true;
      this.error=false;
      this.searchRet=null;
      let data=JSON.parse(JSON.stringify(this.form));
      if(data.flags.length <= 0){ delete data.flags; }
      FlagsRepository.globalSearch(data).then(response => {
        console.log(response.data);
        this.searchRet=response.data;
        this.searching=false;
      }).catch(err => {
        this.error=true;
        this.searching=false;
      });
    },
    resetFlagsandExtras(){
      this.searchRet=null;
      this.form.extra=[];
      this.form.flags=[];
      this.form.field=null;
    }
  },
  watch: {
    'form.model': function(){
      this.resetFlagsandExtras();
    }
  }
}
</script>

<style scoped>

</style>