<template>
  <div>
    <b-button class="btn btn-block" variant="info" v-b-tooltip.hover title="You can create a new character in-game!">Create New Citizen</b-button>



<!--    <b-modal id="arrest-report-form-modal" size="lg" title="Citizen Registration" ref="newCitizenModal" ok-title="Save" hide-footer @show="()=>{this.errors=null}" >
&lt;!&ndash;             @ok="submitForm"&ndash;&gt;

      <p>You can create a new character automatically by going ingame and creating one!</p>
      &lt;!&ndash;      <form ref="citizenForm">
        <b-form-row>
          <b-col>
            <b-form-group
                label="Image"
                label-for="image"
            >
              <b-form-file
                  v-model="form.picture"
                  :state="Boolean(form.picture)"
                  placeholder="DMV Photo"
                  drop-placeholder="Drop photo here..."
                  accept="image/*"
                  @change="onImageUpload"
                  ref="picture"

              ></b-form-file>

            </b-form-group>

            <b-form-group
                label="Full name"
                label-for="name"
            >
              <b-form-input
                  id="name"
                  v-model="form.name"
                  type="text"
                  autocomplete="off"
                  placeholder="Jacob McGreeves"
                  required
              ></b-form-input>
            </b-form-group>
            <b-form-group
                label="Date of Birth"
                label-for="dob"
            >
              <b-form-input
                  id="dob"
                  v-model="form.dob"
                  type="date"
                  autocomplete="off"
                  placeholder="DOB"
                  required
              ></b-form-input>
            </b-form-group>

          </b-col>
          <b-col class="text-right">
            <img :src="imgurl" class="img-fluid" v-if="imgurl" style="max-height: 300px;">
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group
                label="Hair Color"
                label-for="hair_color"
            >
              <b-form-input
                  id="hair_color"
                  v-model="form.hair_color"
                  type="text"
                  autocomplete="off"
                  placeholder="Color"
                  required
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
                label="Eye Color"
                label-for="eye_color"
            >
              <b-form-input
                  id="eye_color"
                  v-model="form.eye_color"
                  type="text"
                  autocomplete="off"
                  placeholder="Color"
                  required
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group
                label="Address"
                label-for="address"
            >
              <b-form-input
                  id="address"
                  v-model="form.address"
                  type="text"
                  autocomplete="off"
                  placeholder=""
                  required
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
                label="Postal"
                label-for="postal"
            >
              <b-form-input
                  id="postal"
                  v-model="form.postal"
                  type="text"
                  autocomplete="off"
                  placeholder="7026"
                  required
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group
                label="Gender"
                label-for="gender"
            >
              <v-select name="type" v-if="genders!==null" :options="genders" label="name" v-model="selectedGender"></v-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
                label="Ethnicity"
                label-for="ethnicity"
            >
              <v-select name="type" v-if="ethnicities!==null" :options="ethnicities" label="name" v-model="selectedEthnicity"></v-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
                label="Occupation"
                label-for="occupation"
            >
              <b-form-input
                  id="occupation"
                  v-model="form.occupation"
                  type="text"
                  autocomplete="off"
                  placeholder="Job"
                  required
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group
                label="Phone Number"
                label-for="number"
            >
              <b-form-input
                  id="number"
                  v-model="form.phone_number"
                  type="text"
                  autocomplete="off"
                  placeholder=""
                  required
                  @input="acceptPhoneNumber"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
                label="Height"
                label-for="height"
            >
              <b-form-input
                  id="height"
                  v-model="form.height"
                  type="text"
                  autocomplete="off"
                  placeholder=""
                  required
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
                label="Weight"
                label-for="weight"
            >
              <b-form-input
                  id="weight"
                  v-model="form.weight"
                  type="text"
                  autocomplete="off"
                  placeholder=""
                  required
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group
                label="Medical Record"
                label-for="medical"
            >
              <b-form-textarea
                  id="medical"
                  v-model="form.medical_record"
                  type="text"
                  placeholder=
                      "1. Arthritis
2. Blood Thinners"

                  required
              ></b-form-textarea>
            </b-form-group>
          </b-col>

        </b-form-row>
      </form>

      <ErrorDisplay :errors="errors"></ErrorDisplay>&ndash;&gt;
    </b-modal>-->
  </div>
</template>

<script>
import CitizenRepository from "@/api/CitizenRepository";
import DataRepository from "@/api/DataRepository";
import ErrorDisplay from "@/components/Partials/ErrorDisplay";

export default {
  name: "NewCitizen",
  components: {ErrorDisplay},
  data: function () {
    return {
      form: {
        name: null,
        gender_id: null,
        ethnicity_id: null,
        dob: null,
        hair_color: '',
        eye_color: '',
        address: '',
        postal: null,
        phone_number: '',
        height: null,
        weight: null,
        medical_record: '',
        occupation: '',
      },
      errors: null,
      imgurl: null,
      genders: null,
      ethnicities: null,
      selectedGender:null,
      selectedEthnicity:null,
      civilian_id:null,
      civilian:null,
    }
  },
  created() {
    DataRepository.getGenders().then(response => {
      this.genders=response.data.data;
    });

    DataRepository.getEthnicities().then(response => {
      this.ethnicities=response.data.data;
    });
  },
  methods: {
    onImageUpload(e){
      const file=e.target.files[0];
      this.imgurl=URL.createObjectURL(file);
      this.picture=e.target.files[0];
    },

    openCivilianEdit(civilian){
      this.resetForm();
      this.form={
        name: civilian.name,
        gender_id: civilian.gender_id,
        ethnicity_id: civilian.ethnicity_id,
        dob: civilian.dob,
        hair_color: civilian.hair_color,
        eye_color: civilian.eye_color,
        address: civilian.address,
        postal: civilian.postal,
        phone_number: civilian.phone_number,
        height: civilian.height,
        weight: civilian.weight,
        medical_record: civilian.medical_record,
        occupation: civilian.occupation,
      };

      this.selectedGender=civilian.gender;
      this.selectedEthnicity=civilian.ethnicity;

      this.registering=false;

      this.civilian_id=civilian.id;
      this.civilian=civilian;

      this.$refs['newCitizenModal'].show();

      CitizenRepository.getPicture(civilian.id).then(response => {
        this.imgurl=response.data.data.url;
      });
    },

    resetForm(){
      this.form={
        name: null,
        gender_id: null,
        ethnicity_id: null,
        dob: null,
        hair_color: null,
        eye_color: null,
        address: null,
        postal: null,
        phone_number: null,
        height: null,
        weight: null,
        medical_record: null,
        occupation: null,
      };
      this.imgurl=null;
      this.civilian_id=null;
      this.civilian=null;
      this.selectedEthnicity=null;
      this.selectedGender=null;
    },

    submitForm(e){
      e.preventDefault();

      if(this.registering) return;

      this.registering=true;
      let formData=new FormData(this.$refs['citizenForm']);

      for(let k in this.form){
        formData.append(k,this.form[k]);
      }

      if(this.civilian_id===null){
        CitizenRepository.createCitizen(formData).then(response => {
          this.resetForm();
          this.$emit('update');
          this.registering=false;
          this.$root.$emit('bv::hide::modal', 'arrest-report-form-modal');
        }).catch(error => {
          this.errors=error.response.data.errors;
          this.registering=false;
        });
      } else {

        let f=this.form;

        if(typeof this.form.picture !== "undefined" && this.form.picture !== null){
          CitizenRepository.updateCitizenPicture(this.form.picture,this.civilian_id);
        }

        CitizenRepository.updateCitizen(f,this.civilian_id).then(response => {
          this.resetForm();
          this.$emit('update');
          this.$root.$emit('bv::hide::modal', 'arrest-report-form-modal');
          this.registering=false;
        }).catch(error => {
          this.errors=error.response.data.errors;
          this.registering=false;
        });
      }


    },

    acceptPhoneNumber(){
      let x = this.form.phone_number.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.form.phone_number = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    }
  },
  watch: {
    selectedGender: function(newVal){
      if(newVal!==null) this.form.gender_id=newVal.id;
    },

    selectedEthnicity: function(newVal){
      if(newVal!==null) this.form.ethnicity_id=newVal.id;
    }
  }
}
</script>

<style scoped>

</style>
