<template>
  <div>
    <b-button class="btn btn-block" variant="primary" v-b-modal.license-form-modal @click="clearData">Register New License</b-button>

    <b-modal no-close-on-backdrop id="license-form-modal" size="md" title="Create New License" ok-title="Save" @ok="createLicense" @show="()=>{this.errors=null}" ref="formModal">

      <b-form-group label="Type" label-for="type">
        <v-select name="type" v-if="types!==null" :options="types" label="name" v-model="selectedType"></v-select>
      </b-form-group>
      <b-form-group label="Status" label-for="status">
        <v-select name="type" :options="statuses" v-model="form.status"></v-select>
      </b-form-group>

      <ErrorDisplay :errors="errors"></ErrorDisplay>
    </b-modal>
  </div>
</template>

<script>
import DataRepository from "@/api/DataRepository";
import CitizenRepository from "@/api/CitizenRepository";
import ErrorDisplay from "@/components/Partials/ErrorDisplay";

export default {
  name: "NewLicense",
  components: {ErrorDisplay},
  props: ['civilian'],
  data: function () {
    return {
      form: {
        civilian_id: this.civilian.id,
        license_id: null,
        status: null,
      },
      selectedType:null,
      types:null,
      statuses:[],
      errors: null
    }
  },
  created() {
    DataRepository.getLicenseTypes().then(response => {
      this.types=response.data.data;
    });
  },
  watch:{
    selectedType: function(newVal){
      this.form.license_id=newVal.id;
      let aux = newVal.status_types.split(',');

      let final=[];

      for(let k in aux){
        final.push(aux[k].trim().replace(/^\w/, (c) => c.toUpperCase()));
      }

      this.statuses=final;
    }
  },
  methods:{
    createLicense: function(e){
      e.preventDefault();
      CitizenRepository.createCitizenLicense(this.form).then(response => {
        this.$refs['formModal'].hide();
        this.$emit('updateCivilianData');
      }).catch(error => {
        this.errors=error.response.data.errors;
      });
    },

    openToUpdate(license){
      this.form.license_id=license.license.id;
      this.selectedType=license.license;
      this.form.status=license.status;
      this.$refs['formModal'].show();
    },

    clearData(){
      this.form.license_id=null;
      this.form.status=null;
      this.selectedType=null;
    }
  }
}
</script>

<style scoped>

</style>