/*
* Auth API
*/

import Repository from "./Repository";

class AuthService {

    login(user){
        return Repository.post('login', {
            email: user.email,
            password: user.password
        })
            .then(response => {
                if(response.data.access_token){
                    localStorage.setItem('userinfo', JSON.stringify(response.data));
                }

                return response.data;
            });
    };

    updatePermissions(){
        return Repository.get('me')
            .then(response => {
                let currentData = localStorage.getItem('userinfo')
                let json = JSON.parse(currentData)
                json.permissions = response.data
                localStorage.setItem('userinfo', JSON.stringify(json));
                location.reload();
            })
    }

}

export default new AuthService();
