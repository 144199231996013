<template>
  <b-container fluid class="detective-container">
    <ValidateUnit v-if="unit===null"></ValidateUnit>
    <div class="police-icons-container">
      <div class="icon" v-b-modal.lookup-modal>
        <img src="/images/computer/icons/lookup.png">
      </div>
      <div class="icon" v-b-modal.notepad>
        <img src="/images/computer/icons/notepad.png">
      </div>
      <div class="icon" v-b-modal.information-panel>
        <img src="/images/computer/icons/information.png">
      </div>
      <div class="icon" v-b-modal.files-modal>
        <img src="/images/computer/icons/files.png">
      </div>
      <div class="icon" v-b-modal.global-search-modal>
        <img src="/images/computer/icons/saris.png">
      </div>
    </div>
    <div class="police-computer-logo-container" v-if="unit!==null">
      <img draggable="false" :src="unit.agency.image_json.url" class="policeComputer-logo">
    </div>
    <InformationPanel></InformationPanel>
    <Notepad :block="false" type="Detectives"></Notepad>
    <NewCaseModal ref="newCaseModal"></NewCaseModal>
    <Lookup></Lookup>
    <GlobalSearchModal></GlobalSearchModal>
    <AfterActionModal ref="afterActionModal"></AfterActionModal>
    <FilesModal></FilesModal>
  </b-container>
</template>

<script>
import PinnedCasesTable from "../Partials/Detectives/PinnedCasesTable";
import InformationPanel from "../Partials/Dispatch/InformationPanel";
import Notepad from "../Partials/Notepad";
import Lookup from "../Partials/LEO/Lookup/Lookup";
import GlobalSearchModal from "../Partials/DOJ/GlobalSearchModal";
import NewCaseModal from "../Partials/Detectives/NewCaseModal";
import ValidateUnit from "@/components/Partials/ValidateUnit";
import AfterActionModal from "../Partials/Detectives/AfterActionModal";
import AfterActionReportTable from "@/components/Partials/Detectives/AfterActionReportTable";
import FilesModal from "@/components/Partials/Detectives/FilesModal";
export default {
  name: "Detectives",
  components: {
    FilesModal,
    AfterActionReportTable,
    AfterActionModal,
    ValidateUnit, NewCaseModal, GlobalSearchModal, Lookup, Notepad, InformationPanel, PinnedCasesTable},
  computed: {
    unit() {
      return this.$store.state.auth.leoUnit;
    }
  },
  methods: {
    newCase(){
      this.$refs['newCaseModal'].newCase();
    },
    newAfterAction(){
      this.$refs['afterActionModal'].newCase();
    }
  }
}
</script>

<style scoped>
.detective-container {
  background-image: url('/images/pvbg.jpg');
  background-size: cover;
  background-position: center center;
  padding-left:0;
}

.police-computer-logo-container {
  width: 100%;
  height: 91.8vh;
  position: absolute;
  z-index: 1;
  background-color: rgba(0,0,0,0.8);
  text-align: center;
}

.policeComputer-logo {
  height: 35vh;
  margin-top: 16.25vh;
  opacity: 0.2;
}

.police-icons-container {
  width: 100%;
  padding: 25px;
  z-index: 999;
  position: absolute;
  display: inline-block;
}

.icon {
  display: inline-block;
  height: 120px;
  padding: 5px;
  cursor: pointer;
}

.icon:hover {
  background-color: rgba(255,255,255,0.3);
}

.icon > img {
  height: 100px;
}
</style>
