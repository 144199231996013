<template>
  <div>
    <div class="table-responsive table-wrapper-scroll-y my-custom-scrollbar">
      <table class="table table-striped table-dark table-sm table-hover table-bg-darker">
        <thead class="thead-light">
        <tr>
          <th scope="col">ID</th>
          <th scope="col">10 Code</th>
          <th scope="col">Title</th>
          <th scope="col">Address</th>
          <th scope="col">Units</th>
          <th scope="col"></th>
        </tr>
        </thead>
        <tbody>
        <tr v-if="loading">
          <td colspan="6" class="text-center">
            <b-spinner variant="primary" label="Loading..."></b-spinner>
          </td>
        </tr>
        <tr v-for="(call,index) in calls" v-bind:key="call.id" @click="selectCallToDispatch(index)" @drop="dropAttach($event, call)" @dragover.prevent @dragenter.prevent>
          <td>{{ call.id }}</td>
          <td>{{ formatCode(call.code.code) }}</td>
          <td>{{ call.title }}</td>
          <td>{{ call.address }} <template v-if="call.postal!==null">({{call.postal}})</template></td>
          <td>
            <b-badge :class="'mr-1 bg-status-' + statuses.find(o => {return o.id === unit.current_status_id}).code" v-for="(unit, unitindex) in call.units" @click.stop="toggleUnitDropdown($event,unitindex,call)" v-bind:key="unit.id" >{{ unit.identifier }}</b-badge>
          </td>
          <td @click.stop="toggleCallDropdown($event,index)" style="cursor: pointer; text-align: center;">
            <b-button-group>
            <b-button size="sm" variant="primary" @click.stop="openEditorCall(call)">Edit</b-button>
            <b-button size="sm" variant="danger" @click.stop="closeCall(call)">Close Call</b-button>
            </b-button-group>
          </td>
          <div>
              <ContextMenu ref="unitContext">
                <b-dropdown-text v-if="selectedUnit">Set Status for {{selectedUnit.identifier}}</b-dropdown-text>
                <b-dropdown-item href="#" v-for="status in statuses" v-if="statuses!==null && selectedUnit" :key="status.id" @click.stop="updateUnitStatus(selectedUnit,status)" ><span :style="{color: status.color}">{{ status.code }}</span></b-dropdown-item>
                <hr>
                <b-dropdown-item v-if="selectedUnit" href="#" @click.stop="detachFromCurrentCall(selectedUnit)">Detach from call</b-dropdown-item>
              </ContextMenu>
            </div>  
        </tr>
        </tbody>
      </table>
      <ManageCall ref="editCall"></ManageCall>
    </div>


    <b-form-row v-if="call_dispatched!==null">
      <h6>Notes for Call {{calls[call_dispatched].id}} - {{calls[call_dispatched].title}} @ {{ calls[call_dispatched].address }} <span v-if="calls[call_dispatched].postal!==null">({{calls[call_dispatched].postal}})</span></h6>
      <b-col cols="12">
        <b-textarea v-model="calls[call_dispatched].notes" placeholder="No Notes Added..." rows="5" v-html="true" class="notes-field" ></b-textarea>
      </b-col>
    </b-form-row>

    <b-form-row v-if="call_dispatched!==null">
      <b-col cols="8">
        <b-form-input type="text" v-model="note" @keyup.enter.native="addNote" autocomplete="off" class="notes-field"></b-form-input>
      </b-col>
      <b-col cols="2">
        <b-button variant="success" class="form-control" @click="addNote" autocomplete="off">Add Note</b-button>
      </b-col>
      <b-col cols="2">
        <b-button variant="danger" class="form-control" @click="closeNotes" autocomplete="off">Hide Notes</b-button>
      </b-col>
    </b-form-row>
  </div>

</template>

<script>
import DataRepository from "@/api/DataRepository";
import DispatchRepository from "@/api/DispatchRepository";
import LeoRepository from "@/api/LeoRepository";
import ManageCall from "@/components/Partials/Dispatch/ManageCall";
import ContextMenu from "@/components/Partials/Dispatch/ContextMenu";

export default {
  name: "DispatchCallList",
  components: {ContextMenu, ManageCall},
  data(){
    return {
      calls:null,
      origins:{
        'caller':'Caller',
        'radio':'Radio'
      },
      call_dispatched: null,
      note:null,
      statuses:null,
      selectedUnit:null,
      loading: true,
    }
  },
  mounted() {
    this.updateDispatchCalls();
  },
  created() {
    DataRepository.getUnitStatus().then((response) => {
      this.statuses = response.data.data;
      this.loading=false;
    });
  },
  methods:{
    updateDispatchCalls(){
      DispatchRepository.getDispatchCalls().then(response => {
        this.calls=response.data.data;
      });
    },

    formatCode(code){
      code=code.split(":");
      return code[0].trim();
    },

    openEditorCall(call){
      this.$refs['editCall'].openCallEdit(call);
    },

    toggleCallDropdown(event,index){

      const dropdown=this.$refs['callContext'].find(
          el => el.$attrs['data-index'] === index
      );


      this.$refs['callContext'].forEach(e=>{
        if(e.$attrs['data-index'] !== index) e.close();
      });

      dropdown.toggle(event);
    },

    toggleUnitDropdown(event,index,call){

      this.selectedUnit = call.units[index];

      const dropdown=this.$refs['unitContext'][0];

      dropdown.toggle(event);
    },

    updateUnitStatus(unit, status) {
      let dropdown=this.$refs['unitContext'][0];
      dropdown.toggle(null);
      LeoRepository.updateUnitStatus(unit.id, status).then(r => {
        this.$emit('updateUnitList');
        this.updateDispatchCalls();
        this.selectedUnit = null;
      });
    },

    detachFromCurrentCall(unit) {
      let dropdown=this.$refs['unitContext'][0];
      dropdown.toggle(null);
      DispatchRepository.changeUnitsCall("detach", unit.call_id, [unit.id]).then(r => {
        this.$emit('updateUnitList');
        this.updateDispatchCalls();
        this.selectedUnit = null;
      });
    },

    dropAttach(evt,call){
      const unit=evt.dataTransfer.getData('unit_id');

      if(unit !== null){
        DispatchRepository.changeUnitsCall('attach',call.id,[unit]);
      }
    },

    closeCall(call){
      let data=call;
      data.call_status='closed';
      DispatchRepository.updateDispatchCall(data,call.id);
    },

    addNote(){
      let fnote='';
      if(this.calls[this.call_dispatched].notes !== null){
        fnote=this.calls[this.call_dispatched].notes;
      }

      let note=this.moment().format('Y-MM-DD HH:mm:ss') + " | " + this.note + "\n";
      this.calls[this.call_dispatched].notes=note+fnote;
      DispatchRepository.updateDispatchCall(this.calls[this.call_dispatched], this.calls[this.call_dispatched].id);
      this.note=null;
    },

    selectCallToDispatch(index){
      if (this.call_dispatched === index){
        this.call_dispatched=null;
        return;
      }
      this.call_dispatched=index;
      this.note=null;
    },

    closeNotes(){
      this.call_dispatched=null;
    },
  },
  sockets: {
    incomingDispatchCall: function(){
      this.updateDispatchCalls();
    },
    updateDispatchCall: function(){
      this.updateDispatchCalls();
    }
  }
}
</script>

<style scoped>

.table-bg-darker {
  background-color: #212529;
}

.notes-field {
  font-family: 'Courier New', Courier, monospace;
  text-transform: uppercase;
}

</style>