<template>
    <b-modal @show="()=>{this.errors=null}" no-close-on-backdrop id="createBOLO-form-modal" size="lg" title="Create BOLO" hide-footer @close="closeModal">

      <b-form-group
          label="Civilian"
          label-for="civilian"
          v-if="civilian !==null && typeof civilian !== 'undefined'"
      >
        <b-form-input
            id="civilian"
            v-model="civilian.name"
            type="text"
            placeholder="Civilian Name"
            disabled
        ></b-form-input>
      </b-form-group>

      <b-form-group
          label="Description"
          label-for="desc"
      >
        <b-form-textarea
            id="desc"
            v-model="form.description"
            type="text"
            required
            rows="4"
            :state="textLength(form.description)"
        ></b-form-textarea>
      </b-form-group>


      <b-form-group
          label="Wanted For"
          label-for="wanted_for"
      >
        <b-form-textarea
            id="wanted_for"
            v-model="form.wanted_for"
            type="text"
            required
            rows="4"
            :state="textLength(form.wanted_for)"
        ></b-form-textarea>
      </b-form-group>

      <b-form-group label="Plate" label-for="plate">
        <b-form-input type="text" v-model="form.plate"></b-form-input>
      </b-form-group>

      <b-form-group label="Last Seen" label-for="last_seen">
        <b-form-input type="text" v-model="form.last_seen" autocomplete="off"></b-form-input>
      </b-form-group>

      <b-form-row>
        <b-col>
          <b-button type="submit" variant="primary" v-on:click="handleSubmit()">Create</b-button>
        </b-col>
      </b-form-row>

      <ErrorDisplay :errors="errors"></ErrorDisplay>

    </b-modal>
</template>

<script>
import LeoRepository from "@/api/LeoRepository";
import ErrorDisplay from "@/components/Partials/ErrorDisplay";

export default {
  name: "CreateBOLO",
  components: {ErrorDisplay},
  props:['civilian'],
  data: function () {
    return {
      form: {
        officer_id: null,
        civilian_id: null,
        description: null,
        wanted_for: null,
        plate: null,
        last_seen:null,
      },
      errors: null,
      submited: false
    }
  },
  computed:{
    unit() {
      return this.$store.state.auth.leoUnit;
    },
  },
  methods: {
    textLength(v) {
      if (v) {
        if (v.length >= 2048) {
          return false
        }
      }
      else {
        return null
      }
    },
    closeModal() {
      this.$root.$emit('bv::hide::modal', 'createBOLO-form-modal');
    },
    handleSubmit(){

      if(this.submited) return false;
      this.submited=true;

      if(this.form.officer_id === null){
        this.form.officer_id = this.unit.id;
      }

      LeoRepository.createBolo(this.form).then( response => {
        this.submited=false;
        this.$root.$emit('bv::hide::modal', 'createBOLO-form-modal');
      }).catch( error => {
        this.submited=false;
        this.errors=error.response.data.errors;
      });
    }
  },
  mounted() {
    if(this.$props.civilian !== null && typeof this.$props.civilian !== 'undefined'){
      this.form.civilian_id=this.$props.civilian.id;
    }
  }
}
</script>

<style scoped>

</style>