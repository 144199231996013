<template>
  <div class="container py-4">
    <div class="card">
      <div class="card-header">
        Account Information
      </div>
      <div class="card-body">
        <div class="container float-left">
            <div class="input-group mb-3 input-group-prepend">
              <div class="input-group-prepend">
                <input class="form-control inputPadding" autocomplete="off" id="username" type="text" :value="user" disabled />
              </div>
            </div>

          <p class="card-text" v-if="doesUserHavePermission('citizen_access')">Citizen Access: Yes</p>
          <p class="card-text" v-else>Citizen Access: None</p>

          <p class="card-text" v-if="doesUserHavePermission('leo_supervisor_access')">Police Access: Supervisor </p>
          <p class="card-text" v-else-if="doesUserHavePermission('leo_access')">Police Access: Officer </p>
          <p class="card-text" v-else>Police Access: None</p>

          <p class="card-text" v-if="doesUserHavePermission('fire_em_access')">EMS/FD Access: Yes</p>
          <p class="card-text" v-else>EMS/FD Access: None </p>

          <p class="card-text" v-if="doesUserHavePermission('dispatch_access')">Dispatch Access: Yes</p>
          <p class="card-text" v-else>Dispatch Access: None </p>

          <p class="card-text" v-if="doesUserHavePermission('doj_access')">Department Of Justice (DOJ) Access: Yes</p>
          <p class="card-text" v-else>Department Of Justice (DOJ) Access: None </p>

          <router-link class="text-white btn btn-primary" to="/citizen" v-if="doesUserHavePermission('citizen_access')">Civilian Dashboard</router-link>
          <router-link class="text-white btn btn-primary" to="/leo" v-if="doesUserHavePermission('leo_access')">LEO Dashboard</router-link>
          <router-link class="text-white btn btn-primary" to="/fd" v-if="doesUserHavePermission('fire_em_access')">EMS/FD Dashboard</router-link>
          <router-link class="text-white btn btn-primary" to="/dispatch" v-if="doesUserHavePermission('dispatch_access')">Dispatch Dashboard</router-link>
          <router-link class="text-white btn btn-primary" to="/doj" v-if="doesUserHavePermission('doj_access')">DOJ Dashboard</router-link>
          <b-btn v-on:click="refreshPermissions" variant="warning">Refresh Permissions</b-btn>

          <span class="float-right cad-version">v. {{$cadVersion}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuthService from "@/api/auth";

export default {
name: "Profile",
  data() {
    return {
      dark: 'false'
    }
  },

  computed: {
    user() {
      return this.$store.state.auth.user.name
    }
  },
  methods: {
    refreshPermissions(){
      AuthService.updatePermissions()
    }
  }
}
</script>

<style scoped>
.inputPadding {
  padding-right: 15px;
  padding-left: 15px;
}

.cad-version{
  color: #3e3e3e;
  position: absolute;
  text-align: right;
  bottom:0;
  right:5px;
}
</style>