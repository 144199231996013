<template>
  <div>
    <div class="d-flex justify-content-center">
      <b-button variant="primary" class="m-2" v-b-modal="'clockin-modal'">Clockin Menu</b-button>
    </div>
    <ClockinModal></ClockinModal>
  </div>
</template>

<script>
import ClockinModal from "../Partials/Supervisor/ClockinModal";
export default {
  name: "Supervisor",
  components: {ClockinModal}
}
</script>

<style scoped>

</style>
