<template>
  <b-modal id="clockin-modal" title="Clockin Menu" no-close-on-backdrop size="xl">
    <b-row>
      <b-col class="">
        <NameSelector @submit="onSubmit"></NameSelector>
      </b-col>
      <b-col class="border-left border-light">
        <h5>Clock-in history</h5>
        <ClockinTable :items="clockinData" v-if="clockinData"></ClockinTable>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import NameSelector from "./Clockin/NameSelector";
import ClockinTable from "./Clockin/ClockinTable";
export default {
  name: "ClockinModal",
  components: {ClockinTable, NameSelector},
  data(){
    return {
      clockinData: []
    }
  },
  methods: {
    onSubmit(data) {
      this.clockinData = data
    }
  }
}
</script>

<style scoped>
.colBorder {
  border-right: 1px white
}
</style>
