<template>
  <b-modal no-close-on-backdrop id="signal100-modal" title="Toggle signal 100" ref="signal100-modal">

    <b-form-row>
      <b-col>
        <b-form-group label="Description" label-for="description">
          <b-form-input type="text" v-model="form.text" placeholder="For what situation" autocomplete="off"></b-form-input>
        </b-form-group>
      </b-col>
    </b-form-row>

    <template #modal-footer="{ ok, cancel }">

      <div class="w-100">
        <div class="float-right">
          <b-button variant="success" @click="activateSignal100()">
            Toggle
          </b-button>
          <b-button variant="danger" @click="cancel()">
            Close
          </b-button>
        </div>

      </div>

    </template>

  </b-modal>

</template>

<script>
import DispatchRepository from "@/api/DispatchRepository";

export default {
  name: "Signal100Modal",
  data(){
    return {
      form: {
        text: null,
      }
    }
  },
  methods: {
    activateSignal100(){
      DispatchRepository.signal100update(this.form.text);
      this.$refs['signal100-modal'].hide();
    }
  }
}
</script>

<style scoped>

</style>