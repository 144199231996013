<template>
  <b-modal v-if="arrest_report || arrest_report_id" no-close-on-backdrop id="arrest-report-lookup-modal" size="lg" ref="arrestReportModal" :title="`Arrest Report`" @show="onShow">
    <div v-if="arrest_report!==null && !editing">
      <h6>Civilian Information</h6>
      <hr>

      <b-form-row>
        <b-col cols="4">
          <div class="fw-bold font-weight-bolder dd-title">Full Name</div>
          {{ arrest_report.civilian.name }}
        </b-col>

        <b-col cols="4">
          <div class="fw-bold font-weight-bolder dd-title">DOB</div>
          {{ arrest_report.civilian.dob }}
        </b-col>

        <b-col cols="4">
          <div class="fw-bold font-weight-bolder dd-title">Gender</div>
          {{ arrest_report.civilian.gender.name }}
        </b-col>
      </b-form-row>

      <b-form-row>
        <b-col cols="4">
          <div class="fw-bold font-weight-bolder dd-title">Address</div>
          {{ arrest_report.civilian.address }}
        </b-col>

        <b-col cols="4">
          <div class="fw-bold font-weight-bolder dd-title">Postal</div>
          {{ arrest_report.civilian.postal }}
        </b-col>

        <b-col cols="4">
          <div class="fw-bold font-weight-bolder dd-title">Hair Color</div>
          {{ arrest_report.civilian.hair_color }}
        </b-col>
      </b-form-row>
      <hr>

      <h6>Charges</h6>
      <hr>

      <template v-for="(charge,index) in arrest_report.charges">
        <b-form-row>
          <b-col cols="2" class="text-center">#{{ (index+1) }}</b-col>
          <b-col cols="10">
            <b-form-row>
              <b-col cols="6">
                <div class="fw-bold font-weight-bolder dd-title">Charge</div>
                {{ charge.title }}
              </b-col>

              <b-col cols="3">
                <div class="fw-bold font-weight-bolder dd-title">Type</div>
                {{ charge.pivot.type }}
              </b-col>

              <b-col cols="3">
                <div class="fw-bold font-weight-bolder dd-title">Counts</div>
                {{ charge.pivot.counts }}
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col cols="6">
                <div class="fw-bold font-weight-bolder dd-title">Code</div>
                {{ charge.code }}
              </b-col>
              <b-col cols="3">
                <div class="fw-bold font-weight-bolder dd-title">Fine Value</div>
                {{ charge.pivot.fine_value }}
              </b-col>
              <b-col cols="3">
                <div class="fw-bold font-weight-bolder dd-title">Jail Time</div>
                {{ charge.pivot.jail_time }}
              </b-col>
            </b-form-row>
          </b-col>
        </b-form-row>
        <hr>
      </template>


      <h6>Arrest Information</h6>
      <hr>

      <b-form-row>
        <b-col cols="12">
          <div class="fw-bold font-weight-bolder dd-title">Arrest Unique Identification Number</div>
          {{ arrest_report.id }}
        </b-col>
      </b-form-row>

      <b-form-row>
        <b-col cols="12">
          <div class="fw-bold font-weight-bolder dd-title">Arreste Inventory</div>
          <template v-if="arrest_report.contents!=null">{{ arrest_report.contents }}</template>
          <template v-if="arrest_report.contents===null">N/A</template>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col cols="12" class="narrative">
          <div class="fw-bold font-weight-bolder dd-title">Narrative</div>
          {{ arrest_report.narrative }}
        </b-col>
      </b-form-row>
      <hr>

      <h6>Arresting Officer</h6>
      <hr>

      <b-form-row>
        <b-col cols="4">
          <div class="fw-bold font-weight-bolder dd-title">Identifier</div>
          {{ arrest_report.arresting_officer.identifier }}
        </b-col>

        <b-col cols="4">
          <div class="fw-bold font-weight-bolder dd-title">Officer</div>
          {{ arrest_report.arresting_officer.rank }} {{ arrest_report.arresting_officer.name }}
        </b-col>

        <b-col cols="4">
          <div class="fw-bold font-weight-bolder dd-title">Agency</div>
          {{ arrest_report.arresting_officer.agency.short_name }}
        </b-col>

        <b-col cols="4">
          <div class="fw-bold font-weight-bolder dd-title">Date/Time</div>
          {{ arrest_report.date_time }}
        </b-col>
      </b-form-row>

      <ErrorDisplay :errors="errors"></ErrorDisplay>
    </div>

    <div v-if="arrest_report!==null && editing && editData!==null">
      <h6>Civilian Information</h6>
      <hr>

      <b-form-row>
        <b-col cols="4">
          <div class="fw-bold font-weight-bolder dd-title">Full Name</div>
          {{ arrest_report.civilian.name }}
        </b-col>

        <b-col cols="4">
          <div class="fw-bold font-weight-bolder dd-title">DOB</div>
          {{ arrest_report.civilian.dob }}
        </b-col>

        <b-col cols="4">
          <div class="fw-bold font-weight-bolder dd-title">Gender</div>
          {{ arrest_report.civilian.gender.name }}
        </b-col>
      </b-form-row>

      <b-form-row>
        <b-col cols="4">
          <div class="fw-bold font-weight-bolder dd-title">Address</div>
          {{ arrest_report.civilian.address }}
        </b-col>

        <b-col cols="4">
          <div class="fw-bold font-weight-bolder dd-title">Postal</div>
          {{ arrest_report.civilian.postal }}
        </b-col>

        <b-col cols="4">
          <div class="fw-bold font-weight-bolder dd-title">Hair Color</div>
          {{ arrest_report.civilian.hair_color }}
        </b-col>
      </b-form-row>
      <hr>

      <AddCharges @change="chargesChanged"></AddCharges>
      <template v-for="(charge,index) in editData.charges">
        <b-form-row>
          <b-col cols="2" class="text-center">
            <b-form-row>
              <b-col cols="12">#{{ (index+1) }}</b-col>
            </b-form-row>
            <b-form-row>
              <b-col cols="12">
                <b-button pill  size="sm" variant="outline-secondary" @click="removeCharges(index)">X</b-button>
              </b-col>
            </b-form-row>
          </b-col>
          <b-col cols="10">
            <b-form-row>
              <b-col cols="6">
                <div class="fw-bold font-weight-bolder dd-title">Charge</div>
                {{ charge.title }}
              </b-col>

              <b-col cols="3">
                <div class="fw-bold font-weight-bolder dd-title">Type</div>
                {{ charge.pivot.type }}
              </b-col>

              <b-col cols="3">
                <div class="fw-bold font-weight-bolder dd-title">Counts</div>
                {{ charge.pivot.counts }}
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col cols="6">
                <div class="fw-bold font-weight-bolder dd-title">Code</div>
                {{ charge.code }}
              </b-col>
              <b-col cols="3">
                <div class="fw-bold font-weight-bolder dd-title">Fine Value</div>
                {{ charge.pivot.fine_value }}
              </b-col>
              <b-col cols="3">
                <div class="fw-bold font-weight-bolder dd-title">Jail Time</div>
                {{ charge.pivot.jail_time }}
              </b-col>
            </b-form-row>
          </b-col>
        </b-form-row>
        <hr>
      </template>

      <h6>Arrest Information</h6>
      <hr>

      <b-form-row>
        <b-col cols="12">
          <div class="fw-bold font-weight-bolder dd-title">Arrest Unique Identification Number</div>
          {{ arrest_report.id }}
        </b-col>
      </b-form-row>

      <b-form-group
          label="Contents"
          label-for="contents"
      >
        <b-form-input
            id="contents"
            autocomplete="off"
            v-model="editData.contents"
            type="text"
            placeholder="Inventory"

        ></b-form-input>
      </b-form-group>

      <b-form-group
          label="Narrative"
          label-for="narrative"
      >
        <b-form-textarea
            id="narrative"
            v-model="editData.narrative"
            type="text"
            required
            rows="4"
        ></b-form-textarea>
      </b-form-group>
      <hr>

      <h6>Arresting Officer</h6>
      <hr>

      <b-form-row>
        <b-col cols="4">
          <div class="fw-bold font-weight-bolder dd-title">Identifier</div>
          {{ arrest_report.arresting_officer.identifier }}
        </b-col>

        <b-col cols="4">
          <div class="fw-bold font-weight-bolder dd-title">Officer</div>
          {{ arrest_report.arresting_officer.rank }} {{ arrest_report.arresting_officer.name }}
        </b-col>

        <b-col cols="4">
          <div class="fw-bold font-weight-bolder dd-title">Agency</div>
          {{ arrest_report.arresting_officer.agency.short_name }}
        </b-col>

        <b-col cols="4">
          <div class="fw-bold font-weight-bolder dd-title">Date/Time</div>
          {{ arrest_report.date_time }}
        </b-col>
      </b-form-row>

      <ErrorDisplay :errors="errors"></ErrorDisplay>
    </div>


    <template #modal-footer="">
      <b-button variant="danger" @click="deleteArrest()" v-if="doesUserHavePermission('arrest_report_delete')">
        Delete
      </b-button>
      <b-button variant="primary" @click="startEditArrest()" v-if="doesUserHavePermission('arrest_report_edit') && !editing">
        Edit
      </b-button>
      <b-button variant="primary" @click="editArrest()" v-if="doesUserHavePermission('arrest_report_edit') && editing">
        Save
      </b-button>
    </template>

  </b-modal>
</template>

<script>
import LeoRepository from "@/api/LeoRepository";
import ErrorDisplay from "@/components/Partials/ErrorDisplay";
import AddCharges from "@/components/Partials/LEO/Reports/AddCharges";

export default {
  name: "ArrestReportShow",
  components: {AddCharges, ErrorDisplay},
  props: ['arrest_report_id'],
  data(){
    return {
      arrest_report: null,
      editData:null,
      errors: null,
      editing:false,
      submited:false,
    }
  },
  mounted(){
    if(this.arrest_report_id) this.setArrestReportInfo();
  },
  methods: {
    openArrestById(id){
      LeoRepository.getArrestReport(id).then(response => {
        this.arrest_report=response.data.data;
        this.$nextTick(() => {
          this.$refs['arrestReportModal'].show();
        });
      });
    },
    setArrestReportInfo(){

      LeoRepository.getArrestReport(this.arrest_report_id).then(response => {
        this.arrest_report=response.data.data;
      });

    },

    deleteArrest(){
      LeoRepository.deleteArrestReport(this.arrest_report.id).then(r => {
        this.arrest_report=null;
        this.$root.$emit('bv::hide::modal', 'arrest-report-lookup-modal');
      }).catch(err => {

      });
    },

    startEditArrest(){
      this.editing=true;
      this.editData=JSON.parse(JSON.stringify(this.arrest_report));
    },

    chargesChanged(event){
      this.editData.addCharges=event;
    },

    removeCharges(index){
      this.editData.charges.splice(index,1);
    },

    editArrest(){

      if(this.submited) return false;
      this.submited=true;

      let sendData=JSON.parse(JSON.stringify(this.editData));

      for(let k in sendData.charges){
        let i=sendData.charges[k];
        sendData.charges[k]={
          penal_code_id: i.pivot.penal_code_id,
          counts: i.pivot.counts,
          jail_time: i.pivot.jail_time,
          fine_value: i.pivot.fine_value,
          type: i.pivot.type
        };
      }

      for(let k in sendData.addCharges){
        if(typeof sendData.addCharges[k] === 'undefined' || sendData.addCharges[k].penal_code_id == null){
          sendData.addCharges.splice(k,1);
        }
      }

      if(typeof sendData.addCharges !== "undefined" && sendData.addCharges.length >= 1) sendData.charges = sendData.charges.concat(sendData.addCharges);
      delete sendData.addCharges;

      LeoRepository.updateArrestReport(this.editData.id,sendData).then(r => {
        this.arrest_report=r.data.data;
        this.editData=null;
        this.editing=false;
        this.submited=false;
      }).catch(error => {
        this.submited=false;
        this.errors=error.response.data.errors;
      });
    },

    onShow(){
      this.editData=null;
      this.editing=false;
    }
  },
  watch:{
    arrest_report_id: function (newVal, oldVal){
      this.arrest_report=null;
      this.editData=null;
      this.editing=false;
      this.setArrestReportInfo();
    }
  }
}
</script>

<style scoped>
.narrative {
  white-space: break-spaces;
}
</style>