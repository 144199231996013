<template>
  <div>
    <div>
      <b-button variant="outline-success" @click="newCaseFile">+ Case File</b-button><br><br>
      <b-form-row>
        <b-col cols="3">
          <b-form-input v-model="search.title" placeholder="Title"></b-form-input>
        </b-col>
        <b-col cols="3">
          <b-form-input v-model="search.target" placeholder="Target"></b-form-input>
        </b-col>
        <b-col cols="3">
          <b-form-select v-model="search.status" :options="statusOptions"></b-form-select>
        </b-col>
        <b-col cols="2">
          <b-form-checkbox
              id="checkbox-1"
              v-model="search.all"
              name="checkbox-1"
              :value="true"
              :unchecked-value="null"
          >
            All records
          </b-form-checkbox>
        </b-col>
        <b-col cols="1">
          <b-button block variant="primary" @click="searchCases"><span v-if="!searching">🔎</span><b-spinner small v-if="searching"></b-spinner></b-button>
        </b-col>
      </b-form-row>
    </div><br>
    <b-table striped hover dark :items="items" :fields="fields" @row-clicked="openCaseFile">
    </b-table>
    <NewCaseModal :case-file-id="caseFileSelected" ref="caseModal"></NewCaseModal>
  </div>
</template>

<script>
import DetectiveRepository from "@/api/DetectiveRepository";
import NewCaseModal from "@/components/Partials/Detectives/NewCaseModal";

export default {
  name: "PinnedCasesTable",
  components: {NewCaseModal},
  data() {
    return {
      fields: [
        {
          key: 'id',
          label: 'Case ID',
          sortable: true,
        },
        {
          key: 'title',
          label: 'Title',
          sortable: false,
          formatter: 'checkLimit'
        },
        {
          key: 'brief_description',
          sortable: false,
          label: 'Description',
          formatter: 'checkLimit'
        },
        {
          key: 'target',
          sortable: true,
          label: 'Target',
          formatter: 'checkLimit'
        },
        {
          key: 'status',
          sortable: true,
          label: 'Status',
          formatter: 'statusFormatter'
        }
      ],
      items: [],
      loading: false,
      caseFileSelected: null,
      search: {
        title: null,
        all: null,
        status: "archived",
        target: null,
      },
      searching: false,
      statusOptions: [
        {value:'ongoing', text:'On Going'},
        {value:'intrial', text:'In Trial'},
        {value:'close', text:'Closed'},
        {value:'archived', text:'Archived'},
        {value:'cold', text:'Cold'},
      ]

    }
  },
  methods: {
    checkLimit(string){
      if (string.length > 100){
        return string.substring(1,100) + '...'
      } else {
        return string;
      }
    },
    statusFormatter(status){
      const statusTypes={
        'ongoing':'On Going',
        'intrial':'In Trial',
        'close':'Closed',
        'archived':'Archived',
        'cold':'Cold'
      };

      return statusTypes[status];
    },
    openCaseFile(record,index){
      this.caseFileSelected=record.id;
      if(this.$refs.hasOwnProperty('caseModal')) this.$refs['caseModal'].show(record.id);
    },
    searchCases(){
      if(this.searching) return;
      this.searching=true;
      DetectiveRepository.getCaseFiles(this.search).then(response => {
        this.items=response.data.data;
        this.searching=false;
      });
    },
    newCaseFile(){
      this.caseFileSelected=null;
      if(this.$refs.hasOwnProperty('caseModal')) this.$refs['caseModal'].newCase();
    }
  },
  created() {
    this.searching=true;
    DetectiveRepository.getCaseFiles().then(response => {
      this.items=response.data.data;
      this.searching=false;
    });

  }
}
</script>

<style>
input[type="checkbox"] {
  vertical-align: middle;
}
</style>