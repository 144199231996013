<template>

  <b-modal no-close-on-backdrop size="xl" id="lookup-modal" hide-footer :title="title">

    <b-tabs v-model="active" active-nav-item-class="text-dark" fill no-fade>
      <b-tab title="Name" active class="lookup-tab" title-link-class="text-white">

        <b-row>
          <b-col cols="8">
            <b-form-input type="text" v-model="lookup.name" placeholder="Search..." @keyup.enter="search('name')" autocomplete="off"></b-form-input>
          </b-col>
          <b-col cols="4">
            <b-btn variant="primary" class="form-control" @click="search('name')" v-if="!searching.name" >Search</b-btn>
            <b-btn variant="primary" class="form-control" v-if="searching.name" ><b-spinner small></b-spinner> Searching...</b-btn>
          </b-col>
        </b-row>

        <hr>
        <b-row v-if="ldata.name !== undefined && Object.keys(ldata.name).length !== 0">
          <b-col>

            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab" v-b-toggle.civilian-table>
                <b>Civilians ({{ ldata.name.civilians.length }})</b>
              </b-card-header>
              <b-collapse id="civilian-table" visible role="tabpanel">
                <b-card-body>
                  <LookupTable :data="this.ldata.name.civilians" :header-items="this.tables.civilian"  @showLookup="openCivilianModal"></LookupTable>
                </b-card-body>
              </b-collapse>
            </b-card>

            <template v-if="!fire">
              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab" v-b-toggle.civilian-records-table>
                  <b>Civilian Records ({{ ldata.name.registrations.firearms.length + ldata.name.registrations.licenses.length + ldata.name.registrations.vehicles.length }})</b>   <b-badge variant="warning" v-if="ldata.name.registrations.firearms.length > 0">Firearm(s)</b-badge>
                </b-card-header>
                <b-collapse id="civilian-records-table" role="tabpanel">
                  <b-card-body>
                    <b-tabs active-nav-item-class="text-dark" content-class="mt-3">
                      <b-tab active title-link-class="text-white" :title="`Firearms (`+this.ldata.name.registrations.firearms.length+`)`">
                        <LookupTable :data="this.ldata.name.registrations.firearms" :header-items="this.tables.registrations.firearms"></LookupTable>
                      </b-tab>
                      <b-tab title-link-class="text-white" :title="`Vehicles (`+this.ldata.name.registrations.vehicles.length+`)`">
                        <LookupTable :data="this.ldata.name.registrations.vehicles" :header-items="this.tables.registrations.vehicles" :flags="flags.vehicles" @flagclick="vehicleFlagged"></LookupTable>
                      </b-tab>
                      <b-tab title-link-class="text-white" :title="`Licenses (`+this.ldata.name.registrations.licenses.length+`)`">
                        <LookupTable :data="this.ldata.name.registrations.licenses" :header-items="this.tables.registrations.licenses" @showLookup="openEditLicenseModal"></LookupTable>
                      </b-tab>
                    </b-tabs>

                  </b-card-body>
                </b-collapse>
              </b-card>

              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab" v-b-toggle.warrants-table>
                  <b>Warrants ({{ ldata.name.warrants.length }})</b> <b-badge variant="danger" v-if="ldata.name.warrants.length > 0">Active warrant</b-badge>
                </b-card-header>
                <b-collapse id="warrants-table" role="tabpanel">
                  <b-card-body>
                    <LookupTable :data="this.ldata.name.warrants" :header-items="this.tables.warrants" @showLookup="openWarrantReportModal"></LookupTable>
                  </b-card-body>
                </b-collapse>
              </b-card>

              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab" v-b-toggle.bolo-table>
                  <b>BOLO ({{ ldata.name.bolo.length }})</b> <b-badge variant="danger" v-if="ldata.name.bolo.length > 0">Active BOLO</b-badge>
                </b-card-header>
                <b-collapse id="bolo-table" role="tabpanel">
                  <b-card-body>
                    <LookupTable :data="this.ldata.name.bolo" :header-items="this.tables.bolo" @showLookup="openBoloModal"></LookupTable>
                  </b-card-body>
                </b-collapse>
              </b-card>

              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab" v-b-toggle.arrests-table>
                  <b>Arrests ({{ ldata.name.arrests.length }})</b> <b-badge variant="warning" v-if="ldata.name.arrests.length > 0">Priors</b-badge>
                </b-card-header>
                <b-collapse id="arrests-table" role="tabpanel">
                  <b-card-body>
                    <LookupTable :data="this.ldata.name.arrests" :header-items="this.tables.arrests" @showLookup="openArrestReportModal"></LookupTable>
                  </b-card-body>
                </b-collapse>
              </b-card>

              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab" v-b-toggle.convictions-table>
                  <b>Convictions ({{ ldata.name.conviction.length }})</b> <b-badge variant="warning" v-if="ldata.name.conviction.length > 0">Priors</b-badge>
                </b-card-header>
                <b-collapse id="convictions-table" role="tabpanel">
                  <b-card-body>
                    <LookupTable :data="this.ldata.name.conviction" :header-items="this.tables.conviction" @showLookup="openConvictionModal"></LookupTable>
                  </b-card-body>
                </b-collapse>
              </b-card>

              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab" v-b-toggle.citations-table>
                  <b>Citations ({{ ldata.name.citations.length }})</b>
                </b-card-header>
                <b-collapse id="citations-table" role="tabpanel">
                  <b-card-body>
                    <LookupTable :data="this.ldata.name.citations" :header-items="this.tables.citation" @showLookup="openCitationReportModal"></LookupTable>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </template>
            <template v-else>

              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab" v-b-toggle.citations-table>
                  <b>Medical Records ({{ ldata.name.medical_records.length }})</b>
                </b-card-header>
                <b-collapse id="citations-table" role="tabpanel">
                  <b-card-body>
                    <LookupTable :data="this.ldata.name.medical_records" :header-items="this.tables.medical_records" @showLookup="openMedicalReportModal"></LookupTable>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </template>

          </b-col>
        </b-row>




      </b-tab>
      <b-tab title="Plate" class="lookup-tab" v-if="!fire" title-link-class="text-white">

        <b-row>
          <b-col cols="8">
            <b-form-input type="text" v-model="lookup.plate" placeholder="Search..." @keyup.enter="search('plate')" autocomplete="off"></b-form-input>
          </b-col>
          <b-col cols="4">
            <b-btn variant="primary" class="form-control" @click="search('plate')" v-if="!searching.plate">Search</b-btn>
            <b-btn variant="primary" class="form-control" v-if="searching.plate" ><b-spinner small></b-spinner> Searching...</b-btn>
          </b-col>
        </b-row>

        <hr>

        <b-row v-if="ldata.plate !== undefined && Object.keys(ldata.plate).length !== 0">
          <b-col>

            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab" v-b-toggle.vehicles-table>
                <b>Vehicles ({{ ldata.plate.vehicles.length }})</b>
              </b-card-header>
              <b-collapse id="vehicles-table" visible role="tabpanel">
                <b-card-body>
                  <LookupTable :data="this.ldata.plate.vehicles" :header-items="this.tables.registrations.vehicles" @showLookup="openCivilianPlateModal"></LookupTable>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab" v-b-toggle.plate-bolo-table>
                <b>BOLO ({{ ldata.plate.bolo.length }})</b> <b-badge variant="danger" v-if="ldata.plate.bolo.length > 0">Active BOLO</b-badge>
              </b-card-header>
              <b-collapse id="plate-bolo-table" role="tabpanel">
                <b-card-body>
                  <LookupTable :data="this.ldata.plate.bolo" :header-items="this.tables.bolo" @showLookup="openBoloPlateModal"></LookupTable>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab" v-b-toggle.plate-citations-table>
                <b>Citations ({{ ldata.plate.citations.length }})</b>
              </b-card-header>
              <b-collapse id="plate-citations-table" role="tabpanel">
                <b-card-body>
                  <LookupTable :data="this.ldata.plate.citations" :header-items="this.tables.citation" @showLookup="openCitationPlateModal"></LookupTable>
                </b-card-body>
              </b-collapse>
            </b-card>

          </b-col>
        </b-row>

      </b-tab>
    </b-tabs>

    <BoloDetails :bolo-select="selected.bolo" v-if="selected.bolo!==null" modal-id="bolo-lookup-modal"></BoloDetails>
    <CivilianShow :civilian="selected.civilian" v-if="selected.civilian!==null" :fire="fire"></CivilianShow>
    <ArrestReportShow :arrest_report_id="selected.arrest" v-if="selected.arrest!==null"></ArrestReportShow>
    <WarrantShow :warrant="selected.warrant" v-if="selected.warrant!==null"></WarrantShow>
    <CitationShow :citation_id="selected.citation" v-if="selected.citation!==null"></CitationShow>
    <MedicalRecordShow :medical_record="selected.medical_record" v-if="selected.medical_record!==null"></MedicalRecordShow>
    <EditLicense @updateLicense="updateLicense" v-if="doesUserHavePermission('license_edit')" ref="editLicense"></EditLicense>

  </b-modal>

</template>

<script>

import CitizenRepository from "@/api/CitizenRepository";
import LookupTable from "@/components/Partials/LEO/Lookup/LookupTable";
import BoloDetails from "@/components/Partials/LEO/Bolos/BoloDetails";
import LeoRepository from "@/api/LeoRepository";
import CivilianShow from "@/components/Partials/LEO/Lookup/CivilianShow";
import ArrestReportShow from "@/components/Partials/LEO/Lookup/ArrestReportShow";
import WarrantShow from "@/components/Partials/LEO/Lookup/WarrantShow";
import CitationShow from "@/components/Partials/LEO/Lookup/CitationShow";
import FlagsRepository from "@/api/FlagsRepository";
import FdRepository from "@/api/FdRepository";
import MedicalRecordShow from "@/components/Partials/LEO/Lookup/MedicalRecordShow";
import EditLicense from "./EditLicense";

export default {
  name: "Lookup",
  components: {
    EditLicense,
    MedicalRecordShow, CitationShow, WarrantShow, ArrestReportShow, CivilianShow, BoloDetails, LookupTable},
  props:['fire'],
  data(){
    return {
      active: 0,
      lookup: {
        name: null,
        plate: null,
      },
      ldata:{
        name: {},
        plate: {}
      },
      searching:{
        name: false,
        plate: false,
      },
      tables: {
        civilian:[
          {
            title:'Name',
            key:'name'
          },{
            title:'Age',
            key:'age'
          },{
            title:'Ethnicity',
            key:'ethnicity.name'
          },{
            title:"Gender",
            key:'gender.name'
          },{
            title:"Address",
            key:'address'
          },{
            title: 'Flags',
            key: 'flags'
          }
        ],
        registrations:{
          firearms:[
            {
              title:'Owner',
              key:'owner.name'
            },{
              title:'Owner Age',
              key:'owner.age'
            },
            {
              title:'Model',
              key:'weapon_model'
            },
            {
              title:'Type',
              key:'weapon_type'
            }
          ],
          vehicles:[
            {
              title:'Owner',
              key:'owner.name'
            },{
              title:'Owner Age',
              key:'owner.age'
            },
            {
              title:'Plate',
              key:'plate'
            },
            {
              title:'Make',
              key:'make'
            },
            {
              title:'Model',
              key:'model'
            },
            {
              title:'Color',
              key:'color'
            },
            {
              title:'Year',
              key:'year'
            },
            {
              title: 'Flags',
              key: 'flags'
            }
          ],
          licenses:[
            {
              title:'Name',
              key:'civilian.name'
            },
            {
              title:'Civilian\'s Age',
              key:'civilian.age'
            },
            {
              title:'License Type',
              key:'license.name'
            },
            {
              title:'Status',
              key:'status'
            }
          ]
        },
        warrants:[
          {
            title:"Name",
            key:'civilian.name',
          },
          {
            title:"Age",
            key:'civilian.age',
          },
          {
            title:"Warrant Type",
            key:'warrant_type',
          },
          {
            title:"Date issued",
            key:'date_time',
          },
        ],
        bolo:[
          {
            title:"Name",
            key:'civilian.name',
          },
          {
            title:"Age",
            key:'civilian.age',
          },
          {
            title:"Wanted for",
            key:'wanted_for',
          },
          {
            title:"Plate",
            key:'plate'
          }
        ],
        arrests:[
          {
            title:"Name",
            key:'civilian.name',
          },
          {
            title:"Age",
            key:'civilian.age',
          },
          {
            title:'Date',
            key:'date_time'
          }
        ],
        citation:[
          {
            title:"Name",
            key:'civilian.name',
          },
          {
            title:"Age",
            key:'civilian.age',
          },
          {
            title:"Plate",
            key:"vehicle_plate"
          },
          {
            title:'Date',
            key:'date_time'
          },
          {
            title:"Vehicle Make",
            key:'vehicle.make'
          },
          {
            title:"Vehicle Model",
            key:'vehicle.model'
          },
        ],
        medical_records:[
          {
            title:"Name",
            key:'civilian.name',
          },
          {
            title:"Age",
            key:'civilian.age',
          },
          {
            title:'Date',
            key:'date_time'
          },
          {
            title:'Cause of Injury',
            key:'cause_of_injury'
          },
          {
            title:'Injury',
            key:'injury_description'
          },
        ],
        conviction:[
          {
            title:"Name",
            key:"defendant.name"
          },
          {
            title:"Age",
            key:"defendant.age"
          },
          {
            title:"Date of Conviction",
            key:"created_at"
          },
        ]
      },
      flags: {
        vehicles:{
          'stolen':{
            true:'Mark as found',
            false:'Flag as stolen'
          },
          'impounded':{
            true:'Mark as released',
            false:'Flag as impounded'
          }
        }
      },
      selected: {
        civilian: null,
        firearm: null,
        vehicle: null,
        warrant: null,
        bolo: null,
        arrest: null,
        citation: null,
        medical_record: null,
        license: null,
      }
    }
  },
  computed: {
    title(){
      if(this.active === 0){

        if(this.lookup.name !== null){
          return "Lookup - Name: " + this.lookup.name;
        } else {
          return "Lookup - Name: ";
        }

      } else if(this.active === 1){

        if(this.lookup.plate !== null){
          return "Lookup - Plate: " + this.lookup.plate;
        } else {
          return "Lookup - Plate: ";
        }

      }
    }
  },
  methods: {
    search(type){
      this.$set(this.searching, type, true);
      CitizenRepository.lookup(type,this.lookup[type]).then(response => {
        this.$set(this.searching, type, false);
        this.$set(this.ldata, type, response.data.data);
        let data = this.ldata;
        let warnings = [];


        if (type === 'plate') {
          if (data.plate.bolo.length > 0) warnings.push('bolo');
        }
        if (type === 'name') {
          if (data.name.bolo.length > 0) warnings.push('bolo');
          if (data.name.arrests.length > 0) warnings.push('arrests');
          if (data.name.warrants.length > 0) warnings.push('warrant');
          if (data.name.registrations.firearms.length > 0) warnings.push('firearm');
        }
        for(let key in warnings) {
          let warning = warnings[key]
          setTimeout(function () {
            const audio = new Audio('audio/tts/'+warning+'.mp3');
            audio.play();
          }, 1500*key)
        }
      }).catch(errors => {
        this.$set(this.searching, type, true);
      });

    },

    openBoloData(data){
      LeoRepository.getBolos(data).then( response => {
        this.selected.bolo=response.data.data;
        setTimeout(() => this.$root.$emit('bv::show::modal', 'bolo-lookup-modal'), 100);
      });
    },

    openBoloModal(value){
      this.openBoloData(this.ldata.name.bolo[value].id);
    },

    openBoloPlateModal(value){
      this.openBoloData(this.ldata.plate.bolo[value].id);
    },

    openCivilianPlateModal(value){
      this.lookup.name=this.ldata.plate.vehicles[value].owner.name;
      this.active=0;
      this.search('name');
    },

    openCivilianModal(value){
      this.selected.civilian=this.ldata.name.civilians[value];
      setTimeout(() => this.$root.$emit('bv::show::modal', 'civilian-lookup-modal'), 300);
    },

    openEditLicenseModal(value){
      if (this.doesUserHavePermission('license_edit')) {
        this.selected.license = this.ldata.name.registrations.licenses[value];
        this.$refs['editLicense'].openToEdit(this.ldata.name.registrations.licenses[value])
      }
    },

    openArrestReportModal(value){
      this.selected.arrest=this.ldata.name.arrests[value].id;
      setTimeout(() => this.$root.$emit('bv::show::modal', 'arrest-report-lookup-modal'), 100);
    },

    openWarrantReportModal(value){
      LeoRepository.getWarrant(this.ldata.name.warrants[value].id).then( response => {
        this.selected.warrant=response.data.data;
        setTimeout(() => this.$root.$emit('bv::show::modal', 'modal-warrant-show'), 100);
      });
    },

    openCitationPlateModal(value){
      this.openCitationModal(this.ldata.plate.citations[value].id);
    },

    openCitationReportModal(value){
      this.openCitationModal(this.ldata.name.citations[value].id);
    },

    updateLicense(status) {
      this.selected.license.status = status
    },

    openMedicalReportModal(value){
      FdRepository.getMedicalRecord(this.ldata.name.medical_records[value].id).then( response => {
        this.selected.medical_record=response.data.data
        setTimeout(() => this.$root.$emit('bv::show::modal', 'modal-medical-record-show'), 100)
      })
    },

    openCitationModal(data){
      this.selected.citation=data;
      setTimeout(() => this.$root.$emit('bv::show::modal', 'modal-citation-show'), 100);
    },

    openConvictionModal(data){
      data=this.ldata.name.conviction[data];
      window.open(data.pdf,'_blank');
    },


    /*FLAGS*/
    vehicleFlaggedPlate(type, index){
      let vehicle=this.ldata.plate.vehicles[index];

      let action = null;

      switch(type){
        case 'stolen':
          action=FlagsRepository.vehicleStolen(vehicle.id);
          break;
        case 'impounded':
          action=FlagsRepository.vehicleImpounded(vehicle.id);
          break;
      }

      if(action!==null){
        action.then(response => {
          if(response.data.hasOwnProperty('value')){
            this.$set(this.ldata.plate.vehicles[index].flags, type, response.data);
          } else if(!response.data){
            this.$delete(this.ldata.plate.vehicles[index].flags, type);
          }

        });
      }

    },


    vehicleFlagged(type, index){
      let vehicle=this.ldata.name.registrations.vehicles[index];

      let action = null;

      switch(type){
        case 'stolen':
          action=FlagsRepository.vehicleStolen(vehicle.id);
          break;
        case 'impounded':
          action=FlagsRepository.vehicleImpounded(vehicle.id);
          break;
      }

      if(action!==null){
        action.then(response => {
          this.$set(this.ldata.name.registrations.vehicles[index], 'flags', response.data);
        });
      }

    }

  },

}
</script>

<style scoped>
  .lookup-tab {
    padding-top: 10px;
  }

  .nav-tabs > li.active > a {
    color: #d73a3a;
    font-size: 16px;
  }
  .nav-tabs > li > a {
    color: #bb2828;
  }

  .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    background-color: #b4bac0;
  }
</style>