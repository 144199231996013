<template>
  <div>
    <b-form-row>
      <b-col>
        <b-form-group label="Title" label-for="title">
          <b-form-input
              id="title"
              v-model="form.title"
              type="text"
              placeholder="Title of the case"
              autocomplete="off"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Target" label-for="target">
          <b-form-input
              id="target"
              v-model="form.target"
              type="text"
              placeholder="Civilian or Group Name"
              autocomplete="off"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col>
        <b-form-group label="District Attorney Assigned" label-for="da_assigned">
          <b-form-input
              id="da_assigned"
              v-model="form.da_assigned"
              type="text"
              placeholder="District Attorney Assigned"
              autocomplete="off"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Status" label-for="status">
          <b-form-select v-model="form.status" :options="status_options"></b-form-select>
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col>
        <b-form-group label="Brief Description" label-for="brief_description">
          <b-form-textarea
              id="description"
              v-model="form.brief_description"
              type="text"
              placeholder="Brief description of the case">
          </b-form-textarea>
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col>
        <b-form-group label="Narrative">
          <b-form-textarea
              id="narrative"
              v-model="form.case_narrative"
              placeholder="Case narrative / log..."
              rows="12"
          ></b-form-textarea>
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col>
        <b-form-group label="Detectives Assigned" label-for="detectives_assigned">
          <v-select multiple @search="searchUnits" :options="units" v-model="form.detectivesAssigned"></v-select>
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col>
        <b-form-group label="Citizens Identified" label-for="citizens_identified">
          <v-select multiple @search="searchCitizens" :options="citizens" v-model="form.identifiedCivs"></v-select>
        </b-form-group>
      </b-col>
    </b-form-row>

    <ErrorDisplay :errors="errors"></ErrorDisplay>

    <b-form-row>
      <b-col class="text-right">
        <b-button variant="outline-danger" v-if="caseFile !== null && doesUserHavePermission('case_file_delete')" @click="deleteCaseFile">Delete</b-button>
        <b-button :variant="loadingVariant" @click="nextStep" :loading="loading" v-if="caseFileId===null || (doesUserHavePermission('case_file_edit') || caseFile.created_by_id===user.id)">
          <span v-if="loading">
            <b-spinner type="grow" variant="success" class="align-middle" small></b-spinner>
          </span>
          <span v-else>
            <template v-if="caseFileId===null">Next ></template>
            <template v-if="caseFileId!==null && (doesUserHavePermission('case_file_edit') || caseFile.created_by_id===user.id)">Save</template>
          </span>
        </b-button>
      </b-col>
    </b-form-row>
  </div>
</template>

<script>

import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import DetectiveRepository from "@/api/DetectiveRepository";
import ErrorDisplay from "@/components/Partials/ErrorDisplay";

export default {
  name: "CaseDetails",
  components: {ErrorDisplay},
  data() {
    return {
      caseFileId: this.caseFile !== null ? this.caseFile.id : null,
      loading: false,
      form: {
        title: null,
        target: null,
        brief_description:null,
        case_narrative: null,
        da_assigned: null,
        status:null,
        identifiedCivs: [],
        detectivesAssigned: []
      },
      errors:null,
      status_options: [
        {value:'ongoing', text:'On Going'},
        {value:'intrial', text:'In Trial'},
        {value:'close', text:'Closed'},
        {value:'archived', text:'Archived'},
        {value:'cold', text:'Cold'},
      ],
      citizens:[],
      units:[],
    }
  },
  props: [
      'caseFile','editorConfig','CKEditor'
  ],
  computed: {
    loadingVariant() {
      return this.loading ? 'outline-warning' : 'outline-success';
    },
    user(){
      return this.$store.state.auth.user;
    }
  },
  mounted(){
    if(this.caseFile !== null){
      let cf=this.caseFile;
      this.form = {
        title:cf.title,
        target:cf.target,
        brief_description: cf.brief_description,
        case_narrative: cf.case_narrative,
        da_assigned: cf.da_assigned,
        status: cf.status,
        identifiedCivs: cf.case_file_civilians_identified,
        detectivesAssigned: cf.case_file_detectives
      };

      this.form.detectivesAssigned=cf.case_file_detectives.map(obj=> ({ ...obj, label: "[" + obj.identifier + "] " + obj.rank + " " + obj.name + " [" + obj.agency.short_name + "]" }));
      this.units=this.form.detectivesAssigned;

      this.form.identifiedCivs=cf.case_file_civilians_identified.map(obj=> ({ ...obj, key: obj.id, label: obj.name + " [" + obj.dob + " - " + obj.age + "yo ]" }))
      this.citizens=this.form.identifiedCivs;
    } else {
      this.form.case_narrative=" ";
    }
  },
  methods: {
    searchCitizens(search, loading){
      if(search.length >= 3){
        loading(true);
        DetectiveRepository.searchCitizens(search).then(response => {
          let objArr=Object.values(response.data)[0];
          objArr=objArr.map(obj=> ({ ...obj, label: obj.name + " - " + obj.gender.name + " " + obj.ethnicity.name + " [" + obj.dob + " - " + obj.age + "yo ]" }));
          this.citizens=objArr;
          loading(false);
        }).catch(error => {
          loading(false);
        });
      }
    },
    searchUnits(search,loading){
      if(search.length >= 3){
        loading(true);
        DetectiveRepository.searchUnits(search).then(response => {
          let objArr=Object.values(response.data)[0];
          objArr=objArr.map(obj=> ({ ...obj, label: "[" + obj.identifier + "] " + obj.rank + " " + obj.name + " [" + obj.agency.short_name + "]" }));
          this.units=objArr;
          loading(false);
        }).catch(error => {
          loading(false);
        });
      }
    },
    nextStep(){
      this.loading=true;
      if(this.caseFileId){
        DetectiveRepository.saveCaseFile(this.form,this.caseFile.id).then(response => {
          this.$emit('createdCaseFile',response.data);
          this.loading=false;
          this.errors=null;
        });
      } else {
        DetectiveRepository.createCaseFile(this.form).then(response => {
          this.$emit('createdCaseFile',response.data);
          this.loading=false;
          this.errors=null;
        }).catch(error => {
          this.loading=false;
          this.errors=error.response.data.errors;
        });
      }
    },
    deleteCaseFile() {
      if(this.caseFile===null) return false;
      DetectiveRepository.deleteCaseFile(this.caseFile.id).then(response => {
        this.errors=null;
        this.$emit('deleteCaseFile');
      }).catch(error => {
        this.errors=error.response.data.errors;
      });
    }
  }
}
</script>

<style>
.vs__selected {
  color:#000!important;
}
</style>
