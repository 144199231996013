import Repository from "./Repository";

class DetectiveRepository {
    searchCitizens(name){
        return Repository.get('civilians?search='+name);
    };

    searchUnits(search){
      return Repository.get('units?search='+search);
    };

    createCaseFile(data){
        return Repository.post('case-files',data);
    };

    saveCaseFile(data,id){
        return Repository.put('case-files/'+id,data);
    }

    createAfterAction(data){
        return Repository.post('after-action-reports',data);
    }

    saveAfterAction(data,id){
        return Repository.put('after-action-reports/'+id,data);
    }

    approveAfterAction(data,id){
        return Repository.post('after-action-reports/'+id+'/approve',data);
    }

    deleteAfterAction(id){
        return Repository.delete('after-action-reports/'+id);
    }

    getCaseFiles(queryParams=null){
        let query="";
        if(queryParams!==null){
            const searchParams = new URLSearchParams()
            Object.keys(queryParams).forEach(key => {
                if(queryParams[key]) searchParams.append(key, queryParams[key])
            });
            query = "?" + searchParams.toString()
        }
        return Repository.get('case-files'+query);
    }

    getCaseFileById(id){
        return Repository.get('case-files/'+id);
    }

    deleteCaseFile(id){
        return Repository.delete('case-files/'+id);
    }

    getAfterActions(queryParams=null){
        let query="";
        if(queryParams!==null){
            const searchParams = new URLSearchParams()
            Object.keys(queryParams).forEach(key => {
                if(queryParams[key]) searchParams.append(key, queryParams[key])
            });
            query = "?" + searchParams.toString()
        }
        return Repository.get('after-action-reports'+query);
    }

    getAfterActionById(id){
        return Repository.get('after-action-reports/'+id);
    }

    createEvidence(data){
        let formData=new FormData();
        for(let key in data){
            if(data.hasOwnProperty(key)){
                let object=data[key];
                if(object === null) continue;
                if (typeof object !== 'object'){
                    formData.append(key, object);
                } else {


                    if(key === 'case_files'){
                        for(let k in object){
                            formData.append(key+"[]", object[k]);
                        }
                    } else {
                        if(key !== 'proof') formData.append(key, JSON.stringify(object));
                        else formData.append(key, object);
                    }

                }
            }
        }

        return Repository.post('evidence',formData);
    }

    saveEvidence(id,data){
        return Repository.put('evidence/'+id,data);
    }

    associateEvidence(identification,type,modelId){
        return Repository.post('evidence/associate/'+identification,{id:modelId, type:type});
    }


    deleteEvidenceById(id){
        return Repository.delete("evidence/"+id);
    }

    createStatement(data){
        return Repository.post('statements',data);
    }

    deleteStatement(id){
        return Repository.delete('statements/'+id);
    }

    createDocument(data){
        return Repository.post('documents',data);
    }

    deleteDocument(id){
        return Repository.delete('documents/'+id);
    }

    createPicture(data){

        let formData=new FormData();
        for(let key in data){
            if(data.hasOwnProperty(key)){
                let object=data[key];
                if(object === null) continue;
                if (typeof object !== 'object'){
                    formData.append(key, object);
                } else {


                    if(key === 'case_files'){
                        for(let k in object){
                            formData.append(key+"[]", object[k]);
                        }
                    } else {
                        if(key !== 'picture') formData.append(key, JSON.stringify(object));
                        else formData.append(key, object);
                    }

                }
            }
        }

        return Repository.post('pictures',formData);
    }

    deletePicture(id){
        return Repository.delete('pictures/'+id);
    }

    caseFileAssociateRecord(id,data){
        return Repository.post('case-files/'+id+'/associate',data);
    }

    caseFileDesAssociateRecord(id,data){
        return Repository.post('case-files/'+id+'/desassociate',data);
    }

    afterActionAssociateRecord(id,data){
        return Repository.post('after-action-reports/'+id+'/associate',data);
    }

    afterActionDesAssociateRecord(id,data){
        return Repository.post('after-action-reports/'+id+'/desassociate',data);
    }
}

export default new DetectiveRepository();