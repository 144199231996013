import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '@/views/Home.vue'

import NotFound from '@/components/NotFound.vue'
import Login from '@/components/Auth/Login'
import LEO from "@/components/Pages/LEO";
import FD from '@/components/Pages/FD';
import DOJ from '@/components/Pages/DOJ';
import Citizen from '@/components/Pages/Citizen'
import Profile from '@/components/Pages/Profile'
import Dispatch from '@/components/Pages/Dispatch'
import ArrestReport from "@/components/Partials/LEO/Reports/ArrestReport";
import Citation from "@/components/Partials/LEO/Reports/Citation";
import Warrant from "@/components/Partials/LEO/Reports/Warrant";
import NewCitizen from "@/components/Partials/Citizen/NewCitizen";
import NewLicense from "@/components/Partials/Citizen/NewLicense";
import Livemap from "@/components/Partials/Dispatch/Live Map/Livemap";
import Detectives from "../components/Pages/Detectives";
import Supervisor from "../components/Pages/Supervisor";

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    name: 'NotFound',
    component: NotFound,
  }, {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      auth: false,
    },
  },
  {
    path: '/login',
    component: Login
  },
  {
    path: '/leo',
    name: 'leo',
    component: LEO
  },
  {
    path: '/fd',
    name:'fd',
    component: FD
  },
  {
    path: '/citizen/:citizen?',
    name: 'citizen',
    component: Citizen,
    props: true
  },
  {
    path: '/profile',
    component: Profile
  },
  {
    path: '/dispatch',
    name: 'dispatch',
    component: Dispatch
  },
  {
    path: '/livemap',
    component: Livemap,
    name:'livemap'
  },
  {
    path: '/doj',
    component: DOJ,
    name:'doj'
  },
  {
    path: '/detectives',
    component: Detectives,
    name: 'detectives'
  },
  {
    path: '/supervisor',
    component: Supervisor,
    name: 'supervisor'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  const publicPages = ['/login', '/register', '/home'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('userinfo');

  if (authRequired && !loggedIn) {
    next('/login');
  } else {
    next();
  }
});

export default router
