<template>
  <b-modal size="sm" no-close-on-backdrop id="edit-license-modal" title="Edit License" ref="formModal">
    <b-form-group label="Status" label-for="status">
      <v-select name="type" :options="types" v-model="form.status"></v-select>
    </b-form-group>

    <ErrorDisplay :errors="errors"></ErrorDisplay>

    <template #modal-footer>
      <b-button variant="primary" @click="editLicense">
        Update
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import ErrorDisplay from "../../ErrorDisplay";
import CitizenRepository from "../../../../api/CitizenRepository";

export default {
  components: {ErrorDisplay},
  props: ['license'],
  name: "EditLicense",
  data() {
    return {
      form: {
        civilian_id: null,
        license_id: null,
        status: null
      },
      types: null,
      errors: null
    }
  },
  methods: {
    openToEdit(license) {
      this.errors=null
      this.form.civilian_id=license.civilian.id;
      this.form.license_id=license.license.id
      this.form.status=license.status
      let aux = license.license.status_types.split(',');
      let final = [];

      for (let k in aux) {
        final.push(aux[k].trim().replace(/^\w/, (c) => c.toUpperCase()));
      }
      console.log(final)

      this.types = final;

      this.$root.$emit('bv::show::modal', 'edit-license-modal')

    },
    editLicense() {
      CitizenRepository.createCitizenLicense(this.form).then(response => {
        this.$refs['formModal'].hide();
        this.$emit('updateLicense', this.form.status);
      }).catch(error => {
        this.errors = error.response.data.errors;
      });
    }
  },
}
</script>

<style scoped>

</style>