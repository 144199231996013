<template>
  <b-modal no-close-on-backdrop id="modal-citation-show" size="lg" title="Citation Information" >

    <div v-if="citation !== null">
      <h6>Civilian Information</h6>
      <hr>

      <b-form-row>
        <b-col cols="4">
          <div class="fw-bold font-weight-bolder dd-title">Full Name</div>
          {{ citation.civilian.name }}
        </b-col>

        <b-col cols="4">
          <div class="fw-bold font-weight-bolder dd-title">DOB</div>
          {{ citation.civilian.dob }}
        </b-col>

        <b-col cols="4">
          <div class="fw-bold font-weight-bolder dd-title">Gender</div>
          {{ citation.civilian.gender.name }}
        </b-col>
      </b-form-row>

      <b-form-row>
        <b-col cols="4">
          <div class="fw-bold font-weight-bolder dd-title">Address</div>
          {{ citation.civilian.address }}
        </b-col>

        <b-col cols="4">
          <div class="fw-bold font-weight-bolder dd-title">Postal</div>
          {{ citation.civilian.postal }}
        </b-col>

        <b-col cols="4">
          <div class="fw-bold font-weight-bolder dd-title">Hair Color</div>
          {{ citation.civilian.hair_color }}
        </b-col>
      </b-form-row>
      <hr>

      <h6>Charges</h6>
      <hr>

      <template v-for="(charge,index) in citation.charges">
        <b-form-row>
          <b-col cols="2" class="text-center">#{{ (index+1) }}</b-col>
          <b-col cols="10">
            <b-form-row>
              <b-col cols="6">
                <div class="fw-bold font-weight-bolder dd-title">Charge</div>
                {{ charge.title }}
              </b-col>

              <b-col cols="3">
                <div class="fw-bold font-weight-bolder dd-title">Type</div>
                {{ charge.pivot.type }}
              </b-col>

              <b-col cols="3">
                <div class="fw-bold font-weight-bolder dd-title">Counts</div>
                {{ charge.pivot.counts }}
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col cols="6">
                <div class="fw-bold font-weight-bolder dd-title">Code</div>
                {{ charge.code }}
              </b-col>
              <b-col cols="3">
                <div class="fw-bold font-weight-bolder dd-title">Fine Value</div>
                {{ charge.pivot.fine_value }}
              </b-col>
              <b-col cols="3">
                <div class="fw-bold font-weight-bolder dd-title">Jail Time</div>
                {{ charge.pivot.jail_time }}
              </b-col>
            </b-form-row>
          </b-col>
        </b-form-row>
        <hr>
      </template>


      <h6>Citation Information</h6>
      <hr>

      <b-form-row>
        <b-col cols="12" class="narrative">
          <div class="fw-bold font-weight-bolder dd-title">Narrative</div>
          {{ citation.narrative }}
        </b-col>
      </b-form-row>
      <hr>

      <h6>Vehicle Information</h6>
      <hr>

      <b-form-row v-if="citation.vehicle!==null">
        <b-col cols="6">
          <div class="fw-bold font-weight-bolder dd-title">Make Model</div>
          {{ citation.vehicle.make }} {{ citation.vehicle.model }}
        </b-col>

        <b-col cols="3">
          <div class="fw-bold font-weight-bolder dd-title">Color</div>
          {{ citation.vehicle.color }}
        </b-col>

        <b-col cols="3">
          <div class="fw-bold font-weight-bolder dd-title">Year</div>
          {{ citation.vehicle.year }}
        </b-col>

      </b-form-row>

      <b-form-row>
        <b-col>
          <div class="fw-bold font-weight-bolder dd-title">Plate</div>
          {{ citation.vehicle_plate }}
        </b-col>
      </b-form-row>

      <hr>
      <h6>Officer Information</h6>
      <hr>

      <b-form-row>
        <b-col cols="4">
          <div class="fw-bold font-weight-bolder dd-title">Identifier</div>
          {{ citation.officer.identifier }}
        </b-col>

        <b-col cols="4">
          <div class="fw-bold font-weight-bolder dd-title">Officer</div>
          {{ citation.officer.rank }} {{ citation.officer.name }}
        </b-col>

        <b-col cols="4">
          <div class="fw-bold font-weight-bolder dd-title">Agency</div>
          {{ citation.officer.agency.short_name }}
        </b-col>

        <b-col cols="4">
          <div class="fw-bold font-weight-bolder dd-title">Date/Time</div>
          {{ citation.date_time }}
        </b-col>
      </b-form-row>
    </div>

    <template #modal-footer>
      <b-button variant="danger" @click="deleteCitation()" v-if="doesUserHavePermission('leo_citation_delete')">
        Delete
      </b-button>
    </template>

  </b-modal>
</template>

<script>
import LeoRepository from "@/api/LeoRepository";

export default {
  name: "CitationShow",
  props: ['citation_id'],
  data() {
    return {
      citation:null
    }
  },
  mounted(){
    this.setCitationInfo();
  },
  methods: {
    setCitationInfo(){

      LeoRepository.getCitation(this.citation_id).then(response => {
        this.citation=response.data.data;
      });

    },
    deleteCitation(){
      LeoRepository.deleteCitation(this.citation.id).then(response => {
        this.bolo=null;
        this.$root.$emit('bv::hide::modal', 'modal-citation-show')
      }).catch(error => {

      });
    }
  },
  watch:{
    citation_id: function (newVal, oldVal){
      this.citation=null;
      this.setCitationInfo();
    }
  }
}
</script>

<style scoped>
.narrative {
  white-space: break-spaces;
}
</style>