<template>
  <b-modal id="case-modal" size="xl" no-close-on-backdrop :title="modalTitle" ref="newCase" hide-footer>

    <b-tabs content-class="mt-3" justified v-model="tabIndex">
      <b-tab title="Details">
        <CaseDetails @createdCaseFile="caseFileCreated" v-bind:CKEditor="CKEditor" v-bind:editorConfig="editorConfig" :case-file="caseFile" @deleteCaseFile="caseFileDeleted" ></CaseDetails>
      </b-tab>
      <b-tab title="Evidence" :disabled="caseFile===null">
        <EvidenceForm v-if="caseFile!==null" v-bind:case-file-id="caseFile.id" v-bind:case-file="caseFile"></EvidenceForm>
      </b-tab>
      <b-tab title="Statements" :disabled="caseFile===null">
        <StatementsForm v-if="caseFile!==null" :case-file="caseFile"></StatementsForm>
      </b-tab>
      <b-tab title="Documents" :disabled="caseFile===null">
        <DocumentForm v-if="caseFile!==null" :case-file="caseFile"></DocumentForm>
      </b-tab>
      <b-tab title="Related Documentation" :disabled="caseFile===null">
        <RelatedDocumentation v-if="caseFile!==null" :case-file="caseFile"></RelatedDocumentation>
      </b-tab>
    </b-tabs>

  </b-modal>
</template>

<script>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CaseDetails from "@/components/Partials/Detectives/FormCaseModal/CaseDetails";
import EvidenceForm from "@/components/Partials/Detectives/FormCaseModal/EvidenceForm";
import StatementsForm from "./FormCaseModal/StatementsForm";
import DocumentForm from "./FormCaseModal/DocumentForm";
import DetectiveRepository from "@/api/DetectiveRepository";
import RelatedDocumentation from "@/components/Partials/Detectives/FormCaseModal/RelatedDocumentation";

export default {
  name: "NewCaseModal",
  components: {RelatedDocumentation, DocumentForm, StatementsForm, EvidenceForm, CaseDetails},
  props: ['caseFileId'],
  data() {
    return {
      caseFile:null,
      tabIndex:0,
      CKEditor: ClassicEditor,
      editorConfig: {
        toolbar: {
          items: [
            'heading',
            '|',
            'alignment',
            'bold',
            'italic',
            'link',
            'bulletedList',
            'numberedList',
            'indent',
            'blockQuote',
            'undo',
            'redo'
          ]
        }
      }
    }
  },
  computed: {
    modalTitle() {
      if(this.caseFile){
        return "Case File #"+this.caseFile.id+" - " + this.caseFile.title;
      } else {
        return "Create new case file";
      }
    }
  },
  methods: {
    newCase() {
      this.caseFile=null;
      this.$refs.newCase.show()
    },
    caseFileCreated(cf) {
      this.caseFile=cf.data;
      setTimeout(() => {
        this.tabIndex=this.tabIndex+1; }, 100);
    },
    caseFileDeleted(){
      this.caseFile=null;
      this.$refs.newCase.hide();
    },
    show(id){
      DetectiveRepository.getCaseFileById(id).then(response => {
        this.caseFile=response.data.data;
        this.$refs.newCase.show();
      });
    }
  },
  mounted() {
    if(this.caseFileId){
      DetectiveRepository.getCaseFileById(this.caseFileId).then(response => {
        this.caseFile=response.data.data;
        this.$refs.newCase.show();
      });
    }
  },
}
</script>

<style>
  .nav-link {
    color:#FFF!important;
  }

  .nav-link.disabled {
    color:#6c757d!important;
  }

  .nav-link.active {
    color:#000!important;
  }
</style>
