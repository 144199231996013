<template>
  <div>
    <b-button v-if="!statusButtons" variant="outline-warning" disabled class="text-center">
      <b-spinner
        v-if="!statusButtons"
        type="grow"
        variant="warning"
        class="align-middle"
        small
      />
    </b-button>
    <button class="btn statusBtn" v-for="status in statusButtons" v-on:click="changeStatus(status)" :style="{ borderColor: status.color, backgroundColor: backgroundColorCode(status) }">{{ status.name }}</button>
  </div>

</template>

<script>
import DataRepository from '@/api/DataRepository'
import LeoRepository from "@/api/LeoRepository";

export default {
  name: "StatusButtons",
  props: ['statusButtons','unit'],
  data() {
    return {
      loading: true,
      currentStatus: null
    }
  },
  methods: {
    backgroundColorCode(status){
      if(this.unit!== null && status.id === this.unit.current_status_id) return status.color;
      else return "transparent";
    },
    changeStatus(status){
      if(this.unit !== null){
        LeoRepository.updateUnitStatus(this.unit.id,status).then(response => {
          this.$store.commit('auth/leoUnitSet',response.data.data);
        });
      }
    }
  },
  sockets:{
    updateUnitsList: function(data){
      if(typeof data.id !== "undefined" && this.unit!==null && this.unit.id===data.id){
        this.$store.commit('auth/leoUnitSet',data);
      }
    }
  }
}
</script>

<style scoped>
  .statusBtn {
    color:#FFF;
  }

  .statusBtn.active {
    background-color: #FFF;
    color: #000;
  }
</style>