<template>
  <div class="parentDiv">
    <b-form-row class="mb-3">
      <b-col cols="3">
        <b-form-input v-model="evidence_identification" placeholder="Evidence identification number"></b-form-input>
      </b-col>
      <b-col cols="3">
        <b-button block variant="primary" @click="associateEvidence">Associate evidence <span v-if="isAssociating"><b-spinner type="grow" variant="white" class="align-middle" small></b-spinner></span></b-button>
      </b-col>
    </b-form-row>

    <b-alert :show="associateError!==null" variant="danger">
      {{associateError}}
    </b-alert>

    <b-card no-body class="evidenceCard" >
      <b-card-header>
        <h6 class="card-title d-inline">New evidence</h6>
        <b-btn variant="outline-primary" class="d-inline float-right" size="sm" v-b-toggle="'evidence-form'">Toggle Form</b-btn>
        <b-btn variant="danger" class="d-inline float-right" size="sm" @click="addNewEvidence">Clear</b-btn>

        <b-btn variant="success" class="d-inline float-right" size="sm" @click="submitEvidence">Submit</b-btn>
      </b-card-header>
      <b-collapse :id="'evidence-form'" accordion="evidence-form" role="tabpanel" v-model="showForm">
        <b-card-body>
          <b-form-row>
              <b-col>
                <b-form-group label="Type" :label-for="'type'">
                  <b-form-input
                      :id="'type'"
                      v-model="form.evidences.type"
                      type="text"
                      placeholder="Type of evidence (i.e.: Bullet, CCTV footage, Blood)"
                      autocomplete="off"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Proof (OOC image of /gme or IC picture)" :label-for="'proof'">

                  <b-form-file
                      v-model="form.evidences.proof"
                      :state="Boolean(form.evidences.proof)"
                      placeholder="Choose a image or drop it here..."
                      drop-placeholder="Drop file here..."
                      :id="'proof'"
                      @change="handleProofUpload($event)"
                  ></b-form-file>
                </b-form-group>
              </b-col>
            </b-form-row>
          <b-form-row>
              <b-col>
                <b-form-group label="Collect Location" :label-for="'collect_location'">
                  <b-form-input
                      :id="'collect_location'"
                      v-model="form.evidences.collect_location"
                      type="text"
                      placeholder="Location where the evidence was collected (be detailed)"
                      autocomplete="off"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Storage Location" :label-for="'storage_location'">
                  <b-form-input
                      :id="'storage_location'"
                      v-model="form.evidences.storage_location"
                      type="text"
                      placeholder="(i.e.: Mission Row Police Department - 3rd Floor Evidence Room)"
                      autocomplete="off"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-form-row>
          <b-form-row>
              <b-col>
                <b-form-group label="Description" :label-for="'description'">
                  <b-form-textarea rows="3" v-model="form.evidences.description" placeholder="Description of the evidence found (can be the /gme)"
                  :id="'description'">
                  </b-form-textarea>
                </b-form-group>
              </b-col>
          </b-form-row>
          <hr>
          <ChainOfCustody @updateCoc="updateCoc" ref="chainOfCustodyForm"></ChainOfCustody>

          <ErrorDisplay :errors="errors" v-if="errors"></ErrorDisplay>

        </b-card-body>
      </b-collapse>
    </b-card>
    <h3 v-if="evidences.length > 0">Evidence associated:</h3>

    <b-card no-body class="evidenceCard" v-for="(evidence,i) in evidences" :key="i">
      <b-card-header>
        <h6 class="card-title d-inline">Evidence #{{(i+1)}} - {{evidence.type}} [{{evidence.identification_number}}]</h6>
        <b-btn variant="outline-primary" class="d-inline float-right" size="sm" v-b-toggle="'evidence-view'+i">Toggle</b-btn>
        <b-btn variant="danger" class="d-inline float-right" size="sm" @click="deleteEvidence(evidence.id,i)">Delete</b-btn>
        <b-btn variant="outline-warning" class="d-inline float-right" size="sm" @click="showEvidenceReport(evidence.identification_number)">Analysis Report</b-btn>
      </b-card-header>
      <b-collapse :id="'evidence-view'+i" accordion="evidence-view" role="tabpanel">
        <b-card-body>
          <ShowEvidence :evidence="evidence" :index="i" @updateEvidence="updateEvidence"></ShowEvidence>
        </b-card-body>
      </b-collapse>

    </b-card>
    <EvidenceReport ref="evidenceReport"></EvidenceReport>

    <b-btn variant="outline-success" class="d-inline float-right">Next ></b-btn>
  </div>
</template>

<script>
import ChainOfCustody from "@/components/Partials/Detectives/FormCaseModal/Evidence/ChainOfCustody";
import DetectiveRepository from "@/api/DetectiveRepository";
import ErrorDisplay from "@/components/Partials/ErrorDisplay";
import ShowEvidence from "@/components/Partials/Detectives/FormCaseModal/Evidence/ShowEvidence";
import EvidenceReport from "@/components/Partials/Detectives/FormCaseModal/Evidence/EvidenceReport";
export default {
  name: "EvidenceForm",
  components: {EvidenceReport, ShowEvidence, ErrorDisplay, ChainOfCustody},
  props: ['caseFileId','caseFile','afterAction'],
  data() {
    return {
      form: {
        evidences: {},
      },
      evidences:[],
      chainOfCustodyNumber:1,
      submiting:false,
      errors: null,
      showForm: true,
      evidence_identification: null,
      isAssociating: false,
      associateError: null,
    }
  },
  computed: {
    unit() {
      return this.$store.state.auth.leoUnit;
    }
  },
  created(){
    this.addNewEvidence();
  },
  mounted(){
    if(this.caseFile){
      this.evidences=this.caseFile.case_files_evidence;
      this.showForm=false;
    } else if(this.afterAction){
      this.evidences=this.afterAction.after_action_report_evidence;
      this.showForm=false;
    }
  },
  methods: {
    deleteEvidence(id,i) {
      DetectiveRepository.deleteEvidenceById(id).then(response => {
        this.evidences.splice(i,1);
      });
    },
    handleProofUpload(evt){
      this.form.evidences.proof=evt.target.files[0];
    },
    updateCoc(coc){
      this.form.evidences.chain_of_custody = coc;
    },
    addNewEvidence(){
      this.form.evidences={
        description:null,
        type:null,
        collect_officer_id:this.unit.id,
        chain_of_custody: null,
        collect_location: null,
        storage_location: null,
        proof: null,
      };

      if(this.caseFile) this.form.evidences.case_files=[this.caseFile.id];
      if(this.afterAction) this.form.evidences.after_action_reports=[this.afterAction.id];
    },
    updateEvidence(index,data){
      this.evidences[index]=data;
    },
    submitEvidence(){
      this.errors=null;
      this.submiting=true;
      DetectiveRepository.createEvidence(this.form.evidences).then(response => {
        this.submiting=false;
        this.addNewEvidence();
        this.$refs.chainOfCustodyForm.resetChain();
        this.evidences.push(response.data);
        this.showForm=false;
      }).catch(error => {
        this.errors=error.response.data.errors;
      });
    },
    associateEvidence(){
      this.isAssociating=true;

      let type = null;
      let id= null;
      if(this.caseFile){
        type = "case_file";
        id = this.caseFile.id;
      }
      else if(this.afterAction){
        type = "after_action";
        id = this.afterAction.id;
      }

      DetectiveRepository.associateEvidence(this.evidence_identification,type,id).then(response => {
        this.evidences.push(response.data);
        this.isAssociating=false;
      }).catch(error => {
        this.isAssociating=false;
        if(error.response.status === 404) this.associateError="Evidence not found";
        else this.associateError="Evidence is already associated with this file.";

        setTimeout(()=>{
          this.associateError=null;
        },5000);
      });
    },
    showEvidenceReport(evidenceId){
      this.$refs['evidenceReport'].show(evidenceId);
    }
  }
}
</script>

<style scoped>
.parentDiv .evidenceCard {
  margin-bottom: 10px;
}

.parentDiv .evidenceCard:last-child {
  margin-bottom: 0;
}
</style>
