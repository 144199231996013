import { render, staticRenderFns } from "./CitationShow.vue?vue&type=template&id=013d2170&scoped=true&"
import script from "./CitationShow.vue?vue&type=script&lang=js&"
export * from "./CitationShow.vue?vue&type=script&lang=js&"
import style0 from "./CitationShow.vue?vue&type=style&index=0&id=013d2170&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "013d2170",
  null
  
)

export default component.exports