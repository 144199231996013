<template>
<div>
  <b-button class="btn btn-block" variant="primary" v-b-modal.live-map-modal>Live Map</b-button>

  <b-modal no-close-on-backdrop id="live-map-modal" size="xl" title="Live Map">
    <Livemap></Livemap>
  </b-modal>
</div>
</template>

<script>
import Livemap from "@/components/Partials/Dispatch/Live Map/Livemap";

export default {
  name: "LivemapModal",
  components: {Livemap}
}
</script>

<style scoped>
  #map {
    max-height: 800px;
  }
</style>
