<template>
  <b-table-lite :items="items" :fields="fields">
    <template #cell(clockin)="data">
      {{new Date(data.value).toLocaleString()}}
    </template>
    <template #cell(clockout)="data">
      {{new Date(data.value).toLocaleString()}}
    </template>
  </b-table-lite>
</template>

<script>
export default {
  name: "ClockinTable",
  props: ['items'],
  data(){
    return {
      fields: [
          'charname',
          'time_on_duty',
          'clockin',
          'clockout'
      ]
    }
  },
}
</script>

<style scoped>

</style>
