<template>
  <div>
      <b-modal id="notepad" size="md" title="Notepad">
        <b-form-textarea
            id="textarea"
            v-model="text"
            placeholder="Enter something..."
            rows="10"
        ></b-form-textarea>
      </b-modal>
  </div>
</template>

<script>
export default {
  name: "Notepad",
  props: ['block', 'type'],
  data() {
  return {
    text: ''
  }

  },
  mounted() {
    let vn=this.variableName();

    let text=localStorage[vn];
    if(text === "undefined") text = "";
    this.text = text;
  },
  watch: {
    text(newText) {
      let vn=this.variableName();

      localStorage[vn]=newText;
    }
  },
  methods : {
    variableName(){
      let name = "notepad";

      if(this.type){
        name+=this.type;
      }

      return name;

    }
  }
}

</script>

<style scoped>
.padding-left{
  padding-left:5px;
}
</style>