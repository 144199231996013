<template>
  <div class="card">
    <div class="card-header bg-warning text-dark">BOLOs</div>
    <div class="card-body">
      <BoloList></BoloList>
    </div>
  </div>
</template>

<script>
import BoloList from "@/components/Partials/LEO/Bolos/BoloList";
export default {
  name: "BOLO",
  components: {BoloList},
};
</script>

<style>
</style>