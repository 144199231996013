<template>
  <b-modal title="Picture" ref="modalPicture" size="xl">
    <b-img v-if="picture_url" :src="picture_url" fluid></b-img>
  </b-modal>
</template>

<script>
export default {
  name: "PicutreModalShow",
  data(){
    return {
      picture_url:null
    }
  },
  methods: {
    openModal(url){
      this.picture_url=url;
      this.$refs['modalPicture'].show();
    }
  }
}
</script>

<style scoped>

</style>