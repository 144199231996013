<template>
  <div>
    <b-row>
      <b-col cols="10">
          <v-select style="color: white!important;" :options="officerNames" v-model="form.officerName" placeholder="Enter officer name"></v-select>
      </b-col>
      <b-col cols="1">
        <b-button variant="primary" @click="submit">Search</b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        Get Times After:
        <b-form-datepicker variant="dark" :min="min" :max="max" v-model="afterDate"></b-form-datepicker>
        <b-form-timepicker v-model="afterTime"></b-form-timepicker>
      </b-col>
      <b-col>
        Get Times Before:
        <b-form-datepicker :min="min" :max="max" v-model="beforeDate"></b-form-datepicker>
        <b-form-timepicker v-model="beforeTime"></b-form-timepicker>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import Repository from "../../../../api/Repository";
import LeoRepository from "../../../../api/LeoRepository";

export default {
  name: "NameSelector",
  data() {
    return {
      officerNames: [],
      beforeDate: new Date(),
      beforeTime: this.getDateTime(new Date()),
      afterDate: new Date('Jan 1 2022'),
      afterTime: '00:00',
      min: new Date('Jan 1 2022'),
      max: new Date(),
      form: {
        startDate: null,
        endDate: null,
        officerName: null,
      }
    }
  },
  methods: {
    submit(){
      let data = {
        startDate: new Date(this.afterDate).toISOString().split('T')[0] + ' ' + this.afterTime,
        endDate: new Date(this.beforeDate).toISOString().split('T')[0] + ' ' + this.beforeTime,
        name: this.form.officerName
      }
      LeoRepository.getClockinTimes(data).then((res)=>{
        console.log(res.data.data)
        this.$emit('submit', res.data.data)
      })
    },
    getDateTime(d){
      return d.toLocaleTimeString([], { hour: '2-digit', minute: "2-digit", hour12: false });
    },
  },
  mounted() {
    LeoRepository.getClockinNames().then((res)=>{
      this.officerNames = res.data.names
    })
  }
}
</script>

<style scoped>
</style>
