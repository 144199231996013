<template>
  <div>
    <h1>Error 404 - not found.</h1>
    <h3> Please contact a CAD Administrator if you believe this is a issue.</h3>
    <img src="https://media.tenor.com/images/eaf5da9c028ba4b0ade96263c86c191e/tenor.gif">
  </div>

</template>

<script>
export default {
  name: 'NotFound',
}
</script>

<style lang="scss">
#NotFound {
  color: white
}
</style>
