<template>
  <div>

    <h5>Chain of custody <b-btn variant="success" class="d-inline float-right" size="sm" @click="addNewRow">+ Add new row</b-btn></h5>



    <div v-for="(coc,k) in chain_of_custody">
      <b-form-row>
        <b-col cols="1" class="font-weight-bold text-center ">
          <h4><b-badge variant="light">#{{ k+1 }}</b-badge></h4>
        </b-col>
        <b-col>
          <b-form-group label="Comments/Location" label-for="comments_locations">
            <b-form-input v-model="coc.comments" @change="dataUpdate"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Date/Time" label-for="date_time">
            <b-form-input v-model="coc.date_time" @change="dataUpdate"></b-form-input>
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col cols="1" class="text-center">
          <b-button variant="danger" size="sm" @click="removeRow(k)">Remove</b-button>
        </b-col>
        <b-col>
          <b-form-group label="Released by" label-for="released_by">
            <b-form-input  v-model="coc.released" @change="dataUpdate" placeholder="Rank, Name, Dept, Identifier"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Received by" label-for="received_by">
            <b-form-input v-model="coc.received" @change="dataUpdate" placeholder="Rank, Name, Dept, Identifier"></b-form-input>
          </b-form-group>
        </b-col>

      </b-form-row>
    </div>

  </div>
</template>

<script>
export default {
  name: "ChainOfCustody",
  props: ['value','coc'],
  data(){
    return {
      chain_of_custody: [
        {
          comments:null,
          received:null,
          released:null,
          date_time:null
        }
      ],
    }
  },
  mounted() {
    if(this.value){
      this.chain_of_custody=this.value;
    }
  },
  methods: {
    addNewRow(){
      this.chain_of_custody.push({
        comments:null,
        received:null,
        released:null,
        date_time:null
      });
    },
    removeRow(index){
      this.chain_of_custody.splice(index,1);
    },
    dataUpdate(){
      this.$emit('updateCoc', this.chain_of_custody);
    },
    resetChain(){
      this.chain_of_custody= [
        {
          comments:null,
          received:null,
          released:null,
          date_time:null
        }
      ];
    }
  }
}
</script>

<style scoped>

</style>
