<template>
  <b-modal id="smartsigns-edit" title="Edit Sign" no-close-on-backdrop size="lg" ref="smartsigns-form-modal" hide-footer>
    <div v-for="k in inputs" class="d-flex">
      <input @click="$event.target.select()" @focus="$event.target.select()" class="character-input text-center" v-for="(i,v) of k" v-model="k[v]" @input="$event.target.nextElementSibling.focus()" maxlength="1">
      <br>
    </div>
<!--    <b-form-group
        id="textset-1"
        label="First line"
        label-for="text-1"
    >
      <b-form-input id="text-1" v-model="form.text[0]" :formatter="formatter"></b-form-input>
    </b-form-group>
    <b-form-group
        id="textset-2"
        label="Second line"
        label-for="text-2"

    >
      <b-form-input id="text-2" v-model="form.text[1]" :formatter="formatter"></b-form-input>
    </b-form-group>
    <b-form-group
        id="textset-3"
        label="Third line"
        label-for="text-3"
    >
      <b-form-input id="text-3" v-model="form.text[2]" :formatter="formatter"></b-form-input>
    </b-form-group>-->
    <div class="pt-2">
      <b-button variant="success" @click="save">Update sign</b-button>
      <b-button variant="danger" class="float-right" @click="reset">Reset</b-button>
    </div>
  </b-modal>
</template>

<script>
import IngameRepository from "../../../../api/IngameRepository";

export default {
  name: "SmartSignsEdit",
  data(){
    return {
      signIds:[],
      inputs: {
        line1: [],
        line2: [],
        line3: []
      },
      form:{
        text:[]
      },
      defaultText:[]
    }
  },
  methods:{
    open(ids,text){
      this.$refs['smartsigns-form-modal'].show();
      this.signIds=ids;
      this.form.text=text;
    },
    save(){
      let text = ["", "", ""]
      let values = Object.values(this.inputs)

      for (const [i, line] of values.entries()) {
        for (const char of line) {
          text[i] += char.toLowerCase() || "•"
        }
      }


      let d={
        ids:this.signIds,
        text:text
      }

      IngameRepository.updateSmartSigns(d).then(()=>{
        this.$refs['smartsigns-form-modal'].hide();
      });
    },
    reset(){
      const characters = 15
      for (const key in this.inputs) {
        this.inputs[key] = Array(characters).fill("")
      }
    }
  },
  mounted() {
    const characters = 15
    for (const key in this.inputs) {
      this.inputs[key] = Array(characters).fill("")
    }
  }
}
</script>

<style>
@font-face {
  font-family: 'Bazaronite';
  src: url('/fonts/Bazaronite/Bazaronite.woff2') format('woff2'),
  url('/fonts/Bazaronite/Bazaronite.woff') format('woff'),
  url('/fonts/Bazaronite/Bazaronite.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


.character-input {
  background-color: black;
  color: yellow;
  width: calc(100% / 15);
  height: 200%;
  font-size: 200%;
  font-family: "Bazaronite",fantasy;
  text-transform: capitalize;
}

</style>
