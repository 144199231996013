<template>

  <div class="parentDiv">

    <b-card no-body class="documentCard">
      <b-card-header>
        <h6 class="card-title d-inline">New Document</h6>
        <b-btn variant="outline-primary" class="d-inline float-right" size="sm" v-b-toggle="'document-form'">Toggle Form</b-btn>
        <b-btn variant="danger" class="d-inline float-right" size="sm" @click="clearForm()">Clear</b-btn>
        <b-btn variant="success" class="d-inline float-right" size="sm" @click="submitDocument">
          <span v-if="submiting">
            <b-spinner type="grow" variant="success" class="align-middle" small></b-spinner>
          </span>
          <span v-else>
            Submit
          </span>
        </b-btn>
      </b-card-header>
      <b-collapse :id="'document-form'" visible accordion="document-form" role="tabpanel">
        <b-card-body>
          <b-form-row>
            <b-col>
              <b-form-group label="Title" :label-for="'title'">
                <b-form-input
                    :id="'title'"
                    v-model="form.document.title"
                    type="text"
                    placeholder="Title of the document"
                    autocomplete="off"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Author">
                <b-form-input
                    v-model="form.document.author"
                    type="text"
                    placeholder="Author of the document (Who created this?)"
                    autocomplete="off"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col>
              <b-form-group label="File URL" :label-for="'file'">
                <b-form-input
                    v-model="form.document.file_url"
                    type="text"
                    placeholder="URL to the File (Google docs or PDF)"
                    autocomplete="off"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Description" :label-for="'description'">
                <b-form-input
                    :id="'description'"
                    v-model="form.document.description"
                    type="text"
                    placeholder="Text description of the document"
                    autocomplete="off"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col>
              <b-form-group label="Certification" :label-for="'certification'">
                <b-form-input
                    :id="'certification'"
                    v-model="form.document.certification"
                    type="text"
                    placeholder="Certification of the document (How do we know this is real?)"
                    autocomplete="off"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Origin" :label-for="'origin'">
                <b-form-input
                    :id="'origin'"
                    v-model="form.document.origin"
                    type="text"
                    placeholder="Where did this document come from?"
                    autocomplete="off"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>
          <ErrorDisplay :errors="errors"></ErrorDisplay>
        </b-card-body>
      </b-collapse>
    </b-card>

    <h3 v-if="documents.length > 0">Documents associated:</h3>

    <b-card no-body class="documentCard" v-for="(document,i) in documents" :key="i">
      <b-card-header>
        <h6 class="card-title d-inline">Document #{{(i+1)}} - {{document.title}}</h6>
        <b-btn variant="outline-primary" class="d-inline float-right" size="sm" v-b-toggle="'document-view'+i">Toggle</b-btn>
        <b-btn variant="danger" class="d-inline float-right" size="sm" @click="deleteDocument(i,document.id)">Clear</b-btn>
      </b-card-header>
      <b-collapse :id="'document-view'+i" accordion="document-view" role="tabpanel">
        <b-card-body>
          <ShowDocument :document="document"></ShowDocument>
        </b-card-body>
      </b-collapse>

    </b-card>
    <b-btn variant="outline-success" class="d-inline float-right">Next ></b-btn>
  </div>
</template>

<script>
import ShowDocument from "./Document/ShowDocument";
import DetectiveRepository from "@/api/DetectiveRepository";
import ErrorDisplay from "@/components/Partials/ErrorDisplay";
export default {
  name: "DocumentForm",
  components: {ErrorDisplay, ShowDocument},
  props: ['caseFile','afterAction'],
  data(){
    return {
      form: {
        document: {
          file_url: null,
          description: null,
          title: null,
          origin: null,
          author: null,
          certification: null,
        }
      },
      documents: [],
      errors: null,
      submiting: false,
      showForm: true,
    }
  },
  mounted() {
    if(this.caseFile){
      this.documents=this.caseFile.case_file_documents;
      this.form.document.case_files=[this.caseFile.id];
    } else if(this.afterAction){
      this.documents=this.afterAction.after_action_report_documents;
      this.form.document.after_action_reports=[this.afterAction.id];
    }
  },
  methods: {
    clearForm() {
      for (let key in this.form.document) {
        this.form.document[key] = null;
      }
      this.form.case_files=[this.caseFile.id];
    },
    deleteDocument(i,id) {
      DetectiveRepository.deleteDocument(id).then((response) => {
        this.documents.splice(i, 1);
      });
    },
    submitDocument() {
      if(this.submiting) return false;
      this.submiting=true;
      this.errors=null;
      DetectiveRepository.createDocument(this.form.document).then((response) => {
        this.submiting=false;
        this.clearForm();
        this.documents.push(response.data.data);
        this.showForm=false;
      }).catch((error) => {
        console.log("doc err");
        console.log(error);
        this.errors=error.response.data.errors;
        this.submiting=false;
      });
    }
  },

}
</script>

<style scoped>
.parentDiv .documentCard {
  margin-bottom: 10px;
}

.parentDiv .documentCard:last-child {
  margin-bottom: 0;
}
</style>
