<template>
  <b-modal no-close-on-backdrop size="xl" id="information-panel" hide-footer title="Information Panel">
    <div class="row">
      <div class="col-md-6">
        <h5>Penal Codes <b-button variant="info" v-b-modal="'iframeEmbed'">Penal Code Document</b-button></h5>
        <b-modal id="iframeEmbed" size="xl"  no-close-on-backdrop hide-footer>
          <b-embed type="iframe" src="https://docs.google.com/document/d/e/2PACX-1vTVIp3x6XulC7m7jYlpfrInVrQ4OokxTkcUMT8S4sBjIswpYlMAmdtGA5TZ9QMfxjWyPsSBm7P6OeVM/pub?embedded=true"></b-embed>
        </b-modal>
        <b-form-input v-model="search" placeholder="Search..." class="mb-2" autocomplete="off"></b-form-input>
        <b-list-group class="list-limit">
          <b-list-group-item v-for="p in penalCodes" :key="p.id">{{ p.code }} - {{ p.title }}</b-list-group-item>
        </b-list-group>
      </div>
      <div class="col-md-6">
        <h5>10 Codes</h5>
        <b-list-group class="list-limit mt-5">
          <b-list-group-item v-for="c in ten_codes" :key="c.id">{{ c.code }}</b-list-group-item>
        </b-list-group>
      </div>
    </div>
  </b-modal>
</template>

<script>
import DataRepository from "@/api/DataRepository";

export default {
  name: "InformationPanel",
  data(){
    return {
      penal_codes:null,
      ten_codes:null,
      search:null
    }
  },
  computed: {
    penalCodes: function(){
      if(this.search === null) return this.penal_codes;
      return this.penal_codes.filter(pc => {
        return pc.code.toLowerCase().includes(this.search.toLowerCase())
            || pc.title.toLowerCase().includes(this.search.toLowerCase())
      })
    }
  },
  methods: {
    sortTenCodes(a, b){
      let re = /1[01]-([0-9]+)/;
      if (parseInt(a.code.match(re)[1]) > parseInt(b.code.match(re)[1])) return 1;
      return -1;
    }
  },
  mounted() {
    DataRepository.getTenCodes().then(response => {
      this.ten_codes=response.data.data;
      this.ten_codes = this.ten_codes.sort(this.sortTenCodes);
    });

    DataRepository.getPenalCodes().then(response => {
      this.penal_codes=response.data.data;
    });
  }
}
</script>

<style scoped>
.list-limit {
  max-height: 70vh;
  overflow: auto;
}
</style>