<template>
  <div>
    <b-form-row>
      <b-col cols="3">
        <div>Title</div>
        {{ document.title }}
      </b-col>
      <b-col cols="3">
        <div>Author</div>
        {{ document.author }}
      </b-col>
      <b-col cols="3">
        <div>Certification</div>
        {{ document.certification }}
      </b-col>
      <b-col cols="3">
        <div>Origin</div>
        {{ document.origin }}
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col cols="2">
        <div>File</div>
        <a :href="document.file_url" target="_blank">Click to open</a>
      </b-col>
      <b-col>
        <div>Description</div>
        {{ document.description }}
      </b-col>
    </b-form-row>
  </div>
</template>

<script>
export default {
  name: "ShowDocument",
  props: ['document'],
  methods: {
  }
}
</script>

<style scoped>
.form-row div[class*="col"] div:first-child {
  font-weight: bolder;
}
</style>
