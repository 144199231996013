<template>
  <b-modal v-if="warrantData" no-close-on-backdrop id="modal-warrant-show" ref="warrantModal" size="lg" :title="`Warrant Information - #`+warrantData.id" >

    <h6>Civilian Information</h6>
    <hr>

    <b-form-row>
      <b-col cols="4">
        <div class="fw-bold font-weight-bolder dd-title">Full Name</div>
        {{ warrantData.civilian.name }}
      </b-col>

      <b-col cols="4">
        <div class="fw-bold font-weight-bolder dd-title">DOB</div>
        {{ warrantData.civilian.dob }}
      </b-col>

      <b-col cols="4">
        <div class="fw-bold font-weight-bolder dd-title">Gender</div>
        {{ warrantData.civilian.gender.name }}
      </b-col>
    </b-form-row>

    <b-form-row>
      <b-col cols="4">
        <div class="fw-bold font-weight-bolder dd-title">Address</div>
        {{ warrantData.civilian.address }}
      </b-col>

      <b-col cols="4">
        <div class="fw-bold font-weight-bolder dd-title">Postal</div>
        {{ warrantData.civilian.postal }}
      </b-col>

      <b-col cols="4">
        <div class="fw-bold font-weight-bolder dd-title">Hair Color</div>
        {{ warrantData.civilian.hair_color }}
      </b-col>
    </b-form-row>
    <hr>

    <h6>Charges</h6>
    <hr>

    <template v-for="(charge,index) in warrantData.charges">
      <b-form-row>
        <b-col cols="2" class="text-center">#{{ (index+1) }}</b-col>
        <b-col cols="10">
          <b-form-row>
            <b-col cols="6">
              <div class="fw-bold font-weight-bolder dd-title">Charge</div>
              {{ charge.title }}
            </b-col>

            <b-col cols="3">
              <div class="fw-bold font-weight-bolder dd-title">Type</div>
              {{ charge.pivot.type }}
            </b-col>

            <b-col cols="3">
              <div class="fw-bold font-weight-bolder dd-title">Counts</div>
              {{ charge.pivot.counts }}
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col cols="6">
              <div class="fw-bold font-weight-bolder dd-title">Code</div>
              {{ charge.code }}
            </b-col>
            <b-col cols="3">
              <div class="fw-bold font-weight-bolder dd-title">Fine Value</div>
              {{ charge.pivot.fine_value }}
            </b-col>
            <b-col cols="3">
              <div class="fw-bold font-weight-bolder dd-title">Jail Time</div>
              {{ charge.pivot.jail_time }}
            </b-col>
          </b-form-row>
        </b-col>
      </b-form-row>
      <hr>
    </template>


    <h6>Warrant Information</h6>
    <hr>

    <b-form-row>
      <b-col cols="12">
        <div class="fw-bold font-weight-bolder dd-title">Warrant Type</div>
        {{ warrantData.warrant_type }}
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col cols="12" class="narrative">
        <div class="fw-bold font-weight-bolder dd-title">Narrative</div>
        {{ warrantData.narrative }}
      </b-col>
    </b-form-row>
    <hr>

    <h6>Filling Officer</h6>
    <hr>

    <b-form-row>
      <b-col cols="4">
        <div class="fw-bold font-weight-bolder dd-title">Identifier</div>
        {{ warrantData.officer.identifier }}
      </b-col>

      <b-col cols="4">
        <div class="fw-bold font-weight-bolder dd-title">Officer</div>
        {{ warrantData.officer.rank }} {{ warrantData.officer.name }}
      </b-col>

      <b-col cols="4">
        <div class="fw-bold font-weight-bolder dd-title">Agency</div>
        {{ warrantData.officer.agency.short_name }}
      </b-col>

      <b-col cols="4">
        <div class="fw-bold font-weight-bolder dd-title">Date/Time</div>
        {{ warrantData.date_time }}
      </b-col>
    </b-form-row>

    <template #modal-footer="">
      <b-button variant="success" v-if="doesUserHavePermission('doj_access') && warrantData.status !== 'approved'" @click="approveWarrant(warrantData,index)">Approve</b-button>
      <b-button variant="danger" @click="deleteWarrant()" v-if="doesUserHavePermission('warrant_delete')">
        Delete
      </b-button>
    </template>

  </b-modal>
</template>

<script>
import LeoRepository from "@/api/LeoRepository";

export default {
  name: "WarrantShow",
  props: ['warrant'],
  data(){
    return {
      warrantData: null,
    }
  },
  mounted() {
    if(this.warrant){
      this.warrantData=this.warrant;
    }
  },
  methods:{
    openWarrant(id){
      LeoRepository.getWarrant(id).then( response => {
        this.warrantData=response.data.data;
            this.$nextTick(() => {
              this.$refs['warrantModal'].show();
            });

      });
    },
    deleteWarrant(){
      LeoRepository.deleteWarrant(this.warrant.id).then(response => {
        this.bolo=null;
        this.$root.$emit('bv::hide::modal', 'modal-warrant-show')
      }).catch(error => {

      });
    },

    approveWarrant(warrant,index){
      let data=warrant;
      data.status="approved";
      LeoRepository.editWarrants(warrant.id,data).then(r => {
        this.warrants[index].status="approved";
      });
    },

  }
}
</script>

<style scoped>
.narrative {
  white-space: break-spaces;
}
</style>