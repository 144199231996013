<template>
  <div class="col-md-12">
    <div class="card card-container" style="background-color: #212529">
      <img
          id="profile-img"
          src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
          class="profile-img-card"
      />

      <div class="row" style="padding-top: 20px; padding-bottom: 10px"></div>

      <div class="form-group">
        <button class="btn btn-primary btn-block" :disabled="loading" @click="startDiscordLogin">
          <span v-show="loading" class="spinner-border spinner-border-sm"></span>
          <span>Login with Discord</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Login',
  data() {
    return {
      user: {
        email: null,
        password: null
      },
      loading: false,
      message: ''
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    }
  },
  created() {
    if (this.loggedIn) {
      this.$router.push('/profile');
    }

    let eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
    let eventer = window[eventMethod];
    let messageEvent = eventMethod == "attachEvent" ? "onmessage" : "message";

    // Listen to message from child window
    eventer(messageEvent,e => {

      if(typeof e.data.access_token !== "undefined" && e.data.access_token !== null){
        this.$store.dispatch('auth/loginDiscord', e.data).then(
            () => {
              this.$router.go('/profile');
            },
            error => {
              this.loading = false;
              this.message =error.response.data.message;
            }
        );
      }

    }, false);
  },
  methods: {
    handleLogin() {
      this.loading = true;
      if (this.user.email && this.user.password) {
        this.$store.dispatch('auth/login', this.user).then(
            () => {
              this.$router.push('/profile');
            },
            error => {
              this.loading = false;
              this.message =error.response.data.message;
            }
        );
      }
    },

    startDiscordLogin(){
      this.loading=true;
      window.open (this.$baseDomain + "/api/v1/login/discord/","mywindow", "width=350,height=650");
    }
  }
};
</script>

<style scoped>
label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
</style>
