<template>

  <div>
    <ul class="list-group list-group-flush">
      <div class="text-center">
        <b-spinner class="align-middle" variant="primary" label="Loading..." v-if="loading"></b-spinner>
      </div>
      <li class="list-group-item bolo-list-item" v-for="bolo in boloList" v-on:click="selectBolo(bolo)">


        <template v-if="bolo.civilian!==null"><b>Name:</b> {{ bolo.civilian.name }}<br></template>
        <template v-if="bolo.plate!==null"><b>Plate:</b> {{ bolo.plate }}<br></template>
        {{ bolo.description }}
      </li>
    </ul>

    <BoloDetails :bolo-select="boloSelect" v-if="boloSelect!==null"></BoloDetails>
  </div>


</template>

<script>
import LeoRepository from "@/api/LeoRepository";
import BoloDetails from "@/components/Partials/LEO/Bolos/BoloDetails";

export default {
  name: "BoloList",
  components: {BoloDetails},
  prop: ['all'],
  data(){
    return {
      boloList:null,
      loading: true,
      boloSelect: null
    }
  },
  created(){
    this.updateBolos();
  },
  methods: {
    selectBolo(bolo){
      this.boloSelect=bolo;
      setTimeout(() => this.$root.$emit('bv::show::modal', 'bolo-modal'), 100);
    },
    updateBolos(){
      LeoRepository.getBolos(null,null).then(response => {
        this.boloList=response.data.data;
        this.loading=false;
      });
    }
  },
  sockets: {
    updateBoloList: function(){
      this.updateBolos();
    }
  }
}
</script>

<style scoped>
  .bolo-list-item {
    cursor: pointer;
  }
</style>