 <template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <h5 class="text-white" style="display:inline">Active Units</h5>
        <b-button
          v-b-modal.filter-modal
          variant="primary"
          size="sm"
          style="display:inline"
          >Filter</b-button>
      </div>
    </div>

    <b-modal
      no-close-on-backdrop
      id="filter-modal"
      size="sm"
      title="Filter Units"
      ok-title="Filter"
    >
      <div class="row">
        <div class="col-md-12">
          <b-form-group label="Department(s)" label-for="department">
            <select
              id="departments"
              v-model="filters.departments"
              class="form-control"
              multiple
              :size="departments.length"
            >
              <option v-for="dept in departments" :value="dept" v-bind:key="dept.id">
                {{ dept.full_name }}
              </option>
            </select>
          </b-form-group>
        </div>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="primary" @click="ok()"> OK </b-button>
        <b-button size="sm" variant="secondary" @click="cancel()">
          Cancel
        </b-button>
        <!-- Button with custom close trigger value -->
        <b-button size="sm" variant="danger" @click="resetFilters">
          Reset
        </b-button>
      </template>
    </b-modal>


<div class="table-responsive table-wrapper-scroll-y my-custom-scrollbar">

      <div class="my-custom-scrollbar table-wrapper-scroll-y">
        <table class="table-hover table table-striped table-dark table-sm table-bg-darker">
          <thead class="thead-light">
          <tr>
            <th scope="col">Identifier</th>
            <th scope="col">Radio</th>
            <th scope="col">Rank</th>
            <th scope="col">Status</th>
            <th scope="col">Quick Actions</th>
          </tr>
          </thead>
          <tbody>
          <tr v-if="loading">
            <td colspan="4" class="text-center">
              <b-spinner class="align-middle" variant="primary" label="Loading Units..."></b-spinner>
            </td>
          </tr>
            <template v-for="(unit,index) in units">
          <tr v-bind:key="unit.id" draggable="true" @dragstart="startDraggingToAttach($event, unit)" @click="toggleUnitDropdown($event,index)">
            <td><span v-b-tooltip.hover.right :title="'['+unit.agency.short_name+'] '+unit.rank+' '+unit.name"><img :src='unit.agency.image_json.url' style="width:25px;height:25px;" /> {{ unit.identifier }} <b-badge variant="success" v-if="rxUnit == unit.id">* Rx</b-badge></span></td>
            <td><small>{{unit.currentChannel}}</small></td>
            <td><small>{{unit.rank}}</small></td>
            <td :style="{color: unit.current_status.color, fontWeight: 'bolder'}">{{  unit.current_status.code }} <b-badge variant="warning" v-if="unit.call_id">On call</b-badge></td>
            <td>
              <b-button-group v-on:click.stop>
              <b-button size="sm" class="bg-status-10-11" v-if="(unit.current_status.code==='10-8' && !unit.call_id)" v-on:click="createAndAttachTrafficStop(unit)">Create 10-11</b-button>
              <b-button size="sm" variant="success" v-if="(unit.current_status.code==='10-8' && !unit.call_id)" v-on:click="createAndAttachCall(unit)">Create Call</b-button>
              <b-button size="sm" class="bg-status-10-23" v-if="unit.current_status.code==='10-97'" v-on:click="updateUnitStatus(unit,singleStatus('10-23'))">10-23</b-button>
              <b-button size="sm" class="bg-status-10-8" v-if="unit.current_status.code==='10-6'" v-on:click="updateUnitStatus(unit,singleStatus('10-8'))">10-8</b-button>
              <b-button size="sm" class="bg-status-10-8" v-if="((unit.current_status.code==='10-23' || unit.current_status.code==='10-11') && unit.call_id)" v-on:click="clearAndFree(unit)">Detach &amp; 10-8</b-button>
              <b-button size="sm" class="bg-status-10-8" v-if="((unit.current_status.code==='10-23' || unit.current_status.code==='10-11') && !unit.call_id)" v-on:click="updateUnitStatus(unit,singleStatus('10-8'))">10-8</b-button>
              <b-button size="sm" class="bg-status-10-15" v-if="(unit.current_status.code==='10-23' || unit.current_status.code==='10-11')" v-on:click="updateUnitStatus(unit,singleStatus('10-15'))">10-15</b-button>
              <b-button size="sm" class="bg-status-10-97" v-if="(unit.current_status.code==='10-8' && unit.call_id)" v-on:click="updateUnitStatus(unit,singleStatus('10-97'))">10-97</b-button>
              </b-button-group>
            </td>
            <div v-if="noctx !== true">
              <ContextMenu ref="unitContext" :data-index="index">
                <b-dropdown-item href="#" v-for="status in statuses" v-if="statuses!==null" :key="status.id" @click="updateUnitStatus(unit,status)" ><span :style="{color: status.color}">{{ status.code }}</span></b-dropdown-item>
                <hr>
                <b-dropdown-item href="#" v-if="(doesUserHavePermission('unit_edit') || unit.created_by === user.id)" v-on:click="editUnitForm(unit)">Edit unit</b-dropdown-item>
                <b-dropdown-item href="#" v-if="unit.call_id" @click="detachFromCurrentCall(unit)">Detach from call</b-dropdown-item>
              </ContextMenu>
            </div>
          </tr>
          <!-- <tr v-bind:key="unit.id" v-if="unitNotes(unit)">
            <td colspan="5">{{unitNotes(unit)[2]}}</td></tr> -->
          </template>
          </tbody>
        </table>
      </div>
    </div>
    <UnitForm :unit="editUnit" @updateUnitsList="updateUnitList"></UnitForm>
    <ManageCall ref="callManagerUnitList"></ManageCall>
  </div>
</template>

<script>
import LeoRepository from "@/api/LeoRepository";
import UnitForm from "@/components/Partials/LEO/Units/UnitForm";
import DispatchRepository from "@/api/DispatchRepository";
import ContextMenu from "@/components/Partials/Dispatch/ContextMenu";
import DataRepository from "@/api/DataRepository";
import ManageCall from "@/components/Partials/Dispatch/ManageCall";
import newPostals from '@/assets/new-postals.json';

export default {
  name: "UnitList",
  components: { ContextMenu, UnitForm, ManageCall },
  props: ["button_name", "noctx", "notes"],
  data() {
    return {
      unitList: null,
      loading: true,
      editUnit: null,
      statuses: null,
      filters: {
        departments: null,
      },
      showFilter: false,
      departments: [],
      dispatch: 1,
      notesRegex: /(.+)\[(.+)\]\s/g,
      rxUnit: 0,
    };
  },
  computed: {
    units() {
      if (this.unitList === null) return null;

      let ul = this.unitList;

      if (this.filters.departments !== null) {
        ul = this.unitList.filter((unit) => {
          return this.filters.departments.some((el) =>
            unit.agency.short_name.includes(el.short_name)
          );
        });
      }

      return ul;
    },
    user() {
      return this.$store.state.auth.user;
    },
    // parsedNotes() {
    //   return [...this.notes.matchAll(this.notesRegex)]
    // }
  },
  created() {
    this.loading=true;
    this.updateUnitList();
    DataRepository.getUnitStatus().then((response) => {
      this.statuses = response.data.data;
    });

    DataRepository.getDeparments().then((response) => {
      this.departments = response.data.data;
    });
  },
  mounted() {
    this.$root.$on('radioRx', unitId => {
        this.units.some((unit, idx) => {
          if (unit.id == unitId) {
            this.rxUnit = unitId;
            return true;
          }
      });
    });

    this.$root.$on('radioRxEnd', unitId => {
        this.units.some((unit, idx) => {
          if (unit.id == unitId) {
            this.rxUnit = 0;
            return true;
          }
      });
    });

    this.$root.$on('updateSubscriberChannel', data => {
        this.unitList.some((unit, idx) => {
          if (unit.ingame_id == data.id) {
            this.unitList[idx].currentChannel = data.channel;
            this.$forceUpdate();
          }
      });
    });
  },
  methods: {
    onClickUnitButton: function (unit) {
      this.$emit("clickedUnit", unit);
    },
    updateUnitList: function () {
      LeoRepository.getUnits(1).then((response) => {
        this.unitList = response.data.data;
        this.loading = false;
      });
    },
    editUnitForm(unit) {
      this.editUnit = unit;
      setTimeout(
        () => this.$root.$emit("bv::show::modal", "unit-identifier-form-modal"),
        100
      );
    },
    setCreateMode() {
      this.editUnit = null;
      setTimeout(
        () => this.$root.$emit("bv::show::modal", "unit-identifier-form-modal"),
        100
      );
    },
    startDraggingToAttach(evt, unit) {
      evt.dataTransfer.dropEffect = "move";
      evt.dataTransfer.effectAllowed = "move";
      evt.dataTransfer.setData("unit_id", unit.id);
    },
    detachFromCurrentCall(unit) {
      DispatchRepository.changeUnitsCall("detach", unit.call_id, [unit.id]);
    },
    updateUnitStatus(unit, status) {
      LeoRepository.updateUnitStatus(unit.id, status).then(r => {
        this.$emit('updateDispatch');
      });
    },
    toggleUnitDropdown(event, index) {
      const dropdown = this.$refs["unitContext"].find(
        (el) => el.$attrs["data-index"] === index
      );

      this.$refs["unitContext"].forEach((e) => {
        if (e.$attrs["data-index"] !== index) e.close();
      });

      dropdown.toggle(event);
    },

    resetFilters() {
      this.filters.departments = null;
    },
    singleStatus(code) {
      return this.statuses.find(s => {
        return s.code === code;
      })
    },
    nearestPostal(x, y) {
      let currentLocation = {x: x, y: y};
      let closest = newPostals.reduce((a, b) => this.distance(currentLocation, a) < this.distance(currentLocation, b) ? a : b);
      return closest.code;
    },
    distance(p1, p2) {
        return Math.sqrt(Math.pow(p1.x - p2.x, 2) + Math.pow(p1.y - p2.y, 2))
    },
    createAndAttachTrafficStop(unit) {
      let callToSend = {};
      callToSend.call_origin='radio';
      callToSend.call_status='active';
      callToSend.priority=2;
      callToSend.address=unit.street
      callToSend.postal=this.nearestPostal(unit.x, unit.y);
      callToSend.title='Traffic Stop';
      callToSend.description='';
      callToSend.notes='';
      callToSend.code = {};
      callToSend.code.id=43;
      callToSend.x=unit.x;
      callToSend.y=unit.y;
      callToSend.z=unit.z;
      callToSend.id = null;

      this.$refs['callManagerUnitList'].openCallEdit(callToSend, unit, this.singleStatus('10-11'));

    },
    createAndAttachCall(unit) {
      let callToSend = {};
      callToSend.call_origin='radio';
      callToSend.call_status='active';
      callToSend.priority=2;
      callToSend.address=unit.street
      callToSend.postal=this.nearestPostal(unit.x, unit.y);
      callToSend.title=null;
      callToSend.description='Called in by ' + unit.identifier + ' - ';
      callToSend.notes='';
      callToSend.code = {};
      callToSend.code.id=null;
      callToSend.x=unit.x;
      callToSend.y=unit.y;
      callToSend.z=unit.z;
      callToSend.id = null;

      this.$refs['callManagerUnitList'].openCallEdit(callToSend, unit, this.singleStatus('10-23'));

    },
    clearAndFree(unit) {
      this.updateUnitStatus(unit, this.singleStatus('10-8'));
      this.detachFromCurrentCall(unit);
      setTimeout(this.updateUnitList, 100);
    },
    unitNotes(unit){
      return this.parsedNotes.find(s => {
        return s[1] === unit.identifier
      });
    }
  },
  sockets: {
    updateUnitsList: function (data) {
      if (data.length > 0 || typeof data.id !== "undefined") {
        let d = this.unitList.findIndex((item) => item.id === data.id);
        if (d === -1) {
          if (
            data.current_status_id !== 2 &&
            (this.dispatch === 1 || data.created_by_id === this.user.id)
          )
            this.unitList.push(data);
        } else {
          if (data.current_status_id === 2) {
            this.unitList.splice(d, 1);
          } else {
            this.$set(this.unitList, d, data);
          }
        }
      }
    },
  },
};
</script>

<style scoped>
.my-custom-scrollbar {
  max-width: 100%;
  max-height: 800px;
  overflow: auto;
}
.table-wrapper-scroll-y {
  display: block;
}
.table-bg-darker {
  background-color: #212529;
}

</style>