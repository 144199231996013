<template>
  <div>
    <b-button class="btn btn-block" variant="primary" v-b-modal.weapon-form-modal @show="()=>{this.errors=null}" @click="newWeapon">Register New Weapon</b-button>

    <b-modal no-close-on-backdrop id="weapon-form-modal" size="md" title="Weapon Registration" ref="newFirearmModal">
      <b-form-row>
        <b-col>
          <b-form-group
              label="Model"
              label-for="model"
          >
            <b-form-input
                id="model"
                v-model="form.weapon_model"
                autocomplete="off"
                type="text"
                required
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col>
          <b-form-group
              label="Type"
              label-for="type"
          >
            <b-form-input
                id="type"
                v-model="form.weapon_type"
                autocomplete="off"
                type="text"
                required
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-form-row>
      <ErrorDisplay :errors="errors"></ErrorDisplay>

      <template #modal-footer>
        <div class="w-100 text-right">

          <template v-if="weapon===null">
            <b-button
                variant="success"
                size="sm"
                @click="createWeapon"
            >
              Create
            </b-button>
          </template>
          <template v-if="weapon!==null">
            <b-button
                variant="success"
                size="sm"
                @click="updateWeapon"
            >
              Update
            </b-button>
            <b-button
                variant="danger"
                size="sm"
                @click="deleteWeapon"
            >
              Delete
            </b-button>
          </template>

          <b-button
              variant="secondary"
              size="sm"
              @click="$refs['newFirearmModal'].hide"
          >
            Close
          </b-button>
        </div>
      </template>

    </b-modal>
  </div>
</template>

<script>
import CitizenRepository from "@/api/CitizenRepository";
import ErrorDisplay from "@/components/Partials/ErrorDisplay";

export default {
  name: "NewWeapon",
  components: {ErrorDisplay},
  props:['civilian'],
  data: function () {
    return {
      form: {
        owner_id: null,
        weapon_model: null,
        weapon_type: null,
      },
      weapon:null,
      errors: null,
      submiting: false,
    }
  },
  created() {
    this.form.owner_id=this.civilian.id;
  },
  methods: {
    newWeapon: function(){
      this.weapon=null;
      this.form.weapon_model=null;
      this.form.weapon_type=null;
    },

    openUpdateWeapon: function(weapon){
      this.weapon=weapon;
      this.form.weapon_model=weapon.weapon_model;
      this.form.weapon_type=weapon.weapon_type;
      this.$refs['newFirearmModal'].show();
    },

    createWeapon: function(){
      this.submiting=true;
      CitizenRepository.createCitizenWeapon(this.form).then(response => {
        this.submiting=false;
        this.$refs['newFirearmModal'].hide();
        this.$emit('updateCivilianData');
      }).catch(error => {
        this.errors=error.response.data.errors;
      });
    },

    updateWeapon: function(){
      this.submiting=true;
      CitizenRepository.updateCitizenWeapon(this.form,this.weapon.id).then(response => {
        this.submiting=false;
        this.$refs['newFirearmModal'].hide();
        this.$emit('updateCivilianData');
      }).catch(error => {
        this.errors=error.response.data.errors;
      });
    },

    deleteWeapon: function(){
      CitizenRepository.deleteCitizenWeapon(this.weapon.id).then(response => {
        this.$refs['newFirearmModal'].hide();
        this.$emit('updateCivilianData');
      }).catch(error => {
        this.errors=error.response.data.errors;
      });
    }
  }
}
</script>

<style scoped>

</style>