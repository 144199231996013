 <template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <h5 class="text-white" style="display:inline">Radio</h5>
        <b-button v-b-modal.radio-filter-modal variant="primary" size="sm" style="display:inline">Filter</b-button>
      </div>
    </div>

    <b-modal no-close-on-backdrop id="radio-filter-modal" size="sm" title="Filter Channels" ok-title="Filter">
      <div class="row">
        <div class="col-md-12">
          <b-form-group label="Department(s)" label-for="department">
            <select id="departments" v-model="filters.departments" class="form-control" multiple
              :size="departments.length">
              <option v-for="dept in departments" :value="dept" v-bind:key="dept.id">
                {{ dept.full_name }}
              </option>
            </select>
          </b-form-group>
        </div>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="primary" @click="ok()"> OK </b-button>
        <b-button size="sm" variant="secondary" @click="cancel()">
          Cancel
        </b-button>
        <!-- Button with custom close trigger value -->
        <b-button size="sm" variant="danger" @click="resetRadioFilters">
          Reset
        </b-button>
      </template>
    </b-modal>

    <div class="table-responsive table-wrapper-scroll-y my-custom-scrollbar">

      <div class="my-custom-scrollbar table-wrapper-scroll-y">
        <table class="table-hover table table-striped table-dark table-sm table-bg-darker">
          <thead class="thead-light">
            <tr>
              <th scope="col">Channel</th>
              <th scope="col">Status</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(channel, idx) in channels">
              <tr v-bind:class="{ 'red': channel.emg != 0 }">
                <td><span>{{ channel.group }} {{ channel.name }}</span></td>
                <td><span style="font-family:'Courier New', Courier, monospace">{{ channel.status }}</span></td>
                <td>
                  <b-button-group v-on:click.stop>
                    <b-button size="sm" variant="primary" v-on:click="toggleMonitor(idx)" v-if="!channel.monitored">
                      ENABLE</b-button>
                    <b-button size="sm" variant="danger" v-on:click="toggleMonitor(idx)" v-if="channel.monitored">
                      DISABLE</b-button>
                    <b-button size="sm" variant="primary" v-bind:disabled="!channel.monitored"
                      v-on:click="clearEmergency(idx)">CL/EMG</b-button>
                    <b-dropdown size="sm" variant="primary" v-bind:disabled="!channel.monitored"
                      v-on:click="playTone(idx)" right split v-bind:text="channel.toneText">
                      <b-dropdown-item v-for="tone in channelTones" v-bind:key="tone.toneID"
                        v-on:click="setTone(idx, tone)" variant="white">{{ tone.toneText }}</b-dropdown-item>
                    </b-dropdown>
                  </b-button-group>
                  <b-button v-bind:id="'ptt-' + channel.tgID" size="sm" variant="success" @mousedown.left="startPTT(idx)"
                    @mouseup.left="stopPTT(idx)" @click.middle="setPrimary(idx)" v-bind:disabled="!channel.monitored"
                    v-bind:class="{ 'red': channel.ptt, 'primary-channel': channel.primary }">
                    &nbsp;&nbsp;&nbsp;PTT&nbsp;&nbsp;&nbsp;</b-button>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>

import DataRepository from "@/api/DataRepository";
import LeoRepository from "@/api/LeoRepository";
import WebRadio from "./radio/webradio";
import Voice from "./radio/voice";
import io from "socket.io-client-legacy";
import { Helper } from "./radio/webradio";


export default {
  name: "RadioPanel",
  components: {},
  props: [],
  data() {
    return {
      filters: {
        departments: null,
      },
      showFilter: false,
      departments: [],
      dispatch: 1,
      radioSocket: null,
      channelList: null,
      unitList: null,
      primaryChannel: null,
      currentSubscriberID: 3001,
      channelTones: [
        {
          toneIcon: "SOLID",
          toneText: "ATTN",
          toneID: "A"
        },
        {
          toneIcon: "INTERVAL",
          toneText: "ALRT",
          toneID: "B"
        },
        {
          toneIcon: "WAVERING",
          toneText: "EVAC",
          toneID: "C"
        }
      ],
    };
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
    channels() {
      let channelList = this.channelList
      if (this.filters.departments !== null) {
        channelList = this.channelList.filter((channel) => {
          return this.filters.departments.some((el) =>
            channel.group.includes(el.short_name)
          );
        });
      }

      return channelList;
    },

  },
  created() {
    DataRepository.getDeparments().then((response) => {
      this.departments = response.data.data;
    });
    this.radioSocket = io("wss://main-1.cc.p25.scncomms.app");

    setInterval(this.updateUnitList, 10000);

    window.addEventListener("keydown", this.keyboardListener);
    window.addEventListener("keyup", this.keyboardListener);

    this.channelList = [
      this.setupChannel(90101, "DISP", "482F7A37221ECB1C02A87FE251F027E1", "LSPD", "pv123"),
      this.setupChannel(90111, "TAC 1", "482F7A37221ECB1C02A87FE251F027E1", "LSPD", "pv123"),
      this.setupChannel(90112, "TAC 2", "482F7A37221ECB1C02A87FE251F027E1", "LSPD", "pv123"),
      this.setupChannel(90301, "DISP", "482F7A37221ECB1C02A87FE251F027E1", "SAHP", "pv123"),
      this.setupChannel(90311, "TAC 1", "482F7A37221ECB1C02A87FE251F027E1", "SAHP", "pv123"),
      this.setupChannel(90312, "TAC 2", "482F7A37221ECB1C02A87FE251F027E1", "SAHP", "pv123"),
      this.setupChannel(90201, "DISP", "482F7A37221ECB1C02A87FE251F027E1", "LSSD", "pv123"),
      this.setupChannel(90211, "TAC 1", "482F7A37221ECB1C02A87FE251F027E1", "LSSD", "pv123"),
      this.setupChannel(90212, "TAC 2", "482F7A37221ECB1C02A87FE251F027E1", "LSSD", "pv123"),
      this.setupChannel(91001, "DISP", "482F7A37221ECB1C02A87FE251F027E1", "LSCoFD", "pv123"),
      this.setupChannel(91011, "TAC 1", "482F7A37221ECB1C02A87FE251F027E1", "LSCoFD", "pv123"),
      this.setupChannel(91012, "TAC 2", "482F7A37221ECB1C02A87FE251F027E1", "LSCoFD", "pv123"),
    ];
  },
  destroyed() {
    window.removeEventListener("keydown", this.keyboardListener);
    window.removeEventListener("keyup", this.keyboardListener);
  },
  methods: {
    updateUnitList: function () {
      LeoRepository.getUnits(1).then((response) => {
        this.unitList = response.data.data;
      });
    },
    async keyboardListener(event) {
      if (event.key == "F13") {
        if (event.type == "keydown") {
          if (!this.channelList[this.primaryChannel].ptt) {
            this.startPTT(this.primaryChannel)
          }
        } else if (event.type == "keyup") {
          this.stopPTT(this.primaryChannel)
        }
      }
    },
    resetRadioFilters() {
      this.filters.departments = null;
    },
    async eventCallback(eventData, idx) {
      if (eventData.id === "rx") {
        this.channelList[idx].rx = true;
        let unitName = eventData.subscriber;
        let unitId = null;
        if (eventData.subscriber > 9000) {
          this.unitList.some((el) => {
            if (el.ingame_id == eventData.subscriber - 9000) {
              unitName = el.identifier;
              unitId = el.id;
              this.$root.$emit('radioRx', el.id);
              return true;
            }
          });
        }
        this.channelList[idx].status = `Rx: ${unitName}`;
        this.channelList[idx].lastCaller = unitName;
        this.channelList[idx].lastCallerId = unitId
      }
      else if (eventData.id === "release") {
        this.channelList[idx].rx = false;
        this.channelList[idx].status = `Last: ${this.channelList[idx].lastCaller}`;
        this.$root.$emit('radioRxEnd', this.channelList[idx].lastCallerId);
      }
      else if (eventData.id === "emerg") {
        let unitName = eventData.subscriber;
        if (eventData.subscriber > 9000) {
          this.unitList.some((el) => {
            if (el.ingame_id == eventData.subscriber - 9000) {
              unitName = el.identifier;
              return true;
            }
          });
        }
        this.channelList[idx].emg = unitName;
        this.channelList[idx].status = `Emergency ${unitName}`;

      }
      else if (eventData.id === "emerg_clear") {
        this.channelList[idx].emg = 0;
        this.channelList[idx].status = `Emergency Cleared`;
      }
      else if (eventData.id === "ccupdate") {
        let payload = JSON.parse(eventData.payload);
        for (const [key, subscriber] of Object.entries(payload.subscribers)) {
          if (subscriber.subscriberID > 9000) {
            this.channelList.some(channel => {
              if (channel.tgID == subscriber.currentTalkgroup) {
                this.$root.$emit('updateSubscriberChannel', {id: subscriber.subscriberID-9000, channel: channel.group + ' ' + channel.name});
              }
            })
          }
        }
      }
    },
    async setTone(idx, tone) {
      this.channelList[idx].toneID = tone.toneID;
      this.channelList[idx].toneText = tone.toneText;
      this.channelList[idx].toneIcon = tone.toneIcon;
    },
    async toggleMonitor(idx) {
      try {
        console.log(`Toggle monitor ${this.channelList[idx].tgID}`);
        this.channelList[idx].monitored = !this.channelList[idx].monitored;
        if (this.channelList[idx].monitored) {
          this.channelList[idx].webRadio = new WebRadio(this.currentSubscriberID++, this.radioSocket, this.channelList[idx].key, Voice, idx);
          this.channelList[idx].webRadio.setEventCallback(this.eventCallback);
          this.channelList[idx].status = "Affiliating";
          await this.channelList[idx].webRadio.setTalkgroup(this.channelList[idx].tgID, this.channelList[idx].encryptionkey);
          this.channelList[idx].status = "Online";
        }
        else {
          this.channelList[idx].webRadio.deregister();
          this.channelList[idx].webRadio.delete();
          this.channelList[idx].webRadio = undefined;
          this.channelList[idx].status = "Offline";
          if (this.primaryChannel === idx) {
            this.primaryChannel = null;
            this.channelList[idx].primary = false;
          }
        }
      }
      catch (e) {
        console.warn("Error toggling monitor");
        console.warn(e);
      }
    },
    async togglePTT(idx) {
      try {
        console.log(`Toggle PTT ${this.channelList[idx].tgID}`);
        this.channelList[idx].ptt = !this.channelList[idx].ptt;
        if (this.channelList[idx].ptt) {
          let result = await this.channelList[idx].webRadio.downPTT();
          if (result === true) {
            this.channelList[idx].status = "Tx";
            this.channelList[idx].tx = true;
          }
        } else {
          this.channelList[idx].ptt = false;
          await this.channelList[idx].webRadio.upPTT();
          this.channelList[idx].tx = false;
          this.channelList[idx].status = "Online";
        }
      }
      catch (e) {
        console.warn("Error toggling PTT");
        console.warn(e);
      }
    },
    async startPTT(idx) {
      try {
        console.log(`Start PTT ${this.channelList[idx].tgID}`);
        this.channelList[idx].ptt = true;
        let result = await this.channelList[idx].webRadio.downPTT();
        if (result === true) {
          this.channelList[idx].status = "Tx";
          this.channelList[idx].tx = true;
        }
      }
      catch (e) {
        console.warn("Error starting PTT");
        console.warn(e);
      }
    },
    async stopPTT(idx) {
      await Helper.sleep(500);
      try {
        console.log(`Stop PTT ${this.channelList[idx].tgID}`);
        this.channelList[idx].ptt = false;
        await this.channelList[idx].webRadio.upPTT();
        this.channelList[idx].tx = false;
        this.channelList[idx].status = "Online";
      }
      catch (e) {
        console.warn("Error stopping PTT");
        console.warn(e);
      }
    },
    async clearEmergency(idx) {
      console.log(`Clear emergency on ${this.channelList[idx].tgID}`);
      this.channelList[idx].webRadio.forceClearEmergency(this.channelList[idx].emg);
      this.channelList[idx].emg = 0;
      this.channelList[idx].status = "Emergency Cleared";
    },
    async playTone(idx) {
      this.channelList[idx].webRadio.broadcastTone(this.channelList[idx].toneID, null, null);
      console.log(`Broadcast tone on ${this.channelList[idx].tgID} : ${this.channelList[idx].toneID}`)
    },
    async setPrimary(idx) {
      if (this.primaryChannel === idx) {
        this.primaryChannel = null;
        this.channelList[idx].primary = false;
        return;
      }

      if (this.primaryChannel !== null) {
        this.channelList[this.primaryChannel].primary = false;
      }

      this.primaryChannel = idx
      this.channelList[idx].primary = true;
    },
    setupChannel(tgID, name, key, group, encryptionkey) {
      return {
        encryptionkey: encryptionkey,
        group: group,
        tgID: tgID,
        name: name,
        key: key,
        status: "Offline",
        monitored: false,
        tx: false,
        rx: false,
        emg: 0,
        webRadio: undefined,
        toneText: "ATTN",
        toneID: "A",
        toneIcon: "SOLID",
        lastCaller: undefined,
        primary: false,
      }
    }
  },
};
</script>

<style scoped>
.my-custom-scrollbar {
  max-width: 100%;
  max-height: 800px;
  overflow: auto;
}

.table-wrapper-scroll-y {
  display: block;
}

.table-bg-darker {
  background-color: #212529;
}

.red {
  background-color: red !important;
}

.primary-channel {
  background-color: orange;
}
</style>