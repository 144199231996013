<template>
  <b-modal id="evidence-report-modal" size="xl" class="text-center" no-close-on-backdrop :title="`Evidence Report - `+evidenceId" ref="evidenceReportModal">
    <div class="text-center">
      <iframe :src="sourceUrl" width="965px" height="720px" frameborder="0" v-if="evidenceId!==null"></iframe>
    </div>
    <template #modal-footer>
      <div class="w-100">
        <b-input type="text" disabled :value="sourceUrl" style="width: 60%;float: left;"></b-input>
        <b-button
            variant="primary"
            size="sm"
            class="float-left"
            @click="copyUrl"
        >
          Copy
        </b-button>
        <b-button
            variant="danger"
            size="sm"
            class="float-right"
            @click="closeModal"
        >
          Close
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: "EvidenceReport",
  data(){
    return {
      evidenceId: null,
      sourceUrl:null,
    }
  },
  methods: {
    show(evidenceId){
      this.evidenceId=evidenceId;
      this.sourceUrl=this.getSource();
      this.$refs['evidenceReportModal'].show();
    },
    getSource(){
      const baseDomain=process.env.VUE_APP_API_URL;
      const baseURL = baseDomain + "/api/" + process.env.VUE_APP_API_VERSION;

      return baseURL+"/evidence/report/"+this.evidenceId;
    },
    closeModal(){
      this.$refs['evidenceReportModal'].hide();
    },
    copyUrl(){
      navigator.clipboard.writeText(this.sourceUrl);
    }
  }
}
</script>

<style scoped>

</style>