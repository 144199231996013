<template>
  <div>
    <b-form-row>
      <b-col cols="5">
        <b-form-group label="Title" label-for="title">
          <b-form-input
            id="title"
            placeholder="Title of the after action report"
            type="text"
            autocomplete="off"
            v-model="form.title"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col cols="5">
        <b-form-group label="Address" label-for="address">
          <b-form-input
              id="address"
              placeholder="Address with postal"
              type="text"
              autocomplete="off"
              v-model="form.address"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col cols="2">
        <b-form-group label="Location">
          <b-btn :variant="locationVariant" v-b-modal.select-position-map-modal>Select Location</b-btn>
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col>
        <b-form-group label="Narrative">
          <b-form-textarea
              id="narrative"
              v-model="form.narrative_facts"
              placeholder="Narrative of facts..."
              rows="8"
          ></b-form-textarea>
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col>
        <b-form-group label="Personal Observations">
          <b-form-textarea
              id="personal_observations"
              v-model="form.personal_observations"
              placeholder="Personal observations..."
              rows="3"
          ></b-form-textarea>
        </b-form-group>
      </b-col>
    </b-form-row>

    <div v-if="!afterAction">
      <br />
      <strong>Declaration</strong><br />
      <em>By submitting this After Action Report, I, <u>{{this.unit.name}}</u>, <u>{{this.unit.rank}}</u> of <u>{{this.unit.agency.full_name}}</u>,
        do solemnly and sincerely declare that this narrative is the truth, the whole truth, and nothing but the truth.
        I understand that false information can result in me being prosecuted.</em>
      <br />

    </div>

    <b-row v-if="afterAction!==null">
      <b-col cols="6">
        <h6><u>Filling officer:</u></h6>
        {{ afterAction.filing_officer.agency.full_name }}<br>
        {{ afterAction.filing_officer.rank }} {{ afterAction.filing_officer.name }}<br>
        {{ afterAction.filing_officer.identifier }}
      </b-col>

      <b-col cols="6">
        <div v-if="afterAction.approving_supervisor === null" class="alert alert-warning">Pending approval</div>
        <div v-else>
          <h6><u>Approved by supervisor:</u></h6>
          {{ afterAction.approving_supervisor.agency.full_name }}<br>
          {{ afterAction.approving_supervisor.rank }} {{ afterAction.approving_supervisor.name }}<br>
          {{ afterAction.approving_supervisor.identifier }}
        </div>
      </b-col>
    </b-row>

    <ErrorDisplay :errors="errors"></ErrorDisplay>

    <b-form-row>
      <b-col class="text-right">
        <b-button variant="outline-warning" v-if="afterAction !== null && doesUserHavePermission('after_action_report_edit') && !afterAction.approved" @click="approveAfterAction">Approve</b-button>
        <b-button variant="outline-danger" v-if="afterAction !== null && doesUserHavePermission('after_action_report_delete')" @click="deleteAfterAction">Delete</b-button>
        <b-button :variant="loadingVariant" @click="nextStep" :loading="loading" v-if="afterAction===null || (doesUserHavePermission('after_action_report_edit') || afterAction.created_by_id===user.id)">
          <span v-if="loading">
            <b-spinner type="grow" variant="success" class="align-middle" small></b-spinner>
          </span>
          <span v-else>
            <template v-if="afterActionId===null">Next ></template>
            <template v-if="afterActionId!==null && (doesUserHavePermission('after_action_report_edit') || afterAction.created_by_id===user.id)">Save</template>
          </span>
        </b-button>
      </b-col>
    </b-form-row>
    <SelectPositionMapModal v-if="afterAction===null||form.latlang!==null" @submit="updateLatLang" :marker="form.latlang"></SelectPositionMapModal>
  </div>
</template>

<script>
import SelectPositionMapModal from "./SelectPositionMapModal";
import DetectiveRepository from "@/api/DetectiveRepository";
import ErrorDisplay from "@/components/Partials/ErrorDisplay";
export default {
  name: "AfterActionDetails",
  components: {ErrorDisplay, SelectPositionMapModal},
  props: ['CKEditor','afterAction','editorConfig'],
  data(){
    return {
      loading: false,
      form: {
        title: '',
        location_x: null,
        location_y: null,
        latlang: null,
        address: null,
        narrative_facts: null,
        personal_observations: null,
        approving_supervisor_id: null,
        approved: false,
      },
      afterActionId: this.afterAction !== null ? this.afterAction.id : null,
      errors:null,
    }
  },
  methods: {
    updateLatLang(latlang){
      this.form.location_x = latlang.lng;
      this.form.location_y = latlang.lat;
      this.form.latlang = latlang;
    },
    nextStep(){
      this.loading=true;
      this.form.filing_officer_id=this.unit.id;
      if(this.afterActionId){
        DetectiveRepository.saveAfterAction(this.form,this.afterAction.id).then(response => {
          this.$emit('createdAfterAction',response.data);
          this.loading=false;
          this.errors=null;
        }).catch(error => {
          this.loading=false;
          this.errors=error.response.data.errors;
        });
      } else {
        DetectiveRepository.createAfterAction(this.form).then(response => {
          this.$emit('createdAfterAction',response.data);
          this.loading=false;
          this.errors=null;
        }).catch(error => {
          this.loading=false;
          this.errors=error.response.data.errors;
        });
      }
    },
    approveAfterAction(){
      if(this.afterAction===null) return;
      DetectiveRepository.approveAfterAction({unit:this.unit},this.afterAction.id).then(response => {
        this.errors=null;
        this.$emit('createdAfterAction',response.data);
      }).catch(error => {
        this.errors=error.response.data.errors;
      });
    },
    deleteAfterAction(){
      if(this.afterAction===null) return false;
      DetectiveRepository.deleteAfterAction(this.afterAction.id).then(response => {
        this.errors=null;
        this.$emit('deleteAfterAction');
      }).catch(error => {
        this.errors=error.response.data.errors;
      });
    }
  },
  computed: {
    locationVariant(){
      if (this.form.location_x === null) {
        return 'danger';
      }
      return 'success';
    },
    unit() {
      return this.$store.state.auth.leoUnit;
    },
    user() {
      return this.$store.state.auth.user;
    },
    loadingVariant() {
      return this.loading ? 'outline-warning' : 'outline-success';
    }
  },
  mounted() {
    if(this.afterAction !== null){
      this.form.title=this.afterAction.title;
      this.form.location_x=this.afterAction.location_x;
      this.form.location_y=this.afterAction.location_y;
      this.form.latlang={lat:this.afterAction.location_y,lng:this.afterAction.location_x};
      this.form.address=this.afterAction.address;
      this.form.narrative_facts=this.afterAction.narrative_facts;
      this.form.personal_observations=this.afterAction.personal_observations;
      this.form.approving_supervisor_id=this.afterAction.approving_supervisor_id;
      this.form.approved=this.afterAction.approved;
    } else {
      this.form.narrative_facts="&nbsp;";
      this.form.personal_observations="&nbsp;";
    }
  }
}
</script>

<style scoped>

</style>
