<template>

  <div>
    <b-row>
      <b-col>
        <h5>Charges&nbsp;
        <b-btn variant="primary" size="sm" v-on:click="addCharge()">Add charge</b-btn>
        <b-btn variant="danger" size="sm" v-on:click="removeCharge()">Remove charge</b-btn>
        </h5>
      </b-col>
    </b-row>

    <template v-for="k in numCharges">
      <b-card v-bind:key="k-1" 
        :header="'Charge ' + k"
        border-variant="secondary"
        header-border-variant="secondary">

      <b-form-row>
        <b-col cols="6">
          <b-form-group label="Charge" label-for="penalCode">
            <v-select name="penalCode" v-if="penalCodes!==null" :options="penalCodes" label="title" v-model="charges[k-1].penal_code" @input="penalCodeSelected(k-1,$event)"></v-select>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group label="Type">
            <b-form-select v-model="charges[k-1].type" :options="optionType" @change="triggerUpdateMain()"></b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group label="Counts">
            <b-form-input type="number" autocomplete="off" v-model="charges[k-1].counts" @change="triggerUpdateMain()" ></b-form-input>
          </b-form-group>
        </b-col>
      </b-form-row>

      <b-form-row>
        <b-col>
          <b-form-group label="State Recommended Jail Time">
            <b-form-input type="text" v-model="charges[k-1].jail_time" :disabled="!doesUserHavePermission('doj_access')" autocomplete="off"></b-form-input>
          </b-form-group>
        </b-col>

        <b-col>
          <b-form-group label="State Recommended Fine Value">
            <b-form-input type="number" v-model="charges[k-1].fine_value" :disabled="!doesUserHavePermission('doj_access')" autocomplete="off"></b-form-input>
          </b-form-group>
        </b-col>

      </b-form-row>
      </b-card>
      <br />
    </template>
  </div>




</template>

<script>
import DataRepository from "@/api/DataRepository";

export default {
  name: "AddCharges",
  data(){
    return {
      penalCodes:null,
      numCharges:0,
      optionType:[
          { value:'felony',text:'Felony' },
          { value:'misdemeanor',text:'Misdemeanor' },
          { value:'infraction',text:'Infraction' },
          { value:'warning',text:'Warning' },
      ],
      charges:[]

    }
  },
  created() {
    DataRepository.getPenalCodes().then( response => {
      this.penalCodes=response.data.data;
      this.addCharge();
    });
  },
  methods: {
    addCharge(){
      this.charges[this.numCharges]={
        penal_code_id: null,
        type: null,
        counts: 1,
        jail_time:'',
        fine_value:''
      };
      this.numCharges=this.numCharges+1;
    },
    removeCharge(){
      this.charges.splice(-1, 1);
      this.numCharges=this.numCharges-1;
      this.triggerUpdateMain();
    },
    penalCodeSelected(index, event){
      let newObject={
        penal_code_id: event.id,
        type: event.charge_type,
        counts: 1,
        jail_time:event.jail_type,
        fine_value:event.fine_value
      };

      this.charges.splice(index,1,newObject);
      this.triggerUpdateMain();
    },
    triggerUpdateMain(){
      this.$emit('change',this.charges);
    },
  }
}
</script>

<style scoped>

</style>