import Repository from "./Repository";

class CitizenRepository {

    getCitizenCalls(service=null){
        let qs="";

        if(service !== null){
            qs='?service='+service;
        }

        return Repository.get('/civilian-calls'+qs);
    };

    updateCitizenCall(call_id, data){
        return Repository.put("/civilian-calls/"+call_id,data);
    };

    lookup(type, search){
      return Repository.get('/lookup?'+type+"="+search);
    };

    getPicture(civilian){
        return Repository.get('/civilians/'+civilian+'/picture');
    };

    getVehicles(civilian=null){
        let qs="";
        if(civilian!==null){
            qs="?civilian="+civilian;
        }

        return Repository.get('/civilian-vehicles'+qs);
    };

    createCitizen(data){
      return Repository.post('/civilians',data,{ headers: {'Content-Type': 'application/x-www-form-urlencoded' }});
    };

    deleteCitizen(civilian){
        return Repository.delete('/civilians/'+civilian);
    };

    updateCitizen(data,civilian){
        return Repository.put('/civilians/'+civilian,data);
    };

    updateCitizenPicture(picture,civilian){
        let formData=new FormData();
        formData.append('picture',picture);
        return Repository.post('/civilians/'+civilian+"/updatePicture", formData,{ headers: {'Content-Type': 'application/x-www-form-urlencoded' }});
    };

    getCitizen(civilian){
        return Repository.get('/civilians/'+civilian);
    };

    getCitizens(hidden=0){
        return Repository.get('/civilians?hidden='+hidden);
    };

    createCitizenLicense(data){
        return Repository.post('/civilian-licenses', data);
    };

    createCitizenWeapon(data){
        return Repository.post('/civilian-weapons', data);
    };

    updateCitizenWeapon(data,weapon){
        return Repository.put('/civilian-weapons/'+weapon, data);
    };

    deleteCitizenWeapon(weapon){
        return Repository.delete('/civilian-weapons/'+weapon);
    };

    createCitizenVehicle(data){
        return Repository.post('/civilian-vehicles', data);
    };

    updateCitizenVehicle(data,vehicle){
        return Repository.put('/civilian-vehicles/'+vehicle, data);
    };

    deleteCitizenVehicle(vehicle){
        return Repository.delete('/civilian-vehicles/'+vehicle);
    };

}

export default new CitizenRepository();