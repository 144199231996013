<template>
  <b-modal id="files-modal" size="xl" title="Files">
    <b-tabs content-class="mt-3" justified>
      <b-tab title="Case Files" v-if="doesUserHavePermission('case_file_access')">
        <PinnedCasesTable></PinnedCasesTable>
      </b-tab>
      <b-tab title="After Action Reports" v-if="doesUserHavePermission('after_action_report_access')">
        <AfterActionReportTable></AfterActionReportTable>
      </b-tab>
    </b-tabs>
  </b-modal>
</template>

<script>
import AfterActionReportTable from "@/components/Partials/Detectives/AfterActionReportTable";
import PinnedCasesTable from "@/components/Partials/Detectives/PinnedCasesTable";
export default {
  name: "FilesModal",
  components: {PinnedCasesTable, AfterActionReportTable}
}
</script>

<style scoped>

</style>