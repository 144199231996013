<template>
  <table class="table">
    <thead class="thead-light">
      <tr>
        <th v-for="header in headerItems">{{ header.title }}</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(k,index) in data" @click="openModal(index)" @contextmenu="openContextMenuTable($event,index)" class="tr-data">
        <td v-for="header in headerItems" v-if="header.key !== 'flags'">{{ header.key.split('.').reduce((p,c)=>p&&p[c]||null, k) }}</td>
        <td v-for="header in headerItems" v-if="header.key === 'flags'">
          <b-badge class="ml-2 text-capitalize" v-for="flag in k.flags" :key="k.id" variant="danger">{{flag.flag_name.replaceAll('_',' ')}}</b-badge>
        </td>
        <ContextMenu ref="tableContext" :data-index="index" v-if="flags">
          <b-dropdown-item href="#" v-on:click="flag(fi,index)" v-for="(f,fi) in flags" v-if="k.flags.hasOwnProperty(fi) && k.flags[fi].value" :key="fi">{{ f[true] }}</b-dropdown-item>
          <b-dropdown-item href="#" v-on:click="flag(fi,index)" v-for="(f,fi) in flags" v-if="!k.flags.hasOwnProperty(fi) || !k.flags[fi].value" :key="fi">{{ f[false] }}</b-dropdown-item>
        </ContextMenu>
      </tr>
    </tbody>
  </table>
</template>

<script>
import ContextMenu from "@/components/Partials/Dispatch/ContextMenu";
export default {
  name: "LookupTable",
  components: {ContextMenu},
  props: ['data','headerItems','type','flags'],
  methods: {
    openModal(value){
      this.$emit('showLookup', value);
    },
    flag(type,index){
      this.$emit('flagclick',type,index);
    },
    openContextMenuTable(e,index){
      e.preventDefault();
      if(this.$refs['tableContext'] && typeof this.$refs['tableContext'] !== 'undefined'){
        const dropdown=this.$refs['tableContext'].find(
            el => el.$attrs['data-index'] === index
        );
        this.$refs['tableContext'].forEach(e=>{
          if(e.$attrs['data-index'] !== index) e.close();
        });
        dropdown.toggle(e);
      }

    }
  }
}
</script>

<style scoped>
  .tr-data {
    cursor: pointer;
  }
</style>