<template>
  <b-modal id="quick-search-arrest" size="s" hide-footer title-html="Quick Search Arrest">

    <b-form-row>
      <div class="col-6">
        <b-form-input type="text" v-model="arrest_id" placeholder="Arrest ID" @keyup.enter="openArrestReportModal"></b-form-input>
      </div>
      <div class="col-2 text-center">
        <b-button variant="primary" block @click="openArrestReportModal">Open</b-button>
      </div>
    </b-form-row>

    <ArrestReportShow :arrest_report_id="arrest_id" v-if="arrest_id!==null"></ArrestReportShow>

  </b-modal>
</template>

<script>

import ArrestReportShow from "@/components/Partials/LEO/Lookup/ArrestReportShow";

export default {
  name: "QuickSearchArrest",
  data(){
   return {
     arrest_id: null
   }
  },
  components: {ArrestReportShow},
  methods: {
    openArrestReportModal(){
      setTimeout(() => this.$root.$emit('bv::show::modal', 'arrest-report-lookup-modal'), 100);
    },
  }
}
      
</script>