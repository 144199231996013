import Repository from "./Repository";

class FdRepository {

    createMedicalRecord(data){
        return Repository.post('medical-records', data);
    }

    getMedicalRecord(id){
        return Repository.get('medical-records/'+id)
    }

}

export default new FdRepository();